import { fetchService } from ".";

export const subscriptionApi = async (values) => {
    const data = await fetchService({
        method: "POST",
        url: "subscribe",
        body: JSON.stringify(values),
        isUrl: false,
        myHeaders: {
            "Content-Type": "application/json",
        },
    });

    return data;
};
