import { registerUser } from "../../api/registerApi";
import { types } from "../actionTypes";

export const registerUserAction = (values) => {
    return async (dispatch) => {
      dispatch({
        type: types.REGISTER_REQUEST,
      });
      try {
        const results = await registerUser(values);
        
        if(results.data.data)
        {
          return dispatch({
            type: types.REGISTER_SUCCESS,
            payload: results.data,
          });
        }
        throw results
      } catch (error) {
        
        return dispatch({
          type: types.REGISTER_FAIL,
          payload: error,
        });
      }
    };
  };