
export const setGoogleDriveSyncing = (values) => {
    return {
        type: "GOOGLE_DRIVE_SYNCING",
        payload: values,
    };
};

export const setDropboxSyncing = (values) => {
    return {
        type: "DROPBOX_SYNCING",
        payload: values,
    };
};