import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Invoicetemplate = ({ createnotetoggle2, isOpen }) => {
    const dispatch = useDispatch()
    const [estimates, setAddress] = useState('');
    const [updated, setUpdated] = useState(estimates);
    const handleClick = (e, val)  =>{
        dispatch(saveFormAction({"estimates": val})) 
        setUpdated(createnotetoggle2);  
      }
    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle2={createnotetoggle2} centered>
                <ModalHeader createnotetoggle2={createnotetoggle2} className="mb-0">
                    Invoice Template
                   <span onClick={createnotetoggle2}><img src={require('../../images/cross-svgrepo-com.svg').default} alt="" width="15px" style={{marginLeft:290}} /></span> 
                </ModalHeader>
                <ModalBody className="py-4">
                    <div className="colors_list">
                        <div className="color_div">
                            Default
                            <span className="with_check circle_circle" onClick={(e)=> handleClick(e, 'Default')}>
                                <img src={require('../../images/check-mark.png')} alt="" />
                            </span>
                        </div>
                        <div className="color_div">
                            Red
                            <span className="circle_circle" onClick={(e)=> handleClick(e, 'Red')}>
                                <img src={require('../../images/check-mark.png')} alt="" />
                            </span>
                        </div>
                        <div className="color_div neatcolor">
                            Blue
                            <span className="circle_circle" onClick={(e)=> handleClick(e, 'Blue')}>
                                <img src={require('../../images/check-mark.png')} alt="" />
                            </span>
                        </div>
                        <div className="color_div grencolor">
                            Green
                            <span className="circle_circle" onClick={(e)=> handleClick(e, 'Green')}>
                                <img src={require('../../images/check-mark.png')} alt="" />
                            </span>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default Invoicetemplate;
