import React, { useEffect, useState } from "react";
import { FormGroup } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import Newtax from "./new-tax"
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Addinvoice = ({ createnotetoggle, isOpen, itemAddOrEdit, values }) => {
  const dispatch = useDispatch()
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const [addNewItemModalOpen, setAddNewItemModal] = useState(false)
  const [elementId, setElementId] = useState("")
  const openAddNewItemsModal = () => {
    setAddNewItemModal(!addNewItemModalOpen)
  }
  // console.log("itemAddOrEdit", itemAddOrEdit)
  const [inputs, setInputs] = useState({
    qty: "",
    description: "",
    price: "",
    amount: ""
  })

  // console.log("values", values)
  useEffect(() => {
    // console.log("HERE--")
    values.index && values.index != '' && setElementId(values.index)
    // if(itemAddOrEdit=='edit'){
    setInputs(values)

    // }
  }, [itemAddOrEdit])

  const resetForm = () => {
    setInputs({
      qty: "",
      description: "",
      price: "",
      amount: ""
    })
    dispatch(saveFormAction({ "addNewItemsAmounts": [] }))
    dispatch(saveFormAction({ "addNewItems": [] }))
  }
  const resetTaxForm = () => {
    // dispatch(saveFormAction({ "taxAmount": "", "taxRate": "" }))
    dispatch(saveFormAction({ "taxItems": [] }))

  }


  const handleInputChange = (e) => {
    setInputs({
      ...inputs, ...{ [e.target.name]: e.target.value }
    })
  }
  const handleClick = () => {
    if (inputs.amount != '') {
      if (itemAddOrEdit == 'add') {
        dispatch(saveFormAction({ "addNewItemsAmounts": [...invoiceDetailsStates.addNewItemsAmounts, inputs.amount] }))
        dispatch(saveFormAction({ "addNewItems": [...invoiceDetailsStates.addNewItems, inputs] }))
      }
      else {
        let updatedArr = invoiceDetailsStates.addNewItems.map((ele, index) => {
          if (index == elementId) {
            ele.qty = inputs.qty
            ele.price = inputs.price
            ele.description = inputs.description
            ele.amount = inputs.amount

          }
          return ele
        })
        console.log("updatedArr", updatedArr)
        dispatch(saveFormAction({ "addNewItemsAmounts": [...invoiceDetailsStates.addNewItemsAmounts, inputs.amount] }))
        dispatch(saveFormAction({ "addNewItems": updatedArr }))
      }

    }

    createnotetoggle()
  }


  useEffect(() => {
    if (inputs.qty != '' && inputs.price != '') {
      let amount = parseFloat(inputs.qty) * parseFloat(inputs.price)
      setInputs({
        ...inputs, ...{ "amount": amount }
      })

    }
  }, [inputs.qty, inputs.price])
  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle={createnotetoggle} centered>
        <ModalHeader createnotetoggle={createnotetoggle}>
          Add Item
        </ModalHeader>

        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Qty</Label>
              <Input
                name="qty"
                type="number"
                value={inputs.qty}
                placeholder="Enter qty"
                className="form-control"
                onChange={handleInputChange}
                id="price_1"
              />
            </FormGroup>

            <FormGroup>
              <Label className="mt-2">Description</Label>
              <Input
                name="description"
                type="textarea"
                value={inputs.description}
                placeholder="Enter description"
                className="form-control"
                onChange={handleInputChange}
                id="qty_1"
              />

            </FormGroup>
            <FormGroup>
              <Label className="mt-2">Price</Label>
              <Input
                name="price"
                type="number"
                step={0.01}
                value={inputs.price}
                placeholder="Enter price"
                className="form-control"
                onChange={handleInputChange}
                id="total_1"
              />

            </FormGroup>
            <FormGroup>
              <Label className="mt-2">Amount</Label>
              <Input
                name="amount"
                type="number"
                value={inputs.amount}
                placeholder="Enter Amount"
                className="form-control"
                onChange={handleInputChange}
                disabled
              />

            </FormGroup>
          </Form>
        </ModalBody>
        {invoiceDetailsStates.taxItems.length > 0 &&
          (
            <ModalBody>
              <Label className="mt-2">Tax</Label>
              {/* <div class="add_list_btn1 without_icon hover_disabled"> */}
                {
                  invoiceDetailsStates.taxItems.map((ele) => {
                    return (
                      <>
                         <div class="add_list_btn1 without_icon hover_disabled">
                        <p className="mb-0">{ele.tax} </p>
                        <span><p className="mb-0">{(parseFloat(ele.rate)).toFixed(2)}% </p></span>
                        </div>
                      </>
                    )
                    
                  })
                }
                {/* {
                  invoiceDetailsStates.taxAmount.map((ele) => (
                    <p className="mb-0">{ele} </p>
                  ))
                }
                <span> {
                  invoiceDetailsStates.taxRate.map((ele) => (
                    <p className="mb-0">{(parseFloat(ele)).toFixed(2)}% </p>
                  ))
                }
                </span> */}
              {/* </div> */}

              <div className="add_list_btn1" onClick={() => resetTaxForm()}>
                <img src={require('../../images/no-parking-cross.svg').default} alt="" className="add_img1" />
                Clear Tax
              </div>
            </ModalBody>
          )}
        <ModalFooter>
          <button className="btn outline_btn" type="submit" onClick={handleClick}>Done</button>
          <Button color="secondary" onClick={createnotetoggle}>Cancel</Button>
        </ModalFooter>

        <ModalBody>
          <div className="add_list_btn1" onClick={() => openAddNewItemsModal()}>
            <img src={require('../../images/cross-svgrepo-com.svg').default} alt="" className="add_img" />
            Add or Exit Taxes
            <img src={require('../../images/right.svg').default} alt="right" className="right_img1"
            />
          </div>

          {inputs.amount &&
            (
              <div className="add_list_btn1" onClick={() => resetForm()}>
                <img src={require('../../images/no-parking-cross.svg').default} alt="" className="add_img1" />
                Delete Item
              </div>
            )}
        </ModalBody>
      </Modal>
      <Newtax isOpen={addNewItemModalOpen} createnotetoggle={openAddNewItemsModal} />
    </div>
  );

}

export default Addinvoice;
