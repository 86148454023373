import React, { useState, useEffect } from "react";
import add from '../images/add.svg';
import car from '../images/car-table.png';
import mobile from '../images/mob.png';
import holiday from '../images/holiday.svg';
import sports from '../images/sports.svg';
import deletee from '../images/del.svg';
import ModalExample from "./common/expense-category";
import Modalcat from "./common/expense-cat";
import { getCategoryListAction } from "../redux/actions/getCategoryListAction";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategoryAction } from "../redux/actions/deleteCategoryAction";
import { toast } from 'react-toastify';
import { getIncomeCategoryListAction } from "../redux/actions/getIncomeCategoryListAction";
import { Loader } from "./loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as EditSvg } from "../images/edit-button-svgrepo-com.svg";
import DeleteModal from "./common/deleteModal";

// import useEffect from "../utils/useEffect";

const Category = ({ currentSelection }) => {
    const dispatch = useDispatch()
    const { authUser } = useSelector((state) => state.login)
    const [categoryList, setCategoryList] = useState(null) // state manage for exoense category
    const [expenseCategoryList, setExpenseCategoryList] = useState(null)
    const [isOpen, setIsOpen] = useState(0)
    const [currentTab, setCurrentTab] = useState('Expense')
    const [showLoader, setShowLoader] = useState(false)
    const [editValues, setEditValues] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null)


    const toggle = (values = null) => {
        setIsOpen(!isOpen)
        setEditValues(values)
    }

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (tab) => {
        setToggleState(tab);

        if (tab === 2) {
            renderIncomeCategories()
            setCurrentTab('Income')
        }

        else {
            renderCategoryListing()
            setCurrentTab('Expense')
        }
    }

    const deleteCategory = (categoryId) => {
        // show loader
        setShowLoader(true)

        let requestParams = {
            'user_id': authUser.data.id,
            'category_id': categoryId
        }

        dispatch(deleteCategoryAction(requestParams)).then(response => {

            if (response.payload.status_code == '200') {
                toast.success(response.payload.message)

                currentTab === 'Income' ? renderIncomeCategories() : renderCategoryListing()

                setShowLoader(false)
            }

            else {
                toast.error(response.payload.message)
                setShowLoader(false)
            }

            setShowDeleteModal(false)
            setDeleteId(null)
        })
    }

    const renderCategoryListing = () => {
        let requestParams = {
            'user_id': authUser.data.id
        }

        if (requestParams) {
            // show loader
            setShowLoader(true)

            dispatch(getCategoryListAction(requestParams)).then(response => {
                setCategoryList(response.payload.data)

                setShowLoader(false)
            })
        }
    }

    const renderIncomeCategories = () => {
        let requestParams = {
            'user_id': authUser.data.id
        }

        if (requestParams) {
            // show loader
            setShowLoader(true)

            dispatch(getIncomeCategoryListAction(requestParams)).then(response => {
                setExpenseCategoryList(response.payload.data)

                setShowLoader(false)
            })
        }
    }

    useEffect(() => {
        renderCategoryListing()
    }, []);

    return (
        <>
            {
                showLoader && <Loader />
            }
            <div className="Exp-category">
                <div className="category-wrap d-flex justify-content-between align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex mb-0">
                            <li className="breadcrumb-item"><a href="#">{currentTab == 'Expense' ? 'Expenditures' : 'Incomes'}</a></li> <span className="mx-2"> {">"} </span>
                            <li className="breadcrumb-item active" aria-current="page">Category</li>
                        </ol>
                    </nav>

                    <button type="button" className="btn btn-primary add-category-exp" onClick={() => {
                        toggle(null)
                    }}>
                        <img src={add} alt="add" />Add</button>
                </div>
                <div className="table-content">
                    <div className={toggleState === 1 ? "transaction-data-list active category-table table-responsive" : "category-table"}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    categoryList != null && categoryList.map((category) => {
                                        return <tr id={category.id}>
                                            <th scope="row">{category.id}</th>
                                            <td> <span><img src={mobile} alt="mob" /></span> {category.name} </td>
                                            <td>{category.budget}</td>
                                            {/* <td><span><img src={mobile} alt ="mob" /></span>Mobile</td> */}
                                            <td >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Edit</Tooltip>}
                                                >
                                                    <EditSvg height={20} width={20} fill="black" className="edit_svg me-3" onClick={() => {
                                                        toggle(category)
                                                    }} />
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Delete</Tooltip>}
                                                >
                                                    <img src={deletee} alt="delete" onClick={() => {
                                                        setShowDeleteModal(true)
                                                        setDeleteId(category.id)
                                                    }
                                                        // deleteCategory(category.id)
                                                    } />
                                                </OverlayTrigger>

                                            </td>
                                        </tr>
                                    })

                                }
                                {
                                    categoryList === 0 && 'No data found.'
                                }
                            </tbody>


                        </table>
                    </div>

                    <div className={toggleState === 2 ? "transaction-data-list active category-table table-responsive" : "category-table"}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    {/* <th scope="col">Date</th> */}
                                    <th scope="col">Category</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    expenseCategoryList != null && expenseCategoryList.map((expenseCategory) => {
                                        return <tr id={expenseCategory.id}>
                                            <th scope="row">{expenseCategory.id}</th>
                                            <td> <span><img src={mobile} alt="mob" /></span> {expenseCategory.name} </td>
                                            <td> {expenseCategory.budget} </td>
                                            {/* <td><span><img src={mobile} alt ="mob" /></span>Mobile</td> */}
                                            {/* <td onClick={() => deleteCategory(expenseCategory.id)}><img src={deletee} alt="delete" /></td> */}
                                            <td >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Edit</Tooltip>}
                                                >
                                                    <EditSvg height={20} width={20} fill="black" className="edit_svg me-3" onClick={() => {
                                                        toggle(expenseCategory)
                                                    }} />
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Delete</Tooltip>}
                                                >
                                                    <img src={deletee} alt="delete" onClick={() => {
                                                        setShowDeleteModal(true)
                                                        setDeleteId(expenseCategory.id)
                                                    }
                                                    } />
                                                </OverlayTrigger>

                                            </td>
                                        </tr>
                                    })

                                }
                                {
                                    expenseCategoryList === 0 && 'No data found.'
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <div className="d-inline-flex expense-tabs">
                        <span className={toggleState === 1 ? "active expense-tab" : "expense-tab"} onClick={() => toggleTab(1)}>Expense</span>
                        <hr></hr>
                        <span className={toggleState === 2 ? "active income-tab" : "income-tab"} onClick={() => toggleTab(2)}>Income</span>
                    </div>
                </div>
                <Modalcat currentSelection={currentSelection}
                    currentTab={currentTab} isOpen={isOpen} toggle={toggle}
                    renderCategoryListing={renderCategoryListing}
                    renderIncomeCategories={renderIncomeCategories}
                    rowValues={editValues} />


                {
                    showDeleteModal &&
                    <DeleteModal
                        isShow={showDeleteModal}
                        onConfirm={() => deleteCategory(deleteId)}
                        setShowModal={() => setShowDeleteModal(!showDeleteModal)}
                    />
                }
            </div>
        </>
    );
}
export default Category;