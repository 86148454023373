export const syncReducer = (state = {
    googleDriveSync: false,
    dropboxSync: false
}, action) => {
    switch (action.type) {
        case 'GOOGLE_DRIVE_SYNCING':
            return { ...state, googleDriveSync: action.payload }

        case 'DROPBOX_SYNCING':
            return { ...state, dropboxSync: action.payload }

        default:
            return state
    }
}

