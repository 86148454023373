import { types as Types } from '../actionTypes';

export const LoginReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return state
    case 'LOGIN_SUCCESS':
      return { state, 'authUser': action.payload }
    case Types.BUY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        error: ''
      }
    case Types.BUY_SUBSCRIPTION_SUCCESS:
      state.authUser.data.subscription_status = action.payload.subscription.plan_id + 1;
      state.authUser.data.subscription = action.payload.subscription;
      return {
        ...state,
        loading: false,
        error: ''
      }
    case Types.BUY_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      }
    default:
      return state
  }
}

