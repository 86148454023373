import { getDataApi } from "../../../api/dashboard/getDataApi";
import { types } from "../../actionTypes";

export const getDataAction = (values) => {
    return async (dispatch) => {
      dispatch({
        type: types.DASHBOARD_DATA_REQUEST,
      });
      try {
        const results = await getDataApi(values);
        
        if(results.data.status_code == 200)
        {
          return dispatch({
            type: types.DASHBOARD_DATA_SUCCESS,
            payload: results.data,
          });
        } else {
            return dispatch({
                type: types.DASHBOARD_DATA_FAIL,
                payload: results.data.message,
            });
        }
      } catch (error) {
        
        return dispatch({
          type: types.ADD_CATEGORY_FAIL,
          payload: error,
        });
      }
    };
  };