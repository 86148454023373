import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Estimatedate = ({ createnotetoggle9, isOpen }) => {
    const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
    const [startDate, setStartDate] = useState(new Date());
    const dispatch = useDispatch()
    const [date, setDate] = useState(invoiceDetailsStates.date);
    const [updated, setUpdated] = useState(date);
    const handleChange = (event) => {
        setDate(event.target.value);
    };
  
    const handleClick = ()  =>{
        dispatch(saveFormAction({"date": date}))    
        setUpdated(createnotetoggle9);
      }
     
    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle9={createnotetoggle9} centered>
                <ModalHeader createnotetoggle9={createnotetoggle9} className="mb-0">
                {invoiceDetailsStates.estimate} Date
                </ModalHeader>
                <ModalBody className="py-4">
                    <div className="d-flex">
                    <Form className="form_text w-100">
                    <FormGroup> 
                        <div className="d-flex">
                            {/* <DatePicker  selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="Estimate Date" /> */}
                             <input type="date" placeholderText="Estimate Date"  onChange={handleChange}/>
                        </div>
                        </FormGroup>
                    </Form>
                    <button className="btn outline_btn ms-2" onClick={handleClick}>OK</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default Estimatedate;
