export const invoiceReducer = (state = {
    estimate: 'Invoice',
    addNewItemsAmounts: [],
    addNewItems: [],
    taxRate: [],
    taxAmount:[],
    taxItems:[],
    currencyformat: "$ 0.00",
    "estimates": "Default",
    address: "",
    billaddress:"",
    shipaddress:"",
    shipamount:"",
    estimate_no:"",
    date:"",
    due:"",
    terms:"",
    file:"",
    files:"",
    "currency":"$"
}, action) => {
    switch (action.type) {
        case 'SAVE_FORM':
            return { ...state, ...action.payload }

        default:
            return state
    }
}

