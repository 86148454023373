import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Paymentintegrate = ({ createnotetoggle14, isOpen }) => {

    const dispatch = useDispatch()
    const [estimated, setAddress] = useState('');
    const [updated, setUpdated] = useState(estimated);
   
    const handleClick = (e, val)  =>{
        dispatch(saveFormAction({"estimated": val})) 
        setUpdated(createnotetoggle14);  
      }
    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle14={createnotetoggle14} centered>
                <ModalHeader createnotetoggle14={createnotetoggle14}>

                    <span onClick={createnotetoggle14} className="d-flex justify-content-between align-items-center">
                        <span>Integrated Payment</span>
                        <img src={require('../../images/cross-svgrepo-com.svg').default} alt="" width="15px" />
                    </span>
                </ModalHeader>

                <ModalBody>
                    <div className="add_list_btn1">
                        <img src={require('../../images/edit.svg').default} alt="" className="add_img1" />
                        Set as Paid
                        <span className="with_check circle_circle" onClick={(e) => handleClick(e, 'Set as Paid')}>
                        <img src={require('../../images/right.svg').default} alt="" className="right_img1" />
                        </span>
                    </div>
                    <div className="add_list_btn1">
                        <img src={require('../../images/lined-circle.svg').default} alt="" className="add_img1" />
                        Set as Partial Paid
                        <span className="with_check circle_circle" onClick={(e) => handleClick(e, 'Set as Partial Paid')}>
                        <img src={require('../../images/right.svg').default} alt="" className="right_img1" />
                        </span>
                    </div>
                </ModalBody>
            </Modal>

        </div>
    );

}

export default Paymentintegrate;
