import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';


const Modalviewnote = ({toggle,isOpen,viewNotedata}) => {
    return (
      <div>
       
        <Modal className="exp-cat" isOpen={isOpen} toggle={toggle} centered>
          <ModalHeader toggle={toggle}>View note</ModalHeader>
          <ModalBody>
          <Form>
         <div className='note-view'>
             <h3 className='mb-1'>{viewNotedata && viewNotedata.title}</h3>
             <span>{viewNotedata && viewNotedata.date}</span>
             <div className='mt-3'>
             <p>{viewNotedata && viewNotedata.note}</p>
             
             </div>

         </div>
          </Form>
           </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>   
    );
  
}

export default Modalviewnote;