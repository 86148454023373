import React from "react";
import Header from '../components/partials/header';
import Navbar from '../components/partials/navbar';
import { useSelector, useDispatch } from 'react-redux';
import del from '../images/delete.svg';
import ExpireModal from './ExpireModal';

const Notifications = () => {
    const { authUser } = useSelector(state => state.login);

    return (
        <>
            <div className="notifications">
                <Header />
                <div className="container-fluid">
                    <div className="row flex-nowrap">
                        <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 navv">
                            <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                                <Navbar />
                            </div>
                        </div>
                        <div className="col background-bg row m-0">
                            <div className="col-lg-9 col-sm-12 mx-auto">
                                <div className="inner-wrap-notifications">
                                    <div className="heading">
                                        <h4>Notifications</h4>
                                    </div>
                                    <div className="new-message">
                                        <p>New messages</p>
                                        <div className="unread-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                    </div>
                                    <div className="recent-msg">
                                        <p>Recent Messages</p>
                                        <div className="read-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                        <div className="read-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                        <div className="read-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                        <div className="read-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                        <div className="read-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                        <div className="read-msg d-flex justify-content-between align-items-center">
                                            <div>
                                                <p>Test is created</p>
                                                <span>Date: 2 june 2022</span>
                                            </div>
                                            <img src={del} alt="delete" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    authUser.data.subscription_status == 1 && (
                        <ExpireModal />
                    )
                }
            </div>
        </>

    );
}
export default Notifications;