import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from "react-redux";
import Addinvoice from "./add-invoice";

const Additem = ({ createnotetoggles13, isOpen }) => {
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const [addNewItemModalOpen, setAddNewItemModal] = useState(false)
  const [itemAddOrEdit, setItemAddOrEdit] = useState("add")
  const [selectedVal, setSelectedVal] = useState({
    qty: "",
    description: "",
    price: "",
    amount: ""
  })
  const [finalAmount, setFinalAmount] = useState(0.00)
  const openAddNewItemsModal = (addState, val) => {
    setAddNewItemModal(!addNewItemModalOpen)
    setItemAddOrEdit(addState)
    if (addState == 'edit') {
      setSelectedVal(val)
    }
    else {
      setSelectedVal(
        {
          qty: "",
          description: "",
          price: "",
          amount: ""
        }
      )
    }
  }

  // console.log("itemAddOrEdit", itemAddOrEdit)

  useEffect(() => {
    if (invoiceDetailsStates.addNewItems.length > 0) {
      let sum = 0
      invoiceDetailsStates.addNewItems.forEach((ele) => {
        sum = sum + parseFloat((ele.amount))
      })
      setFinalAmount(sum)
    }
  }, [invoiceDetailsStates.addNewItems])
  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggles13={createnotetoggles13} centered>
        <ModalHeader createnotetoggles13={createnotetoggles13}>
          <span className="d-flex justify-content-between align-items-center" onClick={createnotetoggles13}>
            <span>Add Item</span>
            <img src={require('../../images/cross-svgrepo-com.svg').default} alt="" width="15px" />
          </span>
        </ModalHeader>

        <ModalBody className="py-3">
          <div className="add_list_btn1" onClick={() => openAddNewItemsModal("add")}>
            <img src={require('../../images/cross-svgrepo-com.svg').default} alt="" className="add_img" />
            New Item
            <img src={require('../../images/right.svg').default} alt="" className="right_img1"
            />
          </div>
          {
            invoiceDetailsStates.addNewItems.length > 0 && (
              <>

                {/* <span>Adds</span> */}
                {
                  invoiceDetailsStates.addNewItems.map((ele, index) => (
                    <>
                      <span className="border-bottom d-flex justify-content-between align-items-center">
                        <span>{ele.description}</span>
                        <p className="mb-2" onClick={() => openAddNewItemsModal("edit", { ...ele, index: index })}>$ {(parseFloat(ele.amount)).toFixed(2)}
                          <img className="ms-2" src={require('../../images/next.svg').default} alt="" />
                        </p>
                      </span>
                    </>

                  ))
                }

                <div className="total_row d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Total</h5>
                  <h5 className="mb-0">
                    {

                      <p className="mb-2">$ {(parseFloat(finalAmount)).toFixed(2)} </p>

                    }
                  </h5>
                </div>
              </>
            )
          }
        </ModalBody>
      </Modal>
      <Addinvoice isOpen={addNewItemModalOpen} createnotetoggle={openAddNewItemsModal} itemAddOrEdit={itemAddOrEdit} values={selectedVal} />
    </div>
  );

}

export default Additem;
