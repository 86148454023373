import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Estimatenumber = ({ createnotetoggle8, isOpen }) => {
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const dispatch = useDispatch()
  const [estimate_no, setAddress] = useState(invoiceDetailsStates.estimate_no);
  const [updated, setUpdated] = useState(estimate_no);
  const handleChange = (event) => {
    setAddress(event.target.value);
  };
  const handleClick = () => {
    dispatch(saveFormAction({ "estimate_no": estimate_no }))
    setUpdated(createnotetoggle8);

  }
  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle8={createnotetoggle8} centered>
        <ModalHeader createnotetoggle8={createnotetoggle8}>
          {invoiceDetailsStates.estimate}  Number
        </ModalHeader>
        <ModalBody className="py-4">
          <div className="d-flex">
            <Form className="form_text w-100">
              <FormGroup>
                <div className="d-flex">
                  <Input
                    name="estimate_no"
                    type="number"
                    value={estimate_no}
                    placeholder={`${invoiceDetailsStates.estimate} No.`}
                    className="me-2"
                    onChange={handleChange}
                  />
                </div>

              </FormGroup>
            </Form>
            <button className="btn outline_btn" type="submit" onClick={handleClick}>OK</button>
          </div>
          {/* <button className="btn outline_btn" onClick={createnotetoggle8}>Cancel</button> */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Estimatenumber;
