import { fetchService } from ".";

export const addNoteApi = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "add-note",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}

export const verifyNotePassword = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "verify-password",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}


export const forgotPassword = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "send-otp",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}

export const verifyNoteOtp = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "verify-otp",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}

export const resetNotePassword = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "reset-password",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}