import React, { useEffect, useState } from "react";
import mobile from "../images/mob.png";
import expo from "../images/export-data.svg";
import ModalExport from "./common/transaction-export";
// import ProgressBar from "@ramonak/react-progress-bar";
import { Progress } from "reactstrap";

const Chartcategory = ({ chartData }) => {
  const [toggleState, setToggleState] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const exporttoggle = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <div className="chart-category">
        <div className="row">
          <div className="col-lg-12 transaction-data-list table-responsive">
            
            <div className="chart-content mt-4">
              <div
                className={toggleState === 1 ? "active chartdata" : "chartdata"}
              >
                <table className="table table-striped mt-0">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Actual Budget</th>
                      <th scope="col">Percentage</th>
                    </tr>
                  </thead>
                  {!chartData ||
                  chartData === [] ||
                  chartData === null ||
                  !chartData?.Expense?.Transactions?.length ? (
                    "No data found."
                  ) : (
                    <tbody>
                      {chartData?.Expense?.Transactions?.length > 0
                        ? chartData.Expense.Transactions.map(
                            (transaction, key) => {
                              return (
                                <tr key={key}>
                                  <th scope="row">{Number(key) + Number(1)}</th>
                                  <th>{transaction.category}</th>
                                  <th>{transaction.cash}</th>
                                  <th>
                                    <div className="outerProgressBar">
                                      <Progress
                                        bar
                                        color="success"
                                        value={transaction.percentage}
                                        max={5000}
                                      >
                                        {/* </Progress> 

                                                  <Progress bar value={this.state.sale} max={100}> */}
                                        {transaction.percentage}
                                      </Progress>
                                    </div>
                                  </th>
                                </tr>
                              );
                            }
                          )
                        : ""}
                    </tbody>
                  )}
                </table>
              </div>
              <div
                className={toggleState === 2 ? "active chartdata" : "chartdata"}
              >
                <table className="table table-striped mt-0">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Actual Budget</th>
                      <th scope="col">Percentage</th>
                    </tr>
                  </thead>
                  {!chartData ||
                  chartData === [] ||
                  chartData === null ||
                  !chartData?.Expense?.Transactions?.length ? (
                    "No data found."
                  ) : (
                    <tbody>
                      {chartData &&
                        chartData?.Income?.Transactions?.length > 0 &&
                        chartData.Income.Transactions.map(
                          (transaction, key) => {
                            return (
                              <tr key={key}>
                                <th scope="row">{Number(key) + Number(1)}</th>
                                <th>{transaction.category}</th>
                                <th>{transaction.cash}</th>
                                <th>
                                  <div className="outerProgressBar">
                                    <Progress
                                      bar
                                      color="success"
                                      value={transaction.percentage}
                                      max={5000}
                                    >
                                      {/* </Progress> 

                                                  <Progress bar value={this.state.sale} max={100}> */}
                                      {transaction.percentage}
                                    </Progress>
                                  </div>
                                </th>
                              </tr>
                            );
                          }
                        )}

                      {
                        chartData &&
                          !chartData.Income.Transactions.length &&
                          "No data found."
                        // <div className="centre"></div>
                      }
                    </tbody>
                  )}
                </table>
              </div>
              <div className="export-data text-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={exporttoggle}
                >
                  {" "}
                  <img src={expo} alt="export" /> Export
                </button>
              </div>
            </div>
            <div className="chart-tabs text-center">
              <div className="tabs-wrap">
                <div
                  className={
                    toggleState === 1 ? "active-chart chatt-tab" : "chatt-tab"
                  }
                  onClick={() => toggleTab(1)}
                >
                  <p>Expense Chart</p>
                </div>
                <hr></hr>
                <div
                  className={
                    toggleState === 2 ? "active-chart chatt-tab" : "chatt-tab"
                  }
                  onClick={() => toggleTab(2)}
                >
                  {" "}
                  <p>Income chart</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalExport isOpen={modalIsOpen} exporttoggle={exporttoggle} />
      </div>
    </>
  );
};
export default Chartcategory;
