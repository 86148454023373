import { object, string, ref, number } from 'yup';

const textValidation = /^[a-zA-Z.]*$/;
const spaceValidation = /^\S*$/;

export const loginFormSchema = object({
    email: string().email('Please enter valid email').matches(spaceValidation, 'Please enter valid email').required('Email is required'),
    password: string().matches(spaceValidation, 'Password should not contain white space').min(6, 'Password should be atleast 6 character long').required('Password is required')
})

export const registerFormSchema = object({
    userName: string().matches(textValidation, 'Invalid user name').min(3, 'User name should be atleast 3 character long').max(200, 'User name should not be greater than 200 character').required('User name is required'),
    email: string().email('Please enter valid email').matches(spaceValidation, 'Please enter valid email').required('Email is required'),
    password: string().matches(spaceValidation, 'Password should not contain white space').min(6, 'Password should be atleast 6 character long').required('Password is required'),
    confirmPassword: string().trim().required("Confirm Password is required").oneOf([ref('password'), null], 'Passwords must match')
})

export const forgotFormSchema = object({
    email: string().email('Please enter valid email').matches(spaceValidation, 'Please enter valid email').required('Email is required')
})

export const addCategorySchema = object({
    name: string().min(3, 'Name should be atleast 3 character long').max(100, 'User name should not be greater than 100 character').required('Name is required'),
    price: number().min(1, 'Budget must be atleast 1').max(999999, 'Budget should not be greater than 99999').required('Budget is required')
})

export const addNoteSchema = object({
    title: string().min(5, 'Tilte should be atleast 5 character long')
        // .max(20, 'Title should not be greater than 20 character')
        .required('Title is required'),
    note: string().min(10, 'Note should be atleast 10 character long')
        // .max(150, 'Note should not be greater than 150 character')
        .required('Note is required'),
    password: number().test('len', 'Must be exactly 4 digits', (val) => {
        if (val) {
            val = '' + val
            return (val.toString().length === 4)
        }
        else {
            return true
        }

    }),
    confirm_password: number().oneOf([ref('password'), null], 'Confirm password must match with password'),
})

export const addTransactionSchema = object({
    amount: number().min(1, 'Price must be atleast 1').max(999999, 'Price should not be greater than 99999').required('Amount is required')
})

export const verifyOtpSchema = object({
    otp: string().required('Otp is required')
})

export const resetNotePassword = object({
    password: number().test('len', 'Must be exactly 4 digits', (val) => {
        if (val) {
            val = '' + val
            return (val.toString().length === 4)
        }
        else {
            return true
        }

    }),
    confirm_password: number().oneOf([ref('password'), null], 'Confirm password must match with password'),
})