import React, { useState } from "react";
import logo from '../../images/logo.png';
import { Formik, Form } from "formik";
import { forgotFormSchema } from "../../utils/validations"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchService } from "../../api";
import { Loader } from "../loader";
import { Link } from "react-router-dom";

const Forgot = () => {
  const [hideLoader, setHideLoader] = useState(false)

  return (
    <header className="App-header">
      {
        hideLoader && <Loader />
      }
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div className="outer-wrap">
                <img src={logo} className="App-logo" alt="logo" />
                <div className="login-form">
                  <h4>Forgot password?</h4>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: ""
                    }}
                    validateOnChange={true}
                    validationSchema={forgotFormSchema}
                    onSubmit={(values, { resetForm }) => {
                      if (values) {
                        setHideLoader(true)
                        fetchService({
                          method: "POST",
                          url: "send-reset-link",
                          body: JSON.stringify(values),
                          isUrl: false,
                          myHeaders: {
                            "Content-Type": "application/json",
                          },
                        }).then(response => {
                          console.log('response---->', response)
                          setHideLoader(false)

                          if (response.data.success === true) {
                            toast.success(response.data.message)
                          }
                        })
                      }
                    }}
                  >
                    
                    {({ errors, touched, values, handleChange, handleSubmit }) => (
                      <Form className="form text-fields" onSubmit={handleSubmit}>
                        <label for="email" className="form-label">Email address</label>
                        <input
                          name="email"
                          type="email"
                          value={values.email}
                          placeholder="Enter email"
                          className="form-control email-field"
                          label="Email"
                          onChange={handleChange}
                        />
                        <div className="error-message">
                          {errors.email ? errors.email : ''}
                        </div>

                        <button type="submit" className="login-btn btn btn-primary w-100 mt-3">Send reset link</button>
                        <div className="ask-signup">
                          <Link to="/">Remember your password? <span>Sign In</span> </Link>
                        </div>

                      </Form>
                    )}

                  </Formik>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Forgot;