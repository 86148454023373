import Login from "../components/auth/login";
import Register from "../components/auth/register";
import Expenditure from "../components/expenditure";
import Transaction from "../components/transaction";
import Budget from "../components/budget";
import Charts from "../components/charts";
import Notes from "../components/notes";
import Settings from "../components/settings";
import Notifications from "../components/notifications";
import Forgot from "../components/auth/forgot";
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ForgotNotePassword from '../components/Notes/VerifyOtp'
import ResetPasswordNote from '../components/Notes/ResetPassword'
import Createinvoices from "../components/create-invoices";
import Invoicepreview from "../components/invoice-preview";

export const AppRoutes = () => {
  const { authUser } = useSelector(state => state.login);

  return useRoutes([
    {
      path: "/",
      element: authUser && authUser.data ? <Navigate to="/expense" /> : <Login />
    },
    {
      path: "/register",
      element: authUser && authUser.data ? <Navigate to="/expense" /> : <Register />
    },
    {
      path: "/expense",
      element: authUser && authUser.data ? <Expenditure /> : <Navigate to="/" />
    },
    {
      path: "/transaction",
      element: authUser && authUser.data ? <Transaction /> : <Navigate to="/" />
    },
    {
      path: "/budget",
      element: authUser && authUser.data ? <Budget /> : <Navigate to="/" />
    },
    {
      path: "/charts",
      element: authUser && authUser.data ? <Charts /> : <Navigate to="/" />
    },
    {
      path: "/notes",
      element: authUser && authUser.data ? <Notes /> : <Navigate to="/" />
    },
    {
      path: "/forgotNotePassword",
      element: authUser && authUser.data ? <ForgotNotePassword /> : <Navigate to="/" />
    },

    {
      path: "/resetNotePassword",
      element: authUser && authUser.data ? <ResetPasswordNote /> : <Navigate to="/" />
    },
    {
      path: "/settings",
      element: authUser && authUser.data ? <Settings /> : <Settings />
    },
    {
      path: "/createinvoice",
      element: authUser && authUser.data ? <Createinvoices /> : <Createinvoices />
    },
    {
      path: "/invoicepreview",
      // element: authUser && authUser.data ? <Invoicepreview /> : <Invoicepreview />
      element: <Invoicepreview />
    },
    {
      path: "/notifications",
      element: authUser && authUser.data ? <Notifications /> : <Navigate to="/" />
    },
    {
      path: "/exportdata",
      element: authUser && authUser.data ? <Notifications /> : <Navigate to="/" />
    },
    {
      path: "/forgot",
      element: <Forgot />
    },
    {
      path: "/logout",
      element: <Navigate to="/" />
    }
  ])
}