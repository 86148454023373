import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Form, Input } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Billto = ({ createnotetoggle5, isOpen, getNotesListing }) => {
  const dispatch = useDispatch()

  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)

  const { authUser } = useSelector((state) => state.login)
  const [billaddress, setAddress] = useState(invoiceDetailsStates.billaddress);
  const [updated, setUpdated] = useState(billaddress);
  const handleChange = (event) => {
      setAddress(event.target.value);
  };

  const handleClick = ()  =>{
      dispatch(saveFormAction({"billaddress": billaddress}))    
      setUpdated(createnotetoggle5);
      createnotetoggle5()
    }



  return (
    <div>
      <Modal className="exp-cat"  isOpen={isOpen} createnotetoggle5={createnotetoggle5} centered>
        <ModalHeader createnotetoggle5={createnotetoggle5}>
        Bill To Address
        </ModalHeader>
            <ModalBody>
            <Form className="form_text">
                  <FormGroup>                
                    <Input
                      name="billaddress"
                      type="textarea"
                      value={billaddress}
                      rows="4"
                      placeholder="Name and address of your customer"
                      onChange={handleChange}
                    />                  
                  </FormGroup>
                  </Form>
              <div className="modal_footer modal_footer1 border-0 mt-0">
                <button className="btn outline_btn" type="submit"  onClick={handleClick}>SAVE</button>
              </div>
            </ModalBody>
      </Modal>
    </div>
  );
}

export default Billto;
