import { addNoteApi, verifyNotePassword, forgotPassword, verifyNoteOtp, resetNotePassword } from "../../api/addNoteApi";
import { types } from "../actionTypes";

export const addNoteAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_NOTE_REQUEST,
    });
    try {
      const results = await addNoteApi(values);
      if (results.data.status_code == 200) {
        return dispatch({
          type: types.ADD_NOTE_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.ADD_NOTE_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.ADD_NOTE_FAIL,
        payload: error,
      });
    }
  };
};

export const verifyNotePasswordAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.NOTE_PASSWORD_VALIDATION_REQUEST,
    });
    try {
      const results = await verifyNotePassword(values);
      if (results.data.status_code == 200) {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_FAILED,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.NOTE_PASSWORD_VALIDATION_FAILED,
        payload: error,
      });
    }
  };
};

export const forgotPasswordAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.NOTE_PASSWORD_VALIDATION_REQUEST,
    });
    try {
      const results = await forgotPassword(values);

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_FAILED,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.NOTE_PASSWORD_VALIDATION_FAILED,
        payload: error,
      });
    }
  };
};

export const verifyNoteOtpAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.NOTE_PASSWORD_VALIDATION_REQUEST,
    });
    try {
      const results = await verifyNoteOtp(values);

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_FAILED,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.NOTE_PASSWORD_VALIDATION_FAILED,
        payload: error,
      });
    }
  };
};

export const resetNotePasswordAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.NOTE_PASSWORD_VALIDATION_REQUEST,
    });
    try {
      const results = await resetNotePassword(values);

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.NOTE_PASSWORD_VALIDATION_FAILED,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.NOTE_PASSWORD_VALIDATION_FAILED,
        payload: error,
      });
    }
  };
};
