import { getSubCategoryApi } from "../../api/getSubCategoryApi";
import { types } from "../actionTypes";

export const getSubCategoryListAction = (values) => {
    return async (dispatch) => {
      dispatch({
        type: types.ADD_CATEGORY_REQUEST,
      });
      try {
        const results = await getSubCategoryApi(values);
        if(results.data.status_code == 200)
        {
          return dispatch({
            type: types.ADD_CATEGORY_SUCCESS,
            payload: results.data,
          });
        } else {
            return dispatch({
                type: types.ADD_CATEGORY_FAIL,
                payload: results.data.message,
            });
        }
      } catch (error) {
        
        return dispatch({
          type: types.ADD_CATEGORY_FAIL,
          payload: error,
        });
      }
    };
  };