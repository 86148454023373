import { dateFilterStartEndApi } from "../../api/dateFilterStartEndApi";
import { types } from "../actionTypes";

export const dateFilterStartEndAction = (values) => {
    return async (dispatch) => {
      dispatch({
        type: types.DATE_FILTER_REQUEST,
      });
      try {
        const results = await dateFilterStartEndApi(values);
        
        if(results.data.data)
        {
          return dispatch({
            type: types.FILTERED_RESPONSE_SUCCESS,
            payload: results.data,
          });
        }
        throw results
      } catch (error) {
        
        return dispatch({
          type: types.DATE_FILTER_FAIL,
          payload: error,
        });
      }
    };
  };