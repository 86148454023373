import { useEffect, useState } from "react";
import GOOGLE from "./google";
import useScript from "./useScript";
import useIsMounted from "./useIsMounted";
import { useDispatch } from "react-redux";
import { setGoogleDriveSyncing } from "../../redux/actions/syncingActions";


import { gapi } from 'gapi-script'

function useGoogle() {
  const dispatch = useDispatch();

  const loaded = useScript("https://apis.google.com/js/api.js");
  const [token, setToken] = useState("");
  const mounted = useIsMounted();

  useEffect(() => {
    function update(google) {
      setToken(google ? google.getAuthResponse().access_token : "");
    }
    if (loaded)
      gapi.load("auth2:picker:client", async () => {
        await gapi.client.init({
          apiKey: GOOGLE.API_KEY,
          clientId: GOOGLE.CLIENT_ID,
          discoveryDocs: GOOGLE.DISCOVERY_DOCS,
          scope: GOOGLE.SCOPES
        });
        if (mounted.current) {
          const instance = gapi.auth2.getAuthInstance();
          instance.currentUser.listen(update);
          update(instance.currentUser.get());
        }
      });
  }, [mounted, loaded]);

  async function authorize() {
    gapi.auth2 && gapi.auth2.getAuthInstance().signIn().then(() => {
      localStorage.setItem('google_drive_sync', true)
      dispatch(setGoogleDriveSyncing(true))

    })
  }

  async function revoke() {
    gapi.auth2 && gapi.auth2.getAuthInstance().disconnect().then(() => {
      localStorage.removeItem('google_drive_sync')
      dispatch(setGoogleDriveSyncing(false))
    })
  }

  return { token, authorize, revoke };
}

export default useGoogle;
