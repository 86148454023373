import { fetchService } from ".";

export const dateFilterApi = async (values) => {
const data = await fetchService({
    method: "POST",
    url: values.url,
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  console.log("RESPONSEAPI--->", data)

  return data
}