import React, { useEffect, useState } from "react";
import logo from '../../images/logo.png';
import { Formik, Form } from "formik";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../loader";
import { resetNotePasswordAction } from "../../redux/actions/addNoteAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { resetNotePassword } from "../../utils/validations"


const ResetPassword = () => {
    const { authUser } = useSelector(state => state.login);
    const dispatch = useDispatch()
    const [hideLoader, setHideLoader] = useState(false)
    const { state } = useLocation()
    const [otp, setOtp] = useState('')
    const navigate = useNavigate()

    return (
        <header className="App-header">
            {
                hideLoader && <Loader />
            }
            <div className="login">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <div className="outer-wrap">
                                <img src={logo} className="App-logo" alt="logo" />
                                <div className="login-form">
                                    <h4>Forgot Note Password Otp?</h4>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            password: "",
                                            confirm_password: ""
                                        }}
                                        validateOnChange={true}
                                        validationSchema={resetNotePassword}
                                        onSubmit={(values, { resetForm }) => {
                                            if (values) {

                                                setHideLoader(true)
                                                const vals = {
                                                    user_id: authUser.data.id,
                                                    id: state.note_id,
                                                    password: values.password,
                                                }

                                                dispatch(resetNotePasswordAction(vals)).then(response => {
                                                    if (response.payload.status_code == '200') {
                                                        toast.success(response.payload.message)
                                                        navigate('/notes')
                                                    }
                                                    else {
                                                        toast.error("Incorrect otp entered")
                                                    }

                                                    setHideLoader(false)
                                                })
                                            }
                                        }}
                                    >
                                        {({ errors, touched, values, handleChange, handleSubmit }) => (
                                            <Form className="form text-fields" onSubmit={handleSubmit}>

                                                <label for="password" className="form-label">Password</label>
                                                <input
                                                    name="password"
                                                    type="password"
                                                    value={values.password}
                                                    placeholder="Enter password"
                                                    className="form-control email-field"
                                                    label="Password"
                                                    onChange={handleChange}
                                                />
                                                <div className="error-message">
                                                    {errors.password ? errors.password : ''}
                                                </div>
                                                <label for="password" className="form-label">Confirm Password</label>
                                                <input
                                                    name="confirm_password"
                                                    type="password"
                                                    value={values.confirm_password}
                                                    placeholder="Enter Confirm password"
                                                    className="form-control email-field"
                                                    label="Password"
                                                    onChange={handleChange}
                                                />
                                                <div className="error-message">
                                                    {errors.confirm_password ? errors.confirm_password : ''}
                                                </div>

                                                <button type="submit" className="login-btn btn btn-primary w-100 mt-3">Change Password</button>

                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default ResetPassword;