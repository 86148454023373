import { fetchService } from ".";

export const getDailyTransactionApi = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "get-daily-transactions",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}


export const fetchTransactionDetail = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "fetch-transaction",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}


export const deleteTransaction = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "delete-transaction",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}