import React, { useEffect, useState } from "react";
import Header from "./partials/header";
import right from "../images/right.svg";
import location from "../images/location.svg";
import ship from "../images/ship.svg";
import edit from "../images/edit.svg";
import plane from "../images/plane.svg";
import cal_2 from "../images/cal_2.svg";
import { useDispatch, useSelector } from "react-redux";
import Switchtemplate from "./common/switch-template";
import Selectlogo from "./common/select-logo";
import Additem from "./common/add-item";
import Billto from "./common/bill-to";
import Currency from "./common/currency";
import Shipinghandeling from "./common/Shiping-handeling";
import Invoicetemplate from "./common/Invoice-template";
import Fromaddress from "./common/From-address";
import Estimatenumber from "./common/Estimatenumber";
import Estimatedate from "./common/Estimatedate";
import Estimateduedate from "./common/Estimateduedate";
import Currenyformating from "./common/Curreny-formating";
import Shipto from "./common/ship-to";
import Termsconditions from "./common/terms-conditions";
import Paymentintegrate from "./common/payment-integrate";
import { Link } from "react-router-dom";
import { saveFormAction } from "../redux/actions/addInvoiceAction";
import ExpireModal from './ExpireModal';

const Createinvoices = () => {
  const { authUser } = useSelector((state) => state.login);

  const [isShown, setIsShown] = useState(false);
  const [isShown1, setIsShown1] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [isShown3, setIsShown3] = useState(false);
  const [isShown4, setIsShown4] = useState(false);
  const [isShown5, setIsShown5] = useState(false);
  const [isShown6, setIsShown6] = useState(false);
  const [isShown7, setIsShown7] = useState(false);
  const [isShown8, setIsShown8] = useState(false);
  const [isShown9, setIsShown9] = useState(false);
  const [isShown10, setIsShown10] = useState(false);
  const [isShown11, setIsShown11] = useState(false);
  const [isShown12, setIsShown12] = useState(false);
  const [isShown13, setIsShown13] = useState(false);
  const [isShown14, setIsShown14] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const createnotetoggle = () => { setIsShown(!isShown) };
  const createnotetoggle1 = () => { setIsShown1(!isShown1) }
  const createnotetoggle2 = () => { setIsShown2(!isShown2) }
  const createnotetoggle3 = () => { setIsShown3(!isShown3) }
  const createnotetoggle4 = () => { setIsShown4(!isShown4) }
  const createnotetoggle5 = () => { setIsShown5(!isShown5) }
  const createnotetoggle6 = () => { setIsShown6(!isShown6) }
  const createnotetoggle7 = () => { setIsShown7(!isShown7) }
  const createnotetoggle8 = () => { setIsShown8(!isShown8) }
  const createnotetoggle9 = () => { setIsShown9(!isShown9) }
  const createnotetoggle10 = () => { setIsShown10(!isShown10) }
  const createnotetoggle11 = () => { setIsShown11(!isShown11) }
  const createnotetoggle12 = () => { setIsShown12(!isShown12) }
  const createnotetoggles13 = () => { setIsShown13(!isShown13) }
  const createnotetoggle14 = () => { setIsShown14(!isShown14) }
  const createnotetoggled = () => { setIsShow(!isShow) }
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)

  const dispatch = useDispatch()

  console.log("invoiceDetailsStates", invoiceDetailsStates)

  const [addItemsTotal, setAddItemsTotal] = useState(0)

  useEffect(() => {
    if (invoiceDetailsStates.addNewItems.length > 0) {
      let sum = 0
      invoiceDetailsStates.addNewItems.forEach((ele) => {
        sum = sum + parseFloat(ele.amount)
      })
      setAddItemsTotal(sum)
    }
  }, [invoiceDetailsStates.addNewItems])

  return (
    <>
      <div className="chart">
        <Header />
        <div className="header_invoice d-flex justify-content-between align-items-center">
          <h1>
            <img src={require('../images/prev.svg').default} alt="" />
            Create an {invoiceDetailsStates.estimate}</h1>
          <Link to='/invoicepreview' className="text-white" style={{ fontSize: "25px" }}>
            <span>Done</span></Link>
        </div>
        <div className="container-fluid">
          <div className="row flex-nowrap">

            <div className="col invoice_background row m-0">
              <div className="col-12">
                <div className="create_invoice_section">
                  <ul>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown1(true)}>
                        <div className="switch_doc">Switch Documents</div>
                        <Invoicetemplate isOpen={isShown1} createnotetoggle1={createnotetoggle1} />
                        <div className="switch_invoice">{invoiceDetailsStates.estimate} <span><img src={right} alt="right" /></span></div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown2(true)}>
                        <div className="switch_doc">Switch Template</div>
                        <Switchtemplate isOpen={isShown2} createnotetoggle2={createnotetoggle2} />
                        <div className="switch_invoice">{invoiceDetailsStates.estimates}  <span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown3(true)}>
                        <div className="switch_doc">Select Logo</div>
                        <Selectlogo isOpen={isShown3} createnotetoggle3={createnotetoggle3} />
                        <div className="switch_invoice">
                          {invoiceDetailsStates.logo_url && <img src={invoiceDetailsStates.logo_url} width={20} height={20} />}
                          <span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    {invoiceDetailsStates.estimate.toLowerCase() == 'estimate' &&
                      (
                        <li>
                          <a href="javascript:;" onClick={() => {

                            dispatch(saveFormAction({ "advancedForm": !invoiceDetailsStates.advancedForm }))
                          }}>
                            <div className="switch_doc">Advanced Form</div>
                            <div className="switch_invoice d-inline-flex">
                              <div className="form-check form-switch">
                                <input className="form-check-input ms-0" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={invoiceDetailsStates.advancedForm} />
                              </div>
                            </div>
                          </a>
                        </li>
                      )}
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown4(true)}>
                        <div className="switch_doc"><span><img src={location} alt="right" /></span>From</div>
                        <Fromaddress isOpen={isShown4} createnotetoggle4={createnotetoggle4} />
                        <div className="switch_invoice d-inline-flex " > <span className="truncate_text1 p_wrap">
                          {invoiceDetailsStates.address}
                        </span><span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown5(true)}>
                        <div className="switch_doc"><span><img src={location} alt="right" /></span>Bill To</div>
                        <Billto isOpen={isShown5} createnotetoggle5={createnotetoggle5} />
                        <div className="switch_invoice d-inline-flex"><span className="truncate_text1 p_wrap"> {invoiceDetailsStates.billaddress}</span><span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>

                    {(invoiceDetailsStates.advancedForm || ["Invoice", "Receipt"].includes(invoiceDetailsStates.estimate)) &&
                      <li>
                        <a href="javascript:;" onClick={() => setIsShown12(true)}>
                          <div className="switch_doc"><span><img src={plane} alt="right" /></span> Ship To</div>
                          <Shipto isOpen={isShown12} createnotetoggle12={createnotetoggle12} />
                          <div className="switch_invoice d-inline-flex"><span className="truncate_text1 p_wrap">{invoiceDetailsStates.shipaddress} </span><span><img src={right} alt="right" /></span> </div>
                        </a>
                      </li>}

                    <li>
                      <a href="javascript:;" onClick={() => setIsShown13(true)}>
                        <div className="switch_doc1"><span><img src={edit} alt="right" /></span>Add or Edit items</div>
                        <Additem isOpen={isShown13} createnotetoggles13={createnotetoggles13} />
                        <div className="switch_invoice" >

                          {invoiceDetailsStates.currencyformat == "$ 0.00" &&
                            (
                              invoiceDetailsStates.addNewItems.length > 0 ?
                                ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? " $ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                          }

                          {/* {invoiceDetailsStates.addNewItems.map((ele) => (<span>{(parseFloat(addItemsTotal)).toFixed(2)}</span>))} */}
                          {
                            addItemsTotal > 0 && <span> {(parseFloat(addItemsTotal)).toFixed(2)} </span>

                          }

                          {
                            invoiceDetailsStates.currencyformat == "0.00 $" && (
                              invoiceDetailsStates.addNewItems.length > 0 ?
                                ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? " $ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                          }
                          <span><img src={right} alt="right" /></span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown7(true)}>
                        <div className="switch_doc2"><span><img src={ship} alt="ship" /></span> Shipping & Handling</div>
                        <Shipinghandeling isOpen={isShown7} createnotetoggle7={createnotetoggle7} />
                        <div className="switch_invoice">
                          {
                            // parseFloat(invoiceDetailsStates.shipamount) > 0 && (
                            <>
                              {/* {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {(parseFloat(invoiceDetailsStates.shipamount || 0.00)).toFixed(2)} */}

                              {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                                invoiceDetailsStates.shipamount.length ?
                                  ((invoiceDetailsStates.currencyformat.length > 0 && !invoiceDetailsStates.currency) ? " $ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                              }
                              {
                                invoiceDetailsStates.shipamount > 0 && <span> {(parseFloat(invoiceDetailsStates.shipamount)).toFixed(2)} </span>
                              }
                              {
                                invoiceDetailsStates.currencyformat == "0.00 $" && (
                                  invoiceDetailsStates.shipamount.length > 0 ?
                                    ((invoiceDetailsStates.currencyformat.length > 0 && !invoiceDetailsStates.currency) ? " $ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                              }
                            </>
                            // )
                          }
                          <span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown8(true)}>
                        <div className="switch_doc3"> <span><img src={require('../../src/images/invoice_no.png')} style={{ width: "auto " }} alt="ship" /></span>
                          {invoiceDetailsStates.estimate} No
                        </div>
                        <Estimatenumber isOpen={isShown8} createnotetoggle8={createnotetoggle8} />
                        <div className="switch_invoice"> {invoiceDetailsStates.estimate_no}<span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown9(true)}>
                        <div className="switch_doc3"><span><img src={cal_2} alt="ship" /></span> {invoiceDetailsStates.estimate} Date</div>
                        <Estimatedate isOpen={isShown9} createnotetoggle9={createnotetoggle9} />
                        <div className="switch_invoice" >{invoiceDetailsStates.date} <span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    {invoiceDetailsStates.estimate.toLowerCase() == 'invoice' &&
                      (
                        <li>
                          <a href="javascript:;" onClick={() => setIsShow(true)}>
                            <div className="switch_doc3"><span><img src={cal_2} alt="ship" /></span>
                              Due Date</div>
                            <Estimateduedate isOpen={isShow} createnotetoggled={createnotetoggled} />
                            <div className="switch_invoice" >{invoiceDetailsStates.due} <span><img src={right} alt="right" /></span> </div>
                          </a>
                        </li>
                      )}

                    <li>
                      <a href="javascript:;" >
                        <div className="switch_doc2"><span><img src={require('../../src/images/currency1.png')} style={{ width: "auto" }} alt="right" /></span>Currency</div>
                        <Currency isOpen={isShown6} createnotetoggle6={createnotetoggle6} />
                        <div className="switch_invoice">
                          {(!invoiceDetailsStates.currency) ? "$" : invoiceDetailsStates.currency}
                          <span><img src={right} alt="right" onClick={() => setIsShown6(true)} /></span> </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:;" onClick={() => setIsShown10(true)}>
                        <div className="switch_doc2"> <span><img src={require('../../src/images/currency_formating.png')} style={{ width: "auto" }} alt="ship" /></span> Curreny Formating</div>
                        <Currenyformating isOpen={isShown10} createnotetoggle10={createnotetoggle10} />
                        <div className="switch_invoice" >

                          {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                            invoiceDetailsStates.addNewItemsAmounts.length > 0 ?
                              ((invoiceDetailsStates.addNewItemsAmounts.length > 0 && !invoiceDetailsStates.currency) ? " $ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                          }
                          {
                            addItemsTotal > 0 && <span> {(parseFloat(addItemsTotal)).toFixed(2)} </span>

                          }
                          {/* <span>
                            {invoiceDetailsStates.addNewItemsAmounts.map((ele) => (<span>{(parseFloat(addItemsTotal)).toFixed(2)}</span>))}
                          </span>
                           */}
                          {
                            invoiceDetailsStates.currencyformat == "0.00 $" && (
                              invoiceDetailsStates.addNewItemsAmounts.length > 0 ?
                                ((invoiceDetailsStates.addNewItemsAmounts.length > 0 && !invoiceDetailsStates.currency) ? " $ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                          }
                          <span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    <li >
                      <a href="javascript:;" onClick={() => setIsShown11(true)}>
                        <div className="switch_doc2"> <span><img src={require('../../src/images/terms1.png')} style={{ width: "auto" }} alt="ship" /></span>Terms & Conditions</div>
                        <Termsconditions isOpen={isShown11} createnotetoggle11={createnotetoggle11} />
                        <div className="switch_invoice d-inline-flex" ><span className="truncate_text1">{invoiceDetailsStates.terms}</span><span><img src={right} alt="right" /></span> </div>
                      </a>
                    </li>
                    {invoiceDetailsStates.estimate.toLowerCase() == 'receipt' &&
                      (
                        <li>
                          <a href="javascript:;" onClick={() => setIsShown14(true)}>
                            <div className="switch_doc2"> <span><img src={require('../../src/images/wallet1.png')} style={{ width: "24px" }} alt="ship" /></span>Integrated Payment</div>
                            <Paymentintegrate isOpen={isShown14} createnotetoggle14={createnotetoggle14} />
                            <div className="switch_invoice">{invoiceDetailsStates.estimated} <span><img src={right} alt="right" /></span> </div>
                          </a>
                        </li>
                      )}
                    {/* <li>
                          <a href="javascript:;">
                            <div className="switch_doc2"> <span><img src={require('../images/no-parking-cross.svg').default} alt="" style={{ width: "24px" }}/></span>Delete Form</div>
                            <div className="switch_invoice"><span><img src={right} alt="right" /></span> </div>
                          </a>
                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          authUser.data.subscription_status == 1 && (
            <ExpireModal />
          )
        }
      </div>
    </>
  );
};
export default Createinvoices;
