import React, { useState, useEffect } from "react";
import Header from "../components/partials/header";
import Navbar from "../components/partials/navbar";
import Pagination from "../components/pagination/pagination";
import expo from "../images/export.svg";
import calender from "../images/cal.svg";
import filter from "../images/filter.svg";
import mobile from "../images/mob.png";
import car from "../images/car-table.png";
import ModalExample from "./common/transaction-filter";
import Transactionadd from "./transactions-add";
import ModalExport from "./common/transaction-export";
import DateFilter from "./dateFilter";
import TransactionFilter from "./common/transaction-filter";
import { transactionFiltersListAction } from "../redux/actions/transactionFiltersListAction";
import { useSelector, useDispatch } from "react-redux";
import useDateFilters from "../hooks/useDateFilters";
import { dateFilterAction } from "../redux/actions/dateFilterAction";
import moment from "moment";
import { getIncomeCategoryListAction } from "../redux/actions/getIncomeCategoryListAction";
import { getIncomeSubCategoryListAction } from "../redux/actions/getIncomeSubCategoryListAction";
import { getDailyTransaction, fetchTransactionDetailAction, deleteTransactionAction } from "../redux/actions/getDailyTransaction";
import { Loader } from "./loader";
import { getExpenseCategoryListAction } from "../redux/actions/getExpenseCategoryListAction";
import { getExpenseSubCategoryListAction } from "../redux/actions/getExpenseSubCategoryListAction";
import { dateFilterStartEndAction } from "../redux/actions/dateFilterStartEndAction";
import { getDataAction } from "../redux/actions/dashboard/getDataAction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as EditSvg } from "../images/edit-button-svgrepo-com.svg";
import deletee from '../images/del.svg';
import DeleteModal from "./common/deleteModal";
import ExpireModal from './ExpireModal';

const Transaction = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transactionList, setTransactionList] = useState(null);
  const [hideLoader, setHideLoader] = useState(false);
  const { authUser } = useSelector((state) => state.login);
  const { requestParams, filteredResponse } = useSelector(
    (state) => state.dateFilter
  );
  const [transactionFiltersList, setTransactionFilterList] = useState(null);
  const [activeTransactionFilter, setActiveTransactionFilter] = useState("");

  const { handleModal, handleDefaultFilter, handlePrevious, handleNext } =
    useDateFilters({
      setHideLoader,
      activeTransactionFilter,
      setTransactionList,
      name: "trans",
    });

  const [incomeCategoryList, setIncomeCategoryList] = useState(null);
  const [expenseCategoryList, setExpenseCategoryList] = useState(null);
  const [incomeSubCategoryList, setIncomeSubCategoryList] = useState(null);
  const [expenseSubCategoryList, setExpenseSubCategoryList] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [fieldValues, setFieldValues] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const exporttoggle = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const fetchFilteredResults = (selectedFilterValue) => {
    if (requestParams) {
      switch (requestParams.dispatchAction) {
        case "dateFilterAction":
          setShowLoader(true);

          requestParams.filter = selectedFilterValue;
          dispatch(dateFilterAction(requestParams)).then((response) => {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);

            setShowLoader(false);
          });
          break;

        case "dateFilterStartEndAction":
          setShowLoader(true);

          requestParams.filter = selectedFilterValue;
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
            setShowLoader(false);
          });
          break;

        default:
          setShowLoader(true);
          requestParams.filter = selectedFilterValue;
          dispatch(getDataAction(requestParams)).then((response) => {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
            setShowLoader(false);
          });
          break;
      }
    } else {
    }
  };

  const fetchTransactionList = () => {
    let requestParams = {
      user_id: authUser.data.id,
      filter_date: moment().format("YYYY-MM-DD"),
    };
    setShowLoader(true);

    dispatch(getDailyTransaction(requestParams)).then((response) => {
      if (
        response.type !== "DASHBOARD_DATA_FAIL" &&
        response.payload.data.length
      ) {
        console.log(response.payload.data)
        setTransactionList(response.payload.data);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    });
  };

  useEffect(() => {
    setShowLoader(true);

    let requestParams = {
      user_id: authUser.data.id,
      filter_date: moment().format("YYYY-MM-DD"),
    };

    dispatch(transactionFiltersListAction(requestParams)).then((response) => {
      if (response.payload.status_code === "200") {
        setTransactionFilterList(response.payload.data);
      }
    });

    fetchTransactionList();

    dispatch(getIncomeCategoryListAction(requestParams)).then((response) => {
      setIncomeCategoryList(response.payload.data);
    });

    dispatch(getExpenseCategoryListAction(requestParams)).then((response) => {
      setExpenseCategoryList(response.payload.data);
    });

    dispatch(getExpenseSubCategoryListAction(requestParams)).then(
      (response) => {
        setExpenseSubCategoryList(response.payload.data);
      }
    );

    dispatch(getIncomeSubCategoryListAction(requestParams)).then((response) => {
      setIncomeSubCategoryList(response.payload.data);
    });
    setShowLoader(false);
  }, []);

  const getTransactionDetail = (id) => {
    try {
      let requestBody = {
        "transaction_id": id
      }

      setShowLoader(true);

      dispatch(fetchTransactionDetailAction(requestBody)).then((response) => {
        if (
          response.type !== "DASHBOARD_DATA_FAIL"
        ) {
          setFieldValues(response.payload.data)
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      });
    }
    catch (err) {
      console.log(err)
    }
  }

  const deleteTransaction = (id) => {
    try {
      let requestBody = {
        date: moment().format("YYYY-MM-DD"),
        id: id,
        type: 1,
        "user_id": authUser.data.id
      }
      setShowLoader(true);

      dispatch(deleteTransactionAction(requestBody)).then((response) => {
        if (
          response.type !== "DASHBOARD_DATA_FAIL"
        ) {
          let newList = transactionList.filter((ele) => ele.id != id)
          setTransactionList(newList)
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
        setDeleteId(false)
        setShowDeleteModal(false)
      });
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {showLoader && <Loader />}
      <div className="transaction">
        <Header />
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 navv">
              <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                <Navbar />
              </div>
            </div>
            <div className="col background-bg h-100">
              <div className="category-filter d-flex">
                <div className="w-100">
                  <div className="top-bar">
                    <div className="right-actions">
                      <button
                        type="button"
                        onClick={exporttoggle}
                        className="btn btn-outline-primary"
                      >
                        <img src={expo} alt="export" />
                        Export
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary ms-2"
                        onClick={toggle}
                      >
                        <img src={filter} alt="filter" />
                        Filter
                      </button>
                    </div>
                    <div className="left-actions">
                      {/* <button type="button" className="btn btn-outline-primary"><img src={calender}  alt="filter"/> 
                                    2 june 2022</button> */}

                      <DateFilter
                        setHideLoader={setHideLoader}
                        setShowLoader={setShowLoader}
                        activeTransactionFilter={activeTransactionFilter}
                        setTransactionList={setTransactionList}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="transaction-data-list table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {transactionList != null && transactionList.length > 0 &&
                        transactionList[0].hasOwnProperty("start_date") && (
                          <>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                          </>
                        )}

                      {transactionList != null && transactionList.length > 0 &&
                        !transactionList[0].hasOwnProperty("start_date") && (
                          <>
                            <th scope="col">Date</th>
                          </>
                        )}
                      <th scope="col">Category</th>
                      <th scope="col">Sub- category</th>
                      <th scope="col">Transaction type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList != null &&
                      transactionList.map((transaction, key) => {
                        return (
                          <tr key={key}>
                            <th scope="row">{Number(key) + Number(1)}</th>
                            {transactionList[0].hasOwnProperty(
                              "start_date"
                            ) && (
                                <>
                                  <td>{transaction.start_date}</td>
                                  <td>{transaction.end_date}</td>
                                </>
                              )}

                            {!transactionList[0].hasOwnProperty(
                              "start_date"
                            ) && (
                                <>
                                  <td>{transaction.date}</td>
                                </>
                              )}

                            <td>{transaction.category.category.name}</td>
                            <td>
                              {transaction.category.sub_category.name !== ""
                                ? transaction.category.sub_category.name
                                : "--"}
                            </td>
                            <td>{transaction.transaction_type}</td>
                            <td>{transaction.amount}</td>
                            <td >
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip >Edit</Tooltip>}
                              >
                                <EditSvg height={20} width={20} fill="black" className="edit_svg me-3"
                                  onClick={() => getTransactionDetail(transaction.id)}
                                />
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip >Delete</Tooltip>}
                              >
                                <img src={deletee} alt="delete"
                                  onClick={() => {
                                    setDeleteId(transaction.id)
                                    setShowDeleteModal(true)
                                    // deleteTransaction(transaction.id)
                                  }}
                                />
                              </OverlayTrigger>

                            </td>
                          </tr>
                        );
                      })}
                    {transactionList === null && (
                      <tr className="no-data-found mx-auto">No data found.</tr>
                    )}
                  </tbody>
                </table>
              </div>
              {/* <Pagination />  */}
              <Transactionadd
                incomeCategoryList={incomeCategoryList}
                incomeSubCategoryList={incomeSubCategoryList}
                expenseCategoryList={expenseCategoryList}
                expenseSubCategoryList={expenseSubCategoryList}
                fetchTransactionList={fetchTransactionList}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
              />
            </div>
          </div>
        </div>
        <TransactionFilter
          isOpen={isOpen}
          toggle={toggle}
          transactionFiltersList={transactionFiltersList}
          activeTransactionFilter={activeTransactionFilter}
          setActiveTransactionFilter={setActiveTransactionFilter}
          fetchFilteredResults={fetchFilteredResults}
        />
        <ModalExport isOpen={modalIsOpen} exporttoggle={exporttoggle} />

        {
          showDeleteModal &&
          <DeleteModal
            isShow={showDeleteModal}
            onConfirm={() => deleteTransaction(deleteId)}
            setShowModal={() => setShowDeleteModal(!showDeleteModal)}
          />
        }

        {
          authUser.data.subscription_status == 1 && (
            <ExpireModal />
          )
        }
      </div>
    </>
  );
};
export default Transaction;
