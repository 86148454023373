export const DateFilterReducer = (state = {}, action)=>{
    switch (action.type) {
        case 'DATE_FILTER_REQUEST':
          return { ...state}

        case 'DATE_FILTER_SUCCESS':
          return { ...state, 'dateFilterResponse': action.payload}

        case 'FILTERED_RESPONSE_SUCCESS':
          return { ...state, 'filteredResponse': action.payload}

        case 'HIT_FILTERS_API':
          return {...state, 'requestParams': action.requestParams}

        case 'UPDATE_TRANSACTION_FILTER':
          return {...state, 'activeTransactionFilterReducer': action.activeTransactionFilterReducer}

        default:
          return {...state}
      }
}