import axios from "axios";
//const baseUrlLocal = "http://localhost:8000/api/";
const baseUrlLocal = "https://dev-spenleaf.iapplabz.co.in/api/";
const baseUrlLocalOld = "https://spenleaf-backend.iapplabz.co.in/api/";
const baseUrlDev = "https://spenleaf-backend.iapplabz.co.in/api/";
const liveUrl = "https://spenleaf.spenweek.com/api/";
export const fetchService = ({ method, url, body, myHeaders }) =>
  axios({
    //   url : `${process.env.BASE_URL}`+url,
    url: baseUrlLocal + url,
    method, // *GET, POST, PUT, DELETE, etc.
    headers: {
      ...myHeaders,
      Accept: "application/json",
      // Authorization: `Bearer ${Token}`,
    },
    data: body,
  })
    .then((response) => {
      if (response) {
        return response;
      }
      throw response;
    })
    .catch((error) => {
      console.log("@fetchService error =>", { url, error });
      throw error;
    });
