import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';

const TransactionFilter = ({toggle,isOpen,transactionFiltersList,setActiveTransactionFilter,activeTransactionFilter,fetchFilteredResults}) => {
  const handleDefaultFilter = (e) => {
    console.log(e.target.value)
    setActiveTransactionFilter(e.target.value)
    toggle() // hide the modal
    fetchFilteredResults(e.target.value)
  }

  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Transaction filters</ModalHeader>
        <ModalBody>
          <Form>
              <FormGroup>
                  {
                    transactionFiltersList!=null && transactionFiltersList.map((filter) => {
                        return  <div className='d-flex align-items-center mb-1'>
                                  <input className="me-2"
                                    type="radio"
                                    name="transaction_filter"
                                    value={filter.value}
                                    onChange={handleDefaultFilter}
                                    checked={activeTransactionFilter == filter.value}
                                  />  
                                  <p className='mb-0'> {filter.name} </p>
                                </div>
                    })
                  }
            </FormGroup>
          </Form>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" onClick={toggle}>Save</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter> */}
      </Modal>
    </div>   
  );
  
}

export default TransactionFilter;