import React, { useState } from "react";
import { FormGroup } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Termsconditions = ({ createnotetoggle11, isOpen, getNotesListing }) => {
    const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
    const dispatch = useDispatch()
    const [terms, setTerms] = useState(invoiceDetailsStates.terms);
    const [updated, setUpdated] = useState(terms);
    const handleChange = (event) => {
        setTerms(event.target.value);
    };

    const handleClick = ()  =>{
        dispatch(saveFormAction({"terms": terms}))    
        setUpdated(createnotetoggle11);
      }

    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle11={createnotetoggle11} centered>
                <ModalHeader createnotetoggle11={createnotetoggle11}>
                    Terms and Conditions
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input
                                name="terms"
                                type="textarea"
                                value={terms}
                                rows="4"
                                placeholder="Terms & Conditions"
                                className="form-control form_text"
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Form>
                    <div className="modal_footer modal_footer1 border-0 mt-0">
                        <button className="btn outline_btn" type="submit" onClick={handleClick} >SAVE</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Termsconditions;
