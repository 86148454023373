import { fetchService } from ".";

export const addTransactionApi = async (values) => {
  console.log('values', values)
  const data = await fetchService({
    method: "POST",
    url: "add-transaction",
    body: values,
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}