import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Selectlogo = ({ createnotetoggle3, isOpen, getNotesListing, setShow }) => {

  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const dispatch = useDispatch()
  const { authUser } = useSelector((state) => state.login)
  const [file, setFile] = useState(invoiceDetailsStates.file);
  const [fileDataURL, setFileDataURL] = useState(null);

  const changeHandler = (e) => {
    const file = e.target.files[0];
    setFile(file);
  }
  
  useEffect(() => {
    let fileReader, isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result)
      dispatch(saveFormAction({ "logo_url": result }))

        }
      }
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }

  }, [file]);

  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle3={createnotetoggle3} centered>
        <ModalHeader createnotetoggle3={createnotetoggle3}>
          Select Logo
        </ModalHeader>
        <ModalBody>
          <div className="add_list_btn1 select_logo d-flex justify-content-between">
            <span>
              <img src={require('../../images/folder.png')} alt="" className="add_img" />
            </span>
            Select Your Logo
            <span className="upload_span">

              {fileDataURL && <img src={fileDataURL} width={20} height={20} />}
              <input type="file" onChange={(e) => {
                // onSelectFile(e)
                changeHandler(e)
              }} />
              
              <img src={require('../../images/right.svg').default} alt="" className="right_img1" />
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" onClick={createnotetoggle3} color="primary">Done</Button>
          <Button color="secondary" onClick={createnotetoggle3}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );

}

export default Selectlogo;
