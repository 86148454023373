import React from "react";
import incom from '../images/dash-income.svg';
import expensee from '../images/dash-expense.svg';
import carryover from '../images/carry-over.svg';
import balance from '../images/balance.svg';
import expense from '../images/expense.svg';
import income from '../images/income.svg';

const Expdashboard = ({dashboardData}) => {

 return (
      <>
    <div className="row">
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
       <div className="exp-outer-wrap">
          <div>
             <span>Income</span>
             <h2>{dashboardData && dashboardData != null ? dashboardData.TotalIncome  : '$ 0.00'}</h2>
          </div>
          <img src={incom} alt="income" />
       </div>
    </div>
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
       <div className="exp-outer-wrap">
          <div>
             <span>expense</span>
             <h2>{dashboardData && dashboardData != null ? dashboardData.TotalExpense  : '$ 0.00'}</h2>
          </div>
          <img src={expensee} alt="expenese" />
       </div>
    </div>
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
       <div className="exp-outer-wrap">
          <div>
             <span>carry over</span>
             <h2>{dashboardData && dashboardData != null ? dashboardData.carry_over  : '$ 0.00'}</h2>
          </div>
          <img src={carryover} alt="expenese" />
       </div>
    </div>
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
       <div className="exp-outer-wrap">
          <div>

             <span>balance</span>
             <h2>{dashboardData && dashboardData != null ? dashboardData.Balance  : '$ 0.00'}</h2>
          </div>
          <img src={balance} alt="balance" />
       </div>
    </div>
    <div className="side-data col-lg-12 justify-content-end mt-4 d-flex mb-3">
       {/* <button type="button" className="btn btn-secondary add-category exp-butn me-2" > <img src={expense} alt="expense" /> Expense</button>
       <button type="button" className="btn btn-secondary add-category"> <img src={income} alt="income" /> Income</button> */}
    </div>
 </div>
 </>
 );
}
export default Expdashboard;