import React, { useState, useEffect } from "react";
import add from '../images/add.svg';
import car from '../images/car-table.png';
import mobile from '../images/mob.png';
import holiday from '../images/holiday.svg';
import sports from '../images/sports.svg';
import deletee from '../images/del.svg';
import Modalsubcat from "./common/expense-sub-cat";
import { getSubCategoryListAction } from "../redux/actions/getSubCategoryListAction";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubCategoryAction } from "../redux/actions/deleteSubCategoryAction";
import { toast } from 'react-toastify';
import { getIncomeSubCategoryListAction } from "../redux/actions/getIncomeSubCategoryListAction";
import { Loader } from "./loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as EditSvg } from "../images/edit-button-svgrepo-com.svg";

// import useEffect from "../utils/useEffect";

const Subcategory = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [subCategoryList, setSubCategoryList] = useState(null) // manage category for expense
    const [incomeSubCategoryList, setIncomeSubCategoryList] = useState(null)
    const [showLoader, setShowLoader] = useState(false)
    const dispatch = useDispatch()
    const { authUser } = useSelector((state) => state.login)

    const [editValues, setEditValues] = useState(null)

    const toggle = (values = null) => {
        setIsOpen(!isOpen)
        setEditValues(values)
    }


    const [currentTab, setCurrentTab] = useState('Expense')

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);

        if (index === 2) {
            renderIncomeSubCategories()
            setCurrentTab('Income')
        }

        else {
            renderSubCategoryListing()
            setCurrentTab('Expense')
        }
    }

    const deleteSubCategory = (subCategoryId) => {
        // show loader
        setShowLoader(true)

        let requestParams = {
            'user_id': authUser.data.id,
            'category_id': subCategoryId
        }

        dispatch(deleteSubCategoryAction(requestParams)).then(response => {

            if (response.payload.status_code == '200') {
                toast.success(response.payload.message)

                currentTab == 'Income' ? renderIncomeSubCategories() : renderSubCategoryListing()
            }

            else
                toast.error(response.payload.message)


            setShowLoader(false)
        })
    }

    const renderSubCategoryListing = () => {
        // show loader
        setShowLoader(true)

        let requestParams = {
            'user_id': authUser.data.id
        }

        if (requestParams) {
            dispatch(getSubCategoryListAction(requestParams)).then(response => {
                setSubCategoryList(response.payload.data)
            })

            setShowLoader(false)
        }
    }

    const renderIncomeSubCategories = () => {
        // show loader
        setShowLoader(true)

        let requestParams = {
            'user_id': authUser.data.id
        }

        if (requestParams) {
            dispatch(getIncomeSubCategoryListAction(requestParams)).then(response => {
                setIncomeSubCategoryList(response.payload.data)
            })

            setShowLoader(false)
        }
    }

    useEffect(() => {
        renderSubCategoryListing()
    }, []);

    return (
        <>
            {
                showLoader && <Loader />
            }
            <div className="Exp-category">
                <div className="category-wrap d-flex justify-content-between align-items-center">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex mb-0">
                            <li className="breadcrumb-item"><a href="#">{currentTab == 'Expense' ? 'Expenditures' : 'Incomes'}</a></li> <span className="mx-2"> {">"} </span>
                            <li className="breadcrumb-item active" aria-current="page">Sub category</li>
                        </ol>
                    </nav>

                    <button type="button" className="btn btn-primary add-category-exp" onClick={() => {
                        toggle(null)
                    }}><img src={add} alt="add" />Add</button>
                </div>

                <div className="table-content">
                    <div className={toggleState === 1 ? "transaction-data-list active category-table table-responsive" : "category-table"}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Sub category</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    subCategoryList !== null && subCategoryList?.map((subCategory) => {
                                        return <tr id={subCategory.id}>
                                            <th scope="row">{subCategory.id}</th>
                                            <td> <span><img src={mobile} alt="mob" /></span> {subCategory.name}</td>
                                            <td> {subCategory.budget}</td>
                                            {/* <td><span><img src={mobile} alt ="mob" /></span>Mobile</td> */}
                                            {/* <td onClick={()=>deleteSubCategory(subCategory.id)}><img src={deletee} alt="delete"/></td> */}
                                            <td >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Edit</Tooltip>}
                                                >
                                                    <EditSvg height={20} width={20} fill="black" className="edit_svg me-3" onClick={() => {
                                                        toggle(subCategory)
                                                    }} />
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Delete</Tooltip>}
                                                >
                                                    <img src={deletee} alt="delete" onClick={() => deleteSubCategory(subCategory.id)} />
                                                </OverlayTrigger>

                                            </td>
                                        </tr>
                                    })

                                }
                                {
                                    subCategoryList === null && 'No data found.'
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className={toggleState === 2 ? "transaction-data-list active category-table table-responsive" : "category-table"}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    {/* <th scope="col">Date</th> */}
                                    <th scope="col">Sub-category</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    incomeSubCategoryList !== null && incomeSubCategoryList.map((incomeSubCategory) => {
                                        return <tr id={incomeSubCategory.id}>
                                            <th scope="row">{incomeSubCategory.id}</th>
                                            <td> <span><img src={mobile} alt="mob" /></span> {incomeSubCategory.name}</td>
                                            <td> {incomeSubCategory.budget}</td>
                                            {/* <td><span><img src={mobile} alt ="mob" /></span>Mobile</td> */}
                                            {/* <td onClick={() => deleteSubCategory(incomeSubCategory.id)}><img src={deletee} alt="delete" /></td> */}
                                            <td >
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Edit</Tooltip>}
                                                >
                                                    <EditSvg height={20} width={20} fill="black" className="edit_svg me-3" onClick={() => {
                                                        toggle(incomeSubCategory)
                                                    }} />
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip >Delete</Tooltip>}
                                                >
                                                    <img src={deletee} alt="delete" onClick={() => deleteSubCategory(incomeSubCategory.id)} />
                                                </OverlayTrigger>

                                            </td>
                                        </tr>
                                    })

                                }
                                {
                                    incomeSubCategoryList === null && 'No data found.'
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <div className="d-inline-flex expense-tabs">
                        <span className={toggleState === 1 ? "active expense-tab" : "expense-tab"} onClick={() => toggleTab(1)}>Expense</span>
                        <hr></hr>
                        <span className={toggleState === 2 ? "active income-tab" : "income-tab"} onClick={() => toggleTab(2)}>Income</span>
                    </div>
                </div>
                <Modalsubcat isOpen={isOpen} toggle={toggle}
                    renderSubCategoryListing={renderSubCategoryListing}
                    currentTab={currentTab}
                    renderIncomeSubCategories={renderIncomeSubCategories}
                    rowValues={editValues}
                />

            </div>
        </>
    );
}
export default Subcategory;