import React, { useState, createRef, useRef, useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fetchExportDataAction } from '../../redux/actions/fetchExportDataAction';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '../loader';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import { CSVLink } from "react-csv";
import useGoogle from '../googleDrive/useGoogle';

const ModalExport = ({ exporttoggle, isOpen }) => {
    const { token } = useGoogle();
    let dropbox_access_token = localStorage.getItem('dropbox_access_token')

    const [exportType, setExportType] = useState('PDF')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndate] = useState(null)
    const dispatch = useDispatch()
    const { authUser } = useSelector(state => state.login);
    const [showLoader, setShowLoader] = useState(false)
    const csvLinkRef = useRef()
    const [csvData, setCsvData] = useState([])
    const [exportButton, setExportButton] = useState(false)
    const [csvFileName, setCsvFileName] = useState('Exported Data')

    const handleExportType = (event) => {
        setExportType(event.target.value)
    }

    const handleStartDate = (event) => {
        setStartDate(event.target.value)
    }

    const handleEndDate = (event) => {
        setEndate(event.target.value)
    }

    const handleExportData = () => {
        exporttoggle()
        setShowLoader(true)
        let requestParams = {
            'user_id': authUser.data.id,
            'start_date': startDate,
            'end_date': endDate,
            'req_type' : "web",
        }

        dispatch(fetchExportDataAction(requestParams)).then(response => {
            if (response.payload.status_code == "200") {
                let exportedData = response.payload.data.transactions
                let exportedDataArray = []

                if (exportType === 'CSV') {
                    exportedDataArray.push([
                        '#', 'DATE', 'CATEGORY', 'SUB-CATEGORY', 'TRANSACTION TYPE', 'AMOUNT'
                    ])
                }

                exportedData.length > 0 && exportedData.map((data, key) => {
                    exportedDataArray.push([Number(key) + Number(1),
                    data.date,
                    data.category,
                    data.subcategory_name,
                    data.transaction_type,
                    data.amount
                    ])
                })

                if (exportType === 'PDF') {
                    const doc = new jsPDF()
                    // doc.text(response.payload.message , 20, 60, 'center')
                    doc.text(response.payload.message, 0, 0, 'center');
                    // It can parse html:
                    // <table id="my-table"><!-- ... --></table>
                    autoTable(doc, { html: '#my-table' })

                    // Or use javascript directly:
                    autoTable(doc, {
                        startY: doc.autoTable() + 70,
                        margin: { horizontal: 10 },
                        styles: { overflow: "linebreak" },
                        bodyStyles: { valign: "top" },
                        // columnStyles: { email: { cellWidth: "wrap" } },
                        head: [['#', 'DATE', 'CATEGORY', 'SUB-CATEGORY', 'TRANSACTION TYPE', 'AMOUNT']],
                        body: exportedDataArray,
                        // [
                        //     ['David', 'david@example.com', 'Sweden'],
                        //     ['Castille', 'castille@example.com', 'Spain'],
                        // // ...
                        // ],
                        // didDrawPage: (data) => {
                        //     doc.text(response.payload.message, data.settings.margin.left, 22);
                        // }
                    })

                    let pdfName = new Date().toString() + '.pdf'


                    doc.save(pdfName)
                    // doc.save(response.payload.message + '.pdf')

                    var blobPDF = new Blob([doc.output()], { type: 'application/pdf' });
                    // var file = new File([blobPDF], new Date().toString() + '.pdf');
                    let url = doc.output("datauri")

                    if (token) {

                        const form = new FormData();
                        form.append(
                            "metadata",
                            new Blob(
                                [
                                    JSON.stringify({
                                        name: pdfName,
                                        mimeType: 'pdf'
                                    })
                                ],
                                { type: "application/json" }
                            )
                        );
                        form.append("file", blobPDF);
                        fetch(
                            "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=name,webViewLink,id,mimeType",
                            {
                                method: "POST",
                                headers: { Authorization: "Bearer " + token },
                                body: form
                            }
                        ).then((res) => res.json());
                    }

                    if (dropbox_access_token) {
                        fetch(
                            "https://api.dropboxapi.com/2/files/save_url",
                            {
                                method: "POST",
                                headers: {
                                    Authorization: "Bearer " + dropbox_access_token,
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    "path": `/${pdfName}`,
                                    "url": url
                                })
                            }
                        ).then((res) => res.json()).catch(err => console.log(err));
                        // fetch(
                        //     "https://content.dropboxapi.com/2/files/upload",
                        //     {
                        //         method: "POST",
                        //         headers: {
                        //             Authorization: "Bearer " + dropbox_access_token,
                        //             "Dropbox-API-Arg": { "autorename": false, "mode": "add", "mute": false, "path": 'hi22uguguigkgbkjb.pdf', "strict_conflict": false },
                        //             "Content-Type": "application/octet-stream"
                        //         },
                        //         body: blobPDF
                        //     }
                        // ).then((res) => res.json()).catch(err => console.log(err));
                    }
                }

                else {
                    setCsvData(exportedDataArray)
                    setExportButton(true)
                    setExportType('PDF')
                    setCsvFileName(response.payload.message)
                }

                setShowLoader(false)
            }

            else {
                toast.error(response.payload)
                setShowLoader(false)
            }

        })
    }

    const handleExportDataDev = async () => {
        exporttoggle()
        setShowLoader(true)

        let requestParams = {
            'user_id': authUser.data.id,
            'start_date': startDate,
            'end_date': endDate,
            'req_type' : "web",
        }

        // dispatch(fetchExportDataAction(requestParams)).then(response => {
        //     if(response.payload.status_code == "200")
        //     {
        //         let exportedData = response.payload.data.transactions
        //         let exportedDataArray = []

        //         if(exportType === 'CSV')
        //         {
        //             exportedDataArray.push([
        //                 '#', 'DATE', 'CATEGORY', 'SUB-CATEGORY', 'TRANSACTION TYPE', 'AMOUNT'
        //             ])
        //         }

        //         exportedData.length>0 && exportedData.map((data, key) => {
        //                 exportedDataArray.push([ Number(key) + Number(1),
        //                                         data.date,
        //                                         data.category,
        //                                         data.subcategory_name,
        //                                         data.transaction_type,
        //                                         data.amount
        //             ]) 
        //         })

        // if(exportType === 'PDF')
        // {
        const doc = new jsPDF()
        // doc.text(response.payload.message , 20, 60, 'center')
        doc.text("JeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjotJeevanjot", 20, 60, 'center');
        // It can parse html:
        // <table id="my-table"><!-- ... --></table>
        // autoTable(doc, { html: '#my-table' })

        // // Or use javascript directly:
        // autoTable(doc, {
        //     startY: doc.autoTable() + 70,
        //     margin: { horizontal: 10 },
        //     styles: { overflow: "linebreak" },
        //     bodyStyles: { valign: "top" },
        //     // columnStyles: { email: { cellWidth: "wrap" } },
        //     head: [['#', 'DATE', 'CATEGORY', 'SUB-CATEGORY', 'TRANSACTION TYPE', 'AMOUNT']],
        //     body: exportedDataArray,
        //     // [
        //     //     ['David', 'david@example.com', 'Sweden'],
        //     //     ['Castille', 'castille@example.com', 'Spain'],
        //     // // ...
        //     // ],
        //     // didDrawPage: (data) => {
        //     //     doc.text(response.payload.message, data.settings.margin.left, 22);
        //     // }
        // })

        let pdfName = new Date().toString() + '.pdf'
        doc.save(pdfName)
        var blobPDF = new Blob([doc.output()], { type: 'application/pdf' });
        // var file = new File([blobPDF], new Date().toString() + '.pdf');
        let url = doc.output("datauri")

        if (token) {

            const form = new FormData();
            form.append(
                "metadata",
                new Blob(
                    [
                        JSON.stringify({
                            name: pdfName,
                            mimeType: 'pdf'
                        })
                    ],
                    { type: "application/json" }
                )
            );
            form.append("file", blobPDF);
            fetch(
                "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=name,webViewLink,id,mimeType",
                {
                    method: "POST",
                    headers: { Authorization: "Bearer " + token },
                    body: form
                }
            ).then((res) => res.json());
        }

        if (dropbox_access_token) {
            fetch(
                "https://api.dropboxapi.com/2/files/save_url",
                {
                    method: "POST",
                    headers: {
                        Authorization: "Bearer " + dropbox_access_token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "path": `/${pdfName}`,
                        "url": url
                    })
                }
            ).then((res) => {console.log("DROPBOX file uploaded");res.json()}).catch(err => console.log(err));
            // fetch(
            //     "https://content.dropboxapi.com/2/files/upload",
            //     {
            //         method: "POST",
            //         headers: {
            //             Authorization: "Bearer " + dropbox_access_token,
            //             "Dropbox-API-Arg": { "autorename": false, "mode": "add", "mute": false, "path": 'hi22uguguigkgbkjb.pdf', "strict_conflict": false },
            //             "Content-Type": "application/octet-stream"
            //         },
            //         body: blobPDF
            //     }
            // ).then((res) => res.json()).catch(err => console.log(err));
        }

        // }

        // else
        // {
        //     setCsvData(exportedDataArray)
        //     setExportButton(true)
        //     setExportType('PDF')
        //     setCsvFileName(response.payload.message)
        // }

        //         setShowLoader(false)
        //     }

        //     else
        //     {
        //         toast.error(response.payload)
        //         setShowLoader(false)
        //     }

        // })
    }

    useEffect(() => {
        if (exportButton) {
            csvLinkRef.current.link.click()
            setExportButton(false)
        }
    }, [exportButton])

    return (
        <>
            {
                showLoader && <Loader />
            }
            <div>
                <Modal className="export" isOpen={isOpen} exporttoggle={exporttoggle} centered>
                    <ModalHeader >Export As</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Input type="select" name="exportType" onChange={handleExportType}>
                                    <option>PDF</option>
                                    <option>CSV</option>
                                </Input>
                            </FormGroup>
                            <div className="date-range">
                                <h4>Date Range</h4>
                                <div className="date-format">
                                    <div className='d-flex justify-content-between'>
                                        <span>Start Date</span>
                                        <input type="date" className='datee' onChange={handleStartDate} />
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <span>End Date</span>
                                        <input type="date" className='datee' onChange={handleEndDate} />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={handleExportData}>Export Now</Button>{' '}
                        <Button color="secondary" onClick={exporttoggle}>Cancel</Button>
                    </ModalFooter>

                    <CSVLink data={csvData} filename={csvFileName + ".csv"} ref={csvLinkRef} ></CSVLink>

                </Modal>
            </div>
        </>
    );
}
export default ModalExport;