import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Currency = ({ createnotetoggle6, isOpen, getNotesListing }) => {
  const dispatch = useDispatch()
  const { authUser } = useSelector((state) => state.login)
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)

  const [selectedTab, setSelectedTab] = useState("symbols");


  const handleClick = (e, val) => {
    dispatch(saveFormAction({ "currency": val }))
    createnotetoggle6()
  }

  const currencyCode = [
    {
      label: "Default",
      value: "Default",
    },
    {
      label: "AED",
      value: "AED",
    },
    {
      label: "AFN",
      value: "AFN",
    },
    {
      label: "ALL",
      value: "ALL",
    },
    {
      label: "AMD",
      value: "AMD",
    },
    {
      label: "ANG",
      value: "ANG",
    },
    {
      label: "AOA",
      value: "AOA",
    },
    {
      label: "ARS",
      value: "ARS",
    },
    {
      label: "AUD",
      value: "AUD",
    },
    {
      label: "AWG",
      value: "AWG",
    },
    {
      label: "AZN",
      value: "AZN",
    },
    {
      label: "BAM",
      value: "BAM",
    },
    {
      label: "BBD",
      value: "BBD",
    },
    {
      label: "BDT",
      value: "BDT",
    },
    {
      label: "BGN",
      value: "BGN",
    },
    {
      label: "BHD",
      value: "BHD",
    },
    {
      label: "BIF",
      value: "BIF",
    },
    {
      label: "BMD",
      value: "BMD",
    },
    {
      label: "BND",
      value: "BND",
    },
    {
      label: "BOB",
      value: "BOB",
    },
    {
      label: "BRL",
      value: "BRL",
    },
    {
      label: "BSD",
      value: "BSD",
    },
    {
      label: "BTN",
      value: "BTN",
    },
    {
      label: "BWP",
      value: "BWP",
    },
    {
      label: "BYN",
      value: "BYN",
    },
    {
      label: "BZD",
      value: "BZD",
    },
    {
      label: "CAD",
      value: "CAD",
    },
    {
      label: "CDF",
      value: "CDF",
    },
    {
      label: "CFA",
      value: "CFA",
    },
    {
      label: "CHF",
      value: "CHF",
    },
    {
      label: "CLP",
      value: "CLP",
    },
    {
      label: "CNY",
      value: "CNY",
    },
    {
      label: "COP",
      value: "COP",
    },
    {
      label: "CRC",
      value: "CRC",
    },
    {
      label: "CUC",
      value: "CUC",
    },
    {
      label: "CUP",
      value: "CUP",
    },
    {
      label: "CVE",
      value: "CVE",
    },
    {
      label: "CZK",
      value: "CZK",
    },
    {
      label: "DJF",
      value: "DJF",
    },
    {
      label: "DKK",
      value: "DKK",
    },
    {
      label: "DOP",
      value: "DOP",
    },
    {
      label: "DZD",
      value: "DZD",
    },
    {
      label: "ECO",
      value: "ECO",
    },
    {
      label: "EGP",
      value: "EGP",
    },
    {
      label: "ERN",
      value: "ERN",
    },
    {
      label: "ETB",
      value: "ETB",
    },
    {
      label: "EUR",
      value: "EUR",
    },
    {
      label: "FCFA",
      value: "FCFA",
    },
    {
      label: "FJD",
      value: "FJD",
    },
    {
      label: "FKP",
      value: "FKP",
    },
    {
      label: "GBP",
      value: "GBP",
    },
    {
      label: "GEL",
      value: "GEL",
    },
    {
      label: "GGP",
      value: "GGP",
    },
    {
      label: "GHS",
      value: "GHS",
    },
    {
      label: "GIP",
      value: "GIP",
    },
    {
      label: "GMD",
      value: "GMD",
    },
    {
      label: "GNF",
      value: "GNF",
    },
    {
      label: "GTQ",
      value: "GTQ",
    },
    {
      label: "GYD",
      value: "GYD",
    },
    {
      label: "HKD",
      value: "HKD",
    },
    {
      label: "HNL",
      value: "HNL",
    },
    {
      label: "HRK",
      value: "HRK",
    },
    {
      label: "HTG",
      value: "HTG",
    },
    {
      label: "HUF",
      value: "HUF",
    },
    {
      label: "IDR",
      value: "IDR",
    },
    {
      label: "ILS",
      value: "ILS",
    },
    {
      label: "IMP",
      value: "IMP",
    },
    {
      label: "INR",
      value: "INR",
    },
    {
      label: "IQD",
      value: "IQD",
    },
    {
      label: "IRR",
      value: "IRR",
    },
    {
      label: "ISK",
      value: "ISK",
    },
    {
      label: "JEP",
      value: "JEP",
    },
    {
      label: "JMD",
      value: "JMD",
    },
    {
      label: "JOD",
      value: "JOD",
    },
    {
      label: "JPY",
      value: "JPY",
    },
    {
      label: "KES",
      value: "KES",
    },
    {
      label: "KGS",
      value: "KGS",
    },
    {
      label: "KHR",
      value: "KHR",
    },
    {
      label: "KMF",
      value: "KMF",
    },
    {
      label: "KPW",
      value: "KPW",
    },
    {
      label: "KRW",
      value: "KRW",
    },
    {
      label: "KWD",
      value: "KWD",
    },
    {
      label: "KYD",
      value: "KYD",
    },
    {
      label: "KZT",
      value: "KZT",
    },
    {
      label: "LAK",
      value: "LAK",
    },
    {
      label: "LBP",
      value: "LBP",
    }, {
      label: "LKR",
      value: "LKR",
    },
    {
      label: "LRD",
      value: "LRD",
    },
    {
      label: "LSL",
      value: "LSL",
    },
    {
      label: "LYD",
      value: "LYD",
    },
    {
      label: "MAD",
      value: "MAD",
    },
    {
      label: "MDL",
      value: "MDL",
    },
    {
      label: "MGA",
      value: "MGA",
    },
    {
      label: "MKD",
      value: "MKD",
    },
    {
      label: "MMK",
      value: "MMK",
    },
    {
      label: "MNT",
      value: "MNT",
    },
    {
      label: "MOP",
      value: "MOP",
    },
    {
      label: "MRU",
      value: "MRU",
    },
    {
      label: "MVR",
      value: "MVR",
    },
    {
      label: "MWK",
      value: "MWK",
    },
    {
      label: "MXN",
      value: "MXN",
    },
    {
      label: "MYR",
      value: "MYR",
    },
    {
      label: "MZN",
      value: "MZN",
    },
    {
      label: "NAD",
      value: "NAD",
    },
    {
      label: "NGN",
      value: "NGN",
    },
    {
      label: "NIO",
      value: "NIO",
    },
    {
      label: "NOK",
      value: "NOK",
    },
    {
      label: "NPR",
      value: "NPR",
    },
    {
      label: "NZD",
      value: "NZD",
    },
    {
      label: "OMR",
      value: "OMR",
    }, {
      label: "PAB",
      value: "PAB",
    },
    {
      label: "PEN",
      value: "PEN",
    },
    {
      label: "PGK",
      value: "PGK",
    },
    {
      label: "PHP",
      value: "PHP",
    },
    {
      label: "PKR",
      value: "PKR",
    },
    {
      label: "PLN",
      value: "PLN",
    },
    {
      label: "PYG",
      value: "PYG",
    },
    {
      label: "QAR",
      value: "QAR",
    },
    {
      label: "RON",
      value: "RON",
    },
    {
      label: "RSD",
      value: "RSD",
    },
    {
      label: "RUB",
      value: "RUB",
    },
    {
      label: "RWF",
      value: "RWF",
    },
    {
      label: "SAR",
      value: "SAR",
    },
    {
      label: "SBD",
      value: "SBD",
    },
    {
      label: "SCR",
      value: "SCR",
    },
    {
      label: "SDG",
      value: "SDG",
    },
    {
      label: "SEK",
      value: "SEK",
    },
    {
      label: "SGD",
      value: "SGD",
    },
    {
      label: "SHP",
      value: "SHP",
    },
    {
      label: "SLL",
      value: "SLL",
    },
    {
      label: "SOS",
      value: "SOS",
    },
    {
      label: "SPL",
      value: "SPL",
    },

    {
      label: "SRD",
      value: "SRD",
    },
    {
      label: "STN",
      value: "STN",
    },
    {
      label: "SVC",
      value: "SVC",
    },
    {
      label: "SYP",
      value: "SYP",
    },
    {
      label: "SZL",
      value: "SZL",
    },
    {
      label: "THB",
      value: "THB",
    },
    {
      label: "TJS",
      value: "TJS",
    },
    {
      label: "TMT",
      value: "TMT",
    },
    {
      label: "TND",
      value: "TND",
    },
    {
      label: "TOP",
      value: "TOP",
    },
    {
      label: "TRY",
      value: "TRY",
    },
    {
      label: "TTD",
      value: "TTD",
    },
    {
      label: "TVD",
      value: "TVD",
    },
    {
      label: "TWD",
      value: "TWD",
    },
    {
      label: "TZS",
      value: "TZS",
    },
    {
      label: "UAH",
      value: "UAH",
    },
    {
      label: "UGX",
      value: "UGX",
    },
    {
      label: "USD",
      value: "USD",
    },
    {
      label: "UYU",
      value: "UYU",
    },
    {
      label: "UZS",
      value: "UZS",
    },
    {
      label: "VEF",
      value: "VEF",
    },
    {
      label: "VES",
      value: "VES",
    },
    {
      label: "VND",
      value: "VND",
    },
    {
      label: "VUV",
      value: "VUV",
    },

    {
      label: "WST",
      value: "WST",
    },
    {
      label: "XAF",
      value: "XAF",
    },

    {
      label: "XAG",
      value: "XAG",
    },
    {
      label: "XAU",
      value: "XAU",
    },
    {
      label: "XBT",
      value: "XBT",
    },
    {
      label: "XCD",
      value: "XCD",
    },
    {
      label: "XOF",
      value: "XOF",
    },
    {
      label: "ZAR",
      value: "ZAR",
    },
    {
      label: "ZMW",
      value: "ZMW",
    },
    {
      label: "ZWD",
      value: "ZWD",
    },

  ]

  const currencySymbol = [
    {
      label: "Default",
      value: "Default",
    },
    {
      label: "TK",
      value: "TK",
    },
    {
      label: "$",
      value: "$",
    },
    {
      label: "Bs",
      value: "Bs",
    },
    {
      label: "Br",
      value: "Br",
    },
    {
      label: "Nu.",
      value: "Nu.",
    },
    {
      label: "₡",
      value: "₡",
    },
    {
      label: "Kč",
      value: "Kč",
    },
    {
      label: "Fdj",
      value: "Fdj",
    },
    {
      label: "€",
      value: "€",
    },
    {
      label: "£",
      value: "£",
    },
    {
      label: "D",
      value: "D",
    },
    {
      label: "GH¢",
      value: "GH¢",
    },
    {
      label: "Rp",
      value: "Rp",
    },
    {
      label: "₪",
      value: "₪",
    },
    {
      label: "Rs",
      value: "Rs",
    },
    {
      label: "¥",
      value: "¥",
    },
    {
      label: "Ksh",
      value: "Ksh",
    },
    {
      label: "₮",
      value: "₮",
    },
    {
      label: "RM",
      value: "RM",
    },
    {
      label: "MTn",
      value: "MTn",
    },
    {
      label: "B/.",
      value: "B/.",
    },
    {
      label: "S/.",
      value: "S/.",
    },
    {
      label: "K",
      value: "K",
    },
    {
      label: "₱",
      value: "₱",
    },
    {
      label: "฿",
      value: "฿",
    },
    {
      label: "TSh",
      value: "TSh",
    },
    {
      label: "₴",
      value: "₴",
    },
    {
      label: "USh",
      value: "USh",
    },
    {
      label: "₫",
      value: "₫",
    },
    {
      label: "VT",
      value: "VT",
    },
    {
      label: "RF",
      value: "RF",
    },
    {
      label: "T$",
      value: "T$",
    },
    {
      label: "NT$",
      value: "NT$",
    },
    {
      label: "CHF",
      value: "CHF",
    },
    {
      label: "Kr",
      value: "Kr",
    },
    {
      label: "E",
      value: "E",
    },
    {
      label: "NT$",
      value: "NT$",
    },
    {
      label: "₩",
      value: "₩",
    },
    {
      label: "R",
      value: "R",
    },
    {
      label: "S",
      value: "S",
    },
    {
      label: "NAf",
      value: "NAf",
    },
    {
      label: "Le",
      value: "Le",
    },
    {
      label: "SR",
      value: "SR",
    },
    {
      label: "Db",
      value: "Db",
    },
    {
      label: "WS$",
      value: "WS$",
    },
    {
      label: "Lei",
      value: "Lei",
    },
    {
      label: "₦",
      value: "₦",
    },
    {
      label: "C$",
      value: "C$",
    },
    {
      label: "L",
      value: "L",
    },
    {
      label: "Ar",
      value: "Ar",
    },
    {
      label: "₭",
      value: "₭",
    },
    {
      label: "G",
      value: "G",
    },
    {
      label: "Q",
      value: "Q",
    },
    {
      label: "RD$",
      value: "RD$",
    },
    {
      label: "CF",
      value: "CF",
    },

    {
      label: "P",
      value: "P",
    },

    {
      label: "CFA",
      value: "CFA",
    },

    {
      label: "FCFA",
      value: "FCFA",
    },



  ]

  let selectedArr = selectedTab == "symbols" ? currencySymbol : currencyCode
  // console.log("invoiceDetailsStates.currency == ele.value", invoiceDetailsStates.currency == ele.value)
  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle6={createnotetoggle6} centered>
        <ModalHeader createnotetoggle6={createnotetoggle6}>
          Currency
        </ModalHeader>
        <ModalBody>
          <div className="radio_list">
            {
              selectedArr.map((ele) => {

                return (
                  <div class={`form-check ${invoiceDetailsStates.currency == ele.value ? "active" : ""}`}>
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={invoiceDetailsStates.currency == ele.value} />
                    <label class="form-check-label" for="flexRadioDefault1" onClick={(e) => handleClick(e, ele.value)}>
                      {ele.value}
                    </label>
                  </div>
                )
              })
            }

          </div>
          <div className="modal_footer">
            <button className="btn outline_btn" onClick={() => setSelectedTab(selectedTab == 'codes' ? "symbols" : "codes")}>{selectedTab == 'codes' ? "Symbols" : "Codes"}</button>
            <button className="btn cancel_btn" onClick={createnotetoggle6}>Cancel</button>
          </div>
        </ModalBody>
      </Modal>

    </div>
  );

}

export default Currency;
