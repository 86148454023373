import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { dateFilterAction } from "../redux/actions/dateFilterAction";
import { dateFilterStartEndAction } from "../redux/actions/dateFilterStartEndAction";
import { getDataAction } from "../redux/actions/dashboard/getDataAction";
import { data } from "autoprefixer";
import { date } from "yup";

const useDateFilters = ({
  setHideLoader,
  setShowLoader,
  activeTransactionFilter,
  setTransactionList,
}) => {
  // const {setHideLoader, activeTransactionFilter, setTransactionList} = props;

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [defaultFilter, setdefaultFilter] = useState("Daily");
  const [dateFilter, setDateFilter] = useState(moment().format("DD MMMM YYYY"));
  const { authUser } = useSelector((state) => state.login);
  const { monthStart } = useSelector((state) => state.setting);

  const [startYearState, setStartYearState] = useState(moment().year())
  const [endYearState, setEndYearState] = useState(moment().year())
  let calenderArr = ["Daily", "Weekly", "Bi-Weekly", "Monthly", "Bi-Monthly", "Quarterly", "Quadrimester", "Semi-Annual", "Annual"]

  useEffect(() => {
    if (monthStart) {

      let selectedVal = calenderArr[parseInt(monthStart) - 1]

      let startWeek = "";
      let endBiweek = "";
      let firstDay = "";
      let lastDay = "";
      let currentComponent = "";
  
      let path = window.location.pathname;
      currentComponent = path.split("/").pop();
  
      setStartYearState(moment().year())
      setEndYearState(moment().year())
  
      switch (currentComponent) {
        case "transaction":
          currentComponent = "transactions";
          break;
        case "charts":
          currentComponent = "chart";
          break;
      }
  
      let requestParams = {};

      switch (selectedVal) {
        case "Weekly":
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
          setdefaultFilter("Weekly");
          setDateFilter(
            moment().startOf("week").format("DD MMMM YYYY") +
            "-" +
            moment().endOf("week").format("DD MMMM YYYY")
          );
  
          if (currentComponent === "transactions") {
            requestParams = {
              type: 1,
              filter_date: moment().startOf("week").format("DD MMMM YYYY"),
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-weekly-" + currentComponent,
            };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-weekly-" + currentComponent
                : "get-weekly-sucategory" + currentComponent;
            requestParams = {
              type: 1,
              date: moment().startOf("week").format("DD MMMM YYYY"),
              user_id: authUser.data.id,
  
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              type: 1,
              filter_date: moment().startOf("week").format("DD MMMM YYYY"),
              user_id: authUser.data.id,
              url: "get-weekly-" + currentComponent,
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              type: 1,
              filter_date: moment().startOf("week").format("DD MMMM YYYY"),
              user_id: authUser.data.id,
              filter: "",
              url: "get-weekly-" + currentComponent,
              dispatchAction: "dateFilterAction",
            },
          });
  
          dispatch(dateFilterAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
        case "Bi-Weekly":
          setdefaultFilter("Bi-Weekly");
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          startWeek = moment().startOf("week").format("DD MMMM YYYY");
          endBiweek = moment([
            moment(startWeek).year(),
            moment(startWeek).month(),
            moment(startWeek).date(),
          ])
            .add(13, "days")
            .toDate();
  
          setDateFilter(
            startWeek + "-" + moment(endBiweek).format("DD MMMM YYYY")
          );
  
          if (currentComponent === "transactions") {
            requestParams = {
              type: 2,
              filter_date: startWeek,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-weekly-" + currentComponent,
            };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-weekly-" + currentComponent
                : "get-weekly-sucategory" + currentComponent;
  
            requestParams = {
              type: 2,
              date: startWeek,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              type: 2,
              filter_date: startWeek,
              user_id: authUser.data.id,
              url: "get-weekly-" + currentComponent,
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              type: 2,
              filter_date: startWeek,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-weekly-" + currentComponent,
              dispatchAction: "dateFilterAction",
            },
          });
  
          dispatch(dateFilterAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
        case "Monthly":
          setdefaultFilter("Monthly");
  
          setDateFilter(moment().month(moment().month()).format("MMMM"));
  
          firstDay = moment().format("YYYY-MM-01");
          lastDay = moment().format(" YYYY-MM-") + moment().daysInMonth();
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          if (currentComponent === "transactions") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            };
            // } else if (currentComponent === "chart") {
            //   console.log("Start_date Monthly.................", firstDay);
            //   requestParams = {
            //     start_date: firstDay,
            //     end_date: lastDay,
            //     user_id: authUser.data.id,
            //     filter: activeTransactionFilter ?? "",
            //     url: "get-" + currentComponent + "-data",
            //     dispatchAction: "dateFilterStartEndAction",
            //   };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-" + currentComponent + "-data"
                : "get-" + currentComponent + "-sucategorydata";
  
            requestParams = {
              type: 2,
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              url: "get-" + currentComponent + "s",
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            },
          });
  
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (
              currentComponent === "chart" ||
              currentComponent === "expense"
            ) {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
  
        case "Bi-Monthly":
          setdefaultFilter("Bi-Monthly");
  
          setDateFilter(
            moment().format("MMMM") +
            "-" +
            moment().add(1, "months").format("MMMM")
          );
  
          firstDay = moment().format("YYYY-MM-01");
          lastDay =
            moment(moment().add(1, "months")).format(" YYYY-MM-") +
            moment(moment().add(1, "months")).daysInMonth();
  
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          if (currentComponent === "transactions") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            };
            // } else if (currentComponent === "chart") {
            //   console.log("Start_date Monthly.................", firstDay);
            //   requestParams = {
            //     start_date: firstDay,
            //     end_date: lastDay,
            //     user_id: authUser.data.id,
            //     filter: activeTransactionFilter ?? "",
            //     url: "get-" + currentComponent + "-data",
            //     dispatchAction: "dateFilterStartEndAction",
            //   };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-" + currentComponent + "-data"
                : "get-" + currentComponent + "-sucategorydata";
  
            requestParams = {
              type: 2,
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              url: "get-" + currentComponent + "s",
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            },
          });
  
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (
              currentComponent === "chart" ||
              currentComponent === "expense"
            ) {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
  
        case "Quarterly":
          setdefaultFilter("Quarterly");
  
          setDateFilter(moment().startOf("quarter").format("MMMM") + '-' + moment().endOf("quarter").format("MMMM"))
  
          firstDay = moment().startOf("quarter").format("YYYY-MM-DD");
          lastDay = moment().endOf("quarter").format('YYYY-MM-DD')
  
          // setDateFilter(
          //   moment().format("MMMM") +
          //   "-" +
          //   moment().add(2, "months").format("MMMM")
          // );
  
          // firstDay = moment().format("YYYY-MM-01");
          // lastDay =
          //   moment(moment().add(2, "months")).format(" YYYY-MM-") +
          //   moment(moment().add(2, "months")).daysInMonth();
  
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          if (currentComponent === "transactions") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            };
            // } else if (currentComponent === "chart") {
            //   console.log("Start_date Monthly.................", firstDay);
            //   requestParams = {
            //     start_date: firstDay,
            //     end_date: lastDay,
            //     user_id: authUser.data.id,
            //     filter: activeTransactionFilter ?? "",
            //     url: "get-" + currentComponent + "-data",
            //     dispatchAction: "dateFilterStartEndAction",
            //   };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-" + currentComponent + "-data"
                : "get-" + currentComponent + "-sucategorydata";
  
            requestParams = {
              type: 2,
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              url: "get-" + currentComponent + "s",
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            },
          });
  
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (
              currentComponent === "chart" ||
              currentComponent === "expense"
            ) {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
  
  
        case "Quadrimester":
          setdefaultFilter("Quadrimester");
  
          let currMonthQuad = moment().format('MM');
          let currYearQuad = moment().format('YYYY');
  
  
          let date_filterQuad
          if (currMonthQuad >= 1 && currMonthQuad <= 4) {
            date_filterQuad = 'January-April'
          }
          else if (currMonthQuad >= 5 && currMonthQuad <= 8) {
            date_filterQuad = 'May-August'
          }
          else if (currMonthQuad >= 9 && currMonthQuad <= 12) {
            date_filterQuad = 'September-December'
          }
  
          let start_monthQuad = date_filterQuad.split('-')[0];
          let last_monthQuad = date_filterQuad.split('-')[1];
          let start_month_indexQuad = monthsArr.indexOf(start_monthQuad) + 1;
          let last_month_indexQuad = monthsArr.indexOf(last_monthQuad) + 1;
  
  
          let firstDayStateQuad = `${currYearQuad}-${start_month_indexQuad < 10 ? `0${start_month_indexQuad}` : start_month_indexQuad}-01`;
  
          let lastDayStateQuad = `${currYearQuad}-${last_month_indexQuad < 10 ? `0${last_month_indexQuad}` : last_month_indexQuad}-${daysInMonth(
            currYearQuad,
            last_month_indexQuad
          )}`;
  
          firstDay = firstDayStateQuad
          lastDay = lastDayStateQuad
  
          setDateFilter(date_filterQuad)
  
          // setDateFilter(
          //   moment().format("MMMM") +
          //   "-" +
          //   moment().add(2, "months").format("MMMM")
          // );
  
          // firstDay = moment().format("YYYY-MM-01");
          // lastDay =
          //   moment(moment().add(2, "months")).format(" YYYY-MM-") +
          //   moment(moment().add(2, "months")).daysInMonth();
  
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          if (currentComponent === "transactions") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            };
            // } else if (currentComponent === "chart") {
            //   console.log("Start_date Monthly.................", firstDay);
            //   requestParams = {
            //     start_date: firstDay,
            //     end_date: lastDay,
            //     user_id: authUser.data.id,
            //     filter: activeTransactionFilter ?? "",
            //     url: "get-" + currentComponent + "-data",
            //     dispatchAction: "dateFilterStartEndAction",
            //   };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-" + currentComponent + "-data"
                : "get-" + currentComponent + "-sucategorydata";
  
            requestParams = {
              type: 2,
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              url: "get-" + currentComponent + "s",
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            },
          });
  
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (
              currentComponent === "chart" ||
              currentComponent === "expense"
            ) {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
  
  
        case "Semi-Annual":
          setdefaultFilter("Semi-Annual");
          let currMonth = moment().format('MM');
          let currYear = moment().format('YYYY');
          let date_filter = currMonth > 6 ? 'July-December' : 'January-June';
  
          let start_month = date_filter.split('-')[0];
          let last_month = date_filter.split('-')[1];
  
          let start_month_index = monthsArr.indexOf(start_month) + 1;
          let last_month_index = monthsArr.indexOf(last_month) + 1;
  
          let firstDayState = `${currYear}-${start_month_index}-01`;
  
          let lastDayState = `${currYear}-${last_month_index}-${daysInMonth(
            currYear,
            last_month_index
          )}`;
  
  
          setDateFilter(date_filter)
  
          firstDay = firstDayState
          lastDay = lastDayState
  
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          if (currentComponent === "transactions") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            };
            // } else if (currentComponent === "chart") {
            //   console.log("Start_date Monthly.................", firstDay);
            //   requestParams = {
            //     start_date: firstDay,
            //     end_date: lastDay,
            //     user_id: authUser.data.id,
            //     filter: activeTransactionFilter ?? "",
            //     url: "get-" + currentComponent + "-data",
            //     dispatchAction: "dateFilterStartEndAction",
            //   };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-" + currentComponent + "-data"
                : "get-" + currentComponent + "-sucategorydata";
  
            requestParams = {
              type: 2,
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: activeTab,
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              url: "get-" + currentComponent + "s",
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              start_date: firstDay,
              end_date: lastDay,
              user_id: authUser.data.id,
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            },
          });
  
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (
              currentComponent === "chart" ||
              currentComponent === "expense"
            ) {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
  
  
        case "Annual":
          setdefaultFilter("Annual");
          setDateFilter(moment().format("YYYY"));
          // for other components
          // for expenditure
  
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          if (currentComponent === "transactions") {
            requestParams = {
              user_id: authUser.data.id,
              start_date: moment().format("YYYY-01-01"),
              end_date: moment().format("YYYY-12-31"),
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            };
          } else if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-" + currentComponent + "-data"
                : "get-" + currentComponent + "-sucategorydata";
            requestParams = {
              user_id: authUser.data.id,
              start_date: moment().format("YYYY-01-01"),
              end_date: moment().format("YYYY-12-31"),
              filter: activeTransactionFilter ?? "",
              url: activeTab,
              dispatchAction: "dateFilterStartEndAction",
            };
          } else if (currentComponent === "expense") {
            requestParams = {
              user_id: authUser.data.id,
              start_date: moment().format("YYYY-01-01"),
              end_date: moment().format("YYYY-12-31"),
              url: "get-" + currentComponent + "s",
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              user_id: authUser.data.id,
              start_date: moment().format("YYYY-01-01"),
              end_date: moment().format("YYYY-12-31"),
              filter: activeTransactionFilter ?? "",
              url: "get-" + currentComponent,
              dispatchAction: "dateFilterStartEndAction",
            },
          });
  
          dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
            if (currentComponent === "transactions") {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (
              currentComponent === "chart" ||
              currentComponent === "expense"
            ) {
              setTransactionList(response.payload.data);
            }
  
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
  
        default:
          setdefaultFilter("Daily");
          setDateFilter(moment().format("DD MMMM YYYY"));
          // currentComponent === "expense"
          //   ? setHideLoader(true)
          //   : setShowLoader(true);
  
          requestParams = {
            user_id: authUser.data.id,
            filter_date: moment().format("YYYY-MM-DD"),
            filter: activeTransactionFilter ?? "",
            url: "get-daily-" + currentComponent,
            dispatchAction: "dailyAction",
          };
  
          if (currentComponent === "chart") {
            let activeTab =
              localStorage.getItem("activeChartTab") === "category"
                ? "get-daily-" + currentComponent
                : "get-daily-sucategory" + currentComponent;
  
            //get-daily-sucategorychart
            requestParams = {
              user_id: authUser.data.id,
              date: moment().format("YYYY-MM-DD"),
              url: activeTab,
            };
          }
  
          dispatch({
            type: "HIT_FILTERS_API",
            requestParams: {
              user_id: authUser.data.id,
              filter_date: moment().format("YYYY-MM-DD"),
              filter: activeTransactionFilter ?? "",
              url: "get-daily-" + currentComponent,
              dispatchAction: "dailyAction",
            },
          });
  
          dispatch(getDataAction(requestParams)).then((response) => {
            if (
              currentComponent === "transactions" &&
              response.type !== "DASHBOARD_DATA_FAIL"
            ) {
              response.payload.data.length > 0
                ? setTransactionList(response.payload.data)
                : setTransactionList(null);
            } else if (response.type !== "DASHBOARD_DATA_FAIL") {
              setTransactionList(response.payload.data);
            }
            // currentComponent === "expense"
            //   ? setHideLoader(false)
            //   : setShowLoader(false);
          });
  
          break;
      }
    }
  }, [monthStart])

  let daysInMonth = function (y, m) {
    return new Date(y, m, 0).getDate();
  }

  let monthsArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];



  const handleModal = (e) => {
    setIsOpen(!isOpen);
  };

  const handleDefaultFilter = (e) => {
    setIsOpen(!isOpen);

    let startWeek = "";
    let endBiweek = "";
    let firstDay = "";
    let lastDay = "";
    let currentComponent = "";

    let path = window.location.pathname;
    currentComponent = path.split("/").pop();

    setStartYearState(moment().year())
    setEndYearState(moment().year())

    switch (currentComponent) {
      case "transaction":
        currentComponent = "transactions";
        break;
      case "charts":
        currentComponent = "chart";
        break;
    }

    let requestParams = {};
    switch (e.target.value) {
      case "Weekly":
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);
        setdefaultFilter("Weekly");
        setDateFilter(
          moment().startOf("week").format("DD MMMM YYYY") +
          "-" +
          moment().endOf("week").format("DD MMMM YYYY")
        );

        if (currentComponent === "transactions") {
          requestParams = {
            type: 1,
            filter_date: moment().startOf("week").format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
          };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-weekly-" + currentComponent
              : "get-weekly-sucategory" + currentComponent;
          requestParams = {
            type: 1,
            date: moment().startOf("week").format("DD MMMM YYYY"),
            user_id: authUser.data.id,

            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            type: 1,
            filter_date: moment().startOf("week").format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            url: "get-weekly-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            type: 1,
            filter_date: moment().startOf("week").format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: "",
            url: "get-weekly-" + currentComponent,
            dispatchAction: "dateFilterAction",
          },
        });

        dispatch(dateFilterAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Bi-Weekly":
        setdefaultFilter("Bi-Weekly");
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        startWeek = moment().startOf("week").format("DD MMMM YYYY");
        endBiweek = moment([
          moment(startWeek).year(),
          moment(startWeek).month(),
          moment(startWeek).date(),
        ])
          .add(13, "days")
          .toDate();

        setDateFilter(
          startWeek + "-" + moment(endBiweek).format("DD MMMM YYYY")
        );

        if (currentComponent === "transactions") {
          requestParams = {
            type: 2,
            filter_date: startWeek,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
          };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-weekly-" + currentComponent
              : "get-weekly-sucategory" + currentComponent;

          requestParams = {
            type: 2,
            date: startWeek,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            type: 2,
            filter_date: startWeek,
            user_id: authUser.data.id,
            url: "get-weekly-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            type: 2,
            filter_date: startWeek,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
            dispatchAction: "dateFilterAction",
          },
        });

        dispatch(dateFilterAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Monthly":
        setdefaultFilter("Monthly");

        setDateFilter(moment().month(moment().month()).format("MMMM"));

        firstDay = moment().format("YYYY-MM-01");
        lastDay = moment().format(" YYYY-MM-") + moment().daysInMonth();
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;


      case "Bi-Monthly":
        setdefaultFilter("Bi-Monthly");

        setDateFilter(
          moment().format("MMMM") +
          "-" +
          moment().add(1, "months").format("MMMM")
        );

        firstDay = moment().format("YYYY-MM-01");
        lastDay =
          moment(moment().add(1, "months")).format(" YYYY-MM-") +
          moment(moment().add(1, "months")).daysInMonth();

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;


      case "Quarterly":
        setdefaultFilter("Quarterly");

        setDateFilter(moment().startOf("quarter").format("MMMM") + '-' + moment().endOf("quarter").format("MMMM"))

        firstDay = moment().startOf("quarter").format("YYYY-MM-DD");
        lastDay = moment().endOf("quarter").format('YYYY-MM-DD')

        // setDateFilter(
        //   moment().format("MMMM") +
        //   "-" +
        //   moment().add(2, "months").format("MMMM")
        // );

        // firstDay = moment().format("YYYY-MM-01");
        // lastDay =
        //   moment(moment().add(2, "months")).format(" YYYY-MM-") +
        //   moment(moment().add(2, "months")).daysInMonth();

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;



      case "Quadrimester":
        setdefaultFilter("Quadrimester");

        let currMonthQuad = moment().format('MM');
        let currYearQuad = moment().format('YYYY');


        let date_filterQuad
        if (currMonthQuad >= 1 && currMonthQuad <= 4) {
          date_filterQuad = 'January-April'
        }
        else if (currMonthQuad >= 5 && currMonthQuad <= 8) {
          date_filterQuad = 'May-August'
        }
        else if (currMonthQuad >= 9 && currMonthQuad <= 12) {
          date_filterQuad = 'September-December'
        }

        let start_monthQuad = date_filterQuad.split('-')[0];
        let last_monthQuad = date_filterQuad.split('-')[1];
        let start_month_indexQuad = monthsArr.indexOf(start_monthQuad) + 1;
        let last_month_indexQuad = monthsArr.indexOf(last_monthQuad) + 1;


        let firstDayStateQuad = `${currYearQuad}-${start_month_indexQuad < 10 ? `0${start_month_indexQuad}` : start_month_indexQuad}-01`;

        let lastDayStateQuad = `${currYearQuad}-${last_month_indexQuad < 10 ? `0${last_month_indexQuad}` : last_month_indexQuad}-${daysInMonth(
          currYearQuad,
          last_month_indexQuad
        )}`;

        firstDay = firstDayStateQuad
        lastDay = lastDayStateQuad

        setDateFilter(date_filterQuad)

        // setDateFilter(
        //   moment().format("MMMM") +
        //   "-" +
        //   moment().add(2, "months").format("MMMM")
        // );

        // firstDay = moment().format("YYYY-MM-01");
        // lastDay =
        //   moment(moment().add(2, "months")).format(" YYYY-MM-") +
        //   moment(moment().add(2, "months")).daysInMonth();

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;



      case "Semi-Annual":
        setdefaultFilter("Semi-Annual");
        let currMonth = moment().format('MM');
        let currYear = moment().format('YYYY');
        let date_filter = currMonth > 6 ? 'July-December' : 'January-June';

        let start_month = date_filter.split('-')[0];
        let last_month = date_filter.split('-')[1];

        let start_month_index = monthsArr.indexOf(start_month) + 1;
        let last_month_index = monthsArr.indexOf(last_month) + 1;

        let firstDayState = `${currYear}-${start_month_index}-01`;

        let lastDayState = `${currYear}-${last_month_index}-${daysInMonth(
          currYear,
          last_month_index
        )}`;


        setDateFilter(date_filter)

        firstDay = firstDayState
        lastDay = lastDayState

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;



      case "Annual":
        setdefaultFilter("Annual");
        setDateFilter(moment().format("YYYY"));
        // for other components
        // for expenditure

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            user_id: authUser.data.id,
            start_date: moment().format("YYYY-01-01"),
            end_date: moment().format("YYYY-12-31"),
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";
          requestParams = {
            user_id: authUser.data.id,
            start_date: moment().format("YYYY-01-01"),
            end_date: moment().format("YYYY-12-31"),
            filter: activeTransactionFilter ?? "",
            url: activeTab,
            dispatchAction: "dateFilterStartEndAction",
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            user_id: authUser.data.id,
            start_date: moment().format("YYYY-01-01"),
            end_date: moment().format("YYYY-12-31"),
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            user_id: authUser.data.id,
            start_date: moment().format("YYYY-01-01"),
            end_date: moment().format("YYYY-12-31"),
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      default:
        setdefaultFilter("Daily");
        setDateFilter(moment().format("DD MMMM YYYY"));
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        requestParams = {
          user_id: authUser.data.id,
          filter_date: moment().format("YYYY-MM-DD"),
          filter: activeTransactionFilter ?? "",
          url: "get-daily-" + currentComponent,
          dispatchAction: "dailyAction",
        };

        if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-daily-" + currentComponent
              : "get-daily-sucategory" + currentComponent;

          //get-daily-sucategorychart
          requestParams = {
            user_id: authUser.data.id,
            date: moment().format("YYYY-MM-DD"),
            url: activeTab,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            user_id: authUser.data.id,
            filter_date: moment().format("YYYY-MM-DD"),
            filter: activeTransactionFilter ?? "",
            url: "get-daily-" + currentComponent,
            dispatchAction: "dailyAction",
          },
        });

        dispatch(getDataAction(requestParams)).then((response) => {
          if (
            currentComponent === "transactions" &&
            response.type !== "DASHBOARD_DATA_FAIL"
          ) {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (response.type !== "DASHBOARD_DATA_FAIL") {
            setTransactionList(response.payload.data);
          }
          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;
    }
  };

  const handlePrevious = () => {
    let requestParams = {};
    setHideLoader(true);

    let currentComponent = "";

    let path = window.location.pathname;
    currentComponent = path.split("/").pop();

    switch (currentComponent) {
      case "transaction":
        currentComponent = "transactions";
        break;
      case "charts":
        currentComponent = "chart";
        break;
    }

    switch (defaultFilter) {
      case "Weekly":
        let weekDates = dateFilter.split("-");
        let startDate = weekDates[0].split(" ");
        let endDate = weekDates[1].split(" ");

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        let currentWeekStart = moment([
          startYearState,
          // parseInt(startDate[2]),
          moment(weekDates[0]).month(startDate[1]).format("M") - 1,
          parseInt(startDate[0]),
        ])
          .subtract(1, "weeks")
          .toDate();

        setStartYearState(
          moment([
            startYearState,
            moment(weekDates[0]).month(startDate[1]).format("M") - 1,
            parseInt(startDate[0]),
          ])
            .subtract(1, "weeks")
            .year()
        )


        let currentWeekEnd = moment([
          // parseInt(endDate[2]),
          endYearState,
          moment(weekDates[0]).month(endDate[1]).format("M") - 1,
          parseInt(endDate[0]),
        ])
          .subtract(1, "weeks")
          .toDate();

        setEndYearState(
          moment([
            endYearState,
            moment(weekDates[0]).month(endDate[1]).format("M") - 1,
            parseInt(endDate[0]),
          ])
            .subtract(1, "weeks")
            .year()
        )

        setDateFilter(
          moment(currentWeekStart).format("DD MMMM YYYY") +
          "-" +
          moment(currentWeekEnd).format("DD MMMM YYYY")
        );

        // alert(
        //   moment(currentWeekStart).format("DD MMMM YYYY") +
        //   "-------" +
        //   moment(currentWeekEnd).format("DD MMMM YYYY")
        // );


        if (currentComponent === "transactions") {
          requestParams = {
            type: 1,
            filter_date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
          };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-weekly-" + currentComponent
              : "get-weekly-sucategory" + currentComponent;

          let weekDates = dateFilter.split("-");
          let startDateMonthly = weekDates[0].split(" ");
          let endDateMonthly = weekDates[4].split(" ");
          let filterType = "chart";
          requestParams = {
            type: 1,
            date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            url: activeTab,
            start_date: startDateMonthly,
            end_date: endDateMonthly,
            filter_type: filterType,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            type: 1,
            filter_date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            url: "get-weekly-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            type: 1,
            filter_date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: "",
            url: "get-weekly-" + currentComponent,
            dispatchAction: "dateFilterAction",
          },
        });

        dispatch(dateFilterAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Bi-Weekly":
        let biWeekDates = dateFilter.split("-");
        let biStartDate = biWeekDates[0].split(" ");
        let biEndDate = biWeekDates[1].split(" ");
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        let biCurrentWeekStart = moment([
          startYearState,
          moment(biWeekDates[0]).month(biStartDate[1]).format("M") - 1,
          parseInt(biStartDate[0]),
        ])
          .subtract(2, "weeks")
          .toDate();

        setStartYearState(
          moment([
            startYearState,
            moment(biWeekDates[0]).month(biStartDate[1]).format("M") - 1,
            parseInt(biStartDate[0]),
          ])
            .subtract(2, "weeks")
            .year()
        )


        let biCurrentWeekEnd = moment([
          endYearState,
          moment(biWeekDates[0]).month(biEndDate[1]).format("M") - 1,
          parseInt(biEndDate[0]),
        ])
          .subtract(2, "weeks")
          .toDate();

        setEndYearState(
          moment([
            endYearState,
            moment(biWeekDates[0]).month(biEndDate[1]).format("M") - 1,
            parseInt(biEndDate[0]),
          ])
            .subtract(2, "weeks")
            .year()
        )

        setDateFilter(
          moment(biCurrentWeekStart).format("DD MMMM YYYY") +
          "-" +
          moment(biCurrentWeekEnd).format("DD MMMM YYYY")
        );

        if (currentComponent === "transactions") {
          requestParams = {
            type: 2,
            filter_date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
          };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-weekly-" + currentComponent
              : "get-weekly-sucategory" + currentComponent;

          requestParams = {
            type: 2,
            date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            type: 2,
            filter_date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            url: "get-weekly-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            type: 2,
            filter_date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
            dispatchAction: "dateFilterAction",
          },
        });

        dispatch(dateFilterAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else {
            setTransactionList(response.payload.data);
          }
          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Monthly":
        let selectedMonth = moment([
          startYearState,
          moment().month(dateFilter).format("M") - 1,
          1,
        ])
          .subtract(1, "months")
          .toDate();
        let startYear = moment([
          startYearState,
          moment().month(dateFilter).format("M") - 1,
          1,
        ])
          .subtract(1, "months")
          .year()

        setStartYearState(startYear)

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        setDateFilter(moment(selectedMonth).format("MMMM"));

        let firstDay = moment(moment(selectedMonth)).format("YYYY-MM-01");
        let lastDay =
          moment(moment(selectedMonth)).format("YYYY-MM-") +
          moment(moment(selectedMonth)).daysInMonth();

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent + "-data",
            dispatchAction: "dateFilterStartEndAction",
          };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: moment(selectedMonth).format("DD MMMM YYYY"),
            end_date: moment(selectedMonth).format("DD-MM-YY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;


      case "Bi-Monthly":
        let BM_firstMonth = dateFilter.split('-')[0]
        let BM_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthBM = moment([
          startYearState,
          moment().month(BM_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "months")
          .toDate();

        let startYear_BM = moment([
          startYearState,
          moment().month(BM_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "months")
          .year();

        setStartYearState(startYear_BM)

        let selectedLastMonthBM = moment([
          endYearState,
          moment().month(BM_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "months")
          .toDate();

        let endYear_BM = moment([
          endYearState,
          moment().month(BM_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "months")
          .year();

        setEndYearState(endYear_BM)

        setDateFilter(moment(selectedFirstMonthBM).format("MMMM") + '-' + moment(selectedLastMonthBM).format("MMMM"));

        let BM_firstDay = moment(moment(selectedFirstMonthBM)).format("YYYY-MM-01");
        let BM_lastDay =
          moment(moment(selectedLastMonthBM)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthBM)).daysInMonth();


        // alert(BM_firstDay + '----' + BM_lastDay)


        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Quarterly":

        let QRT_firstMonth = dateFilter.split('-')[0]
        let QRT_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthQRT = moment([
          startYearState,
          moment().month(QRT_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "quarter")
          .toDate();


        let startYear_QRT = moment([
          startYearState,
          moment().month(QRT_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "quarter")
          .year();

        setStartYearState(startYear_QRT)



        let selectedLastMonthQRT = moment([
          endYearState,
          moment().month(QRT_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "quarter")
          .toDate();


        let endYear_QRT = moment([
          endYearState,
          moment().month(QRT_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(1, "quarter")
          .year();

        setEndYearState(endYear_QRT)

        setDateFilter(moment(selectedFirstMonthQRT).format("MMMM") + '-' + moment(selectedLastMonthQRT).format("MMMM"));


        let QRT_firstDay = moment(moment(selectedFirstMonthQRT)).format("YYYY-MM-01");
        let QRT_lastDay =
          moment(moment(selectedLastMonthQRT)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthQRT)).daysInMonth();

        // alert(QRT_firstDay + '----' + QRT_lastDay);

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Quadrimester":

        let QRM_firstMonth = dateFilter.split('-')[0]
        let QRM_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthQRM = moment([
          startYearState,
          moment().month(QRM_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(4, 'months')
          .toDate();

        let startYear_QRM = moment([
          startYearState,
          moment().month(QRM_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(4, 'months')
          .year();

        setStartYearState(startYear_QRM)


        let selectedLastMonthQRM = moment([
          endYearState,
          moment().month(QRM_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(4, 'months')

          .toDate();

        let endYear_QRM = moment([
          endYearState,
          moment().month(QRM_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(4, 'months')

          .year();

        setEndYearState(endYear_QRM)


        setDateFilter(moment(selectedFirstMonthQRM).format("MMMM") + '-' + moment(selectedLastMonthQRM).format("MMMM"));


        let QRM_firstDay = moment(moment(selectedFirstMonthQRM)).format("YYYY-MM-01");
        let QRM_lastDay =
          moment(moment(selectedLastMonthQRM)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthQRM)).daysInMonth();


        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;


      case "Semi-Annual":

        let SA_firstMonth = dateFilter.split('-')[0]
        let SA_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthSA = moment([
          startYearState,
          moment().month(SA_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(6, 'months')
          .toDate();


        let startYear_SA = moment([
          startYearState,
          moment().month(SA_firstMonth).format("M") - 1,
          1,
        ])
          .subtract(6, 'months')
          .year();

        setStartYearState(startYear_SA)



        let selectedLastMonthSA = moment([
          endYearState,
          moment().month(SA_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(6, 'months')

          .toDate();

        let endYear_SA = moment([
          endYearState,
          moment().month(SA_lastMonth).format("M") - 1,
          1,
        ])
          .subtract(6, 'months')

          .year();

        setEndYearState(endYear_SA)


        setDateFilter(moment(selectedFirstMonthSA).format("MMMM") + '-' + moment(selectedLastMonthSA).format("MMMM"));


        let SA_firstDay = moment(moment(selectedFirstMonthSA)).format("YYYY-MM-01");
        let SA_lastDay =
          moment(moment(selectedLastMonthSA)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthSA)).daysInMonth();

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;



      case "Annual":
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        let selectedYear = moment([
          moment().year(dateFilter).format("YYYY"),
          moment().month(),
          1,
        ])
          .subtract(1, "years")
          .toDate();

        setDateFilter(moment(selectedYear).format("YYYY"));

        let first = moment(moment(selectedYear)).format("YYYY-01-01");
        let last = moment(moment(selectedYear)).format("YYYY-12-31");

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: first,
            end_date: last,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   requestParams = {
          //     start_date: first,
          //     end_date: last,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: moment(selectedYear).format("DD MMMM YYYY"),
            end_date: moment(selectedYear).format("DD-MM-YY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: first,
            end_date: last,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: first,
            end_date: last,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "expense" ||
            currentComponent === "chart"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      default:
        let currentDate = moment([
          moment(dateFilter).year(),
          moment(dateFilter).month(),
          moment(dateFilter).date(),
        ])
          .subtract(1, "days")
          .toDate();

        setDateFilter(moment(currentDate).format("DD MMMM YYYY"));
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            user_id: authUser.data.id,
            filter_date: moment(currentDate).format("YYYY-MM-DD"),
            filter: activeTransactionFilter ?? "",
            url: "get-daily-" + currentComponent,
            dispatchAction: "dailyAction",
          };
        } else if (currentComponent === "chart") {
          requestParams = {
            user_id: authUser.data.id,
            date: moment(currentDate).format("YYYY-MM-DD"),
            url:
              localStorage.getItem("activeChartTab") === "category"
                ? "get-daily-" + currentComponent
                : "get-daily-sucategory" + currentComponent,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            user_id: authUser.data.id,
            filter_date: moment(currentDate).format("YYYY-MM-DD"),
            url: "get-daily-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            user_id: authUser.data.id,
            filter_date: moment(currentDate).format("YYYY-MM-DD"),
            filter: activeTransactionFilter ?? "",
            url: "get-daily-" + currentComponent,
            dispatchAction: "dailyAction",
          },
        });

        dispatch(getDataAction(requestParams)).then((response) => {
          if (
            currentComponent === "transactions" &&
            response.type !== "DASHBOARD_DATA_FAIL"
          ) {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            if (response.type !== "DASHBOARD_DATA_FAIL") {
              setTransactionList(response.payload.data);
            }
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });
        break;
    }
  };

  const handleNext = () => {
    let requestParams = {};
    setHideLoader(true);

    let currentComponent = "";

    let path = window.location.pathname;
    currentComponent = path.split("/").pop();
    // console.log("currentComponent----", currentComponent);

    switch (currentComponent) {
      case "transaction":
        currentComponent = "transactions";
        break;
      case "charts":
        currentComponent = "chart";
        break;
    }

    switch (defaultFilter) {
      case "Weekly":
        let weekDates = dateFilter.split("-");
        let startDate = weekDates[0].split(" ");
        let endDate = weekDates[1].split(" ");
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        let currentWeekStart = moment([
          startYearState,
          // parseInt(startDate[2]),
          moment(weekDates[0]).month(startDate[1]).format("M") - 1,
          parseInt(startDate[0]),
        ])
          .add(1, "weeks")
          .toDate();

        setStartYearState(
          moment([
            startYearState,
            moment(weekDates[0]).month(startDate[1]).format("M") - 1,
            parseInt(startDate[0]),
          ])
            .add(1, "weeks")
            .year()
        )


        let currentWeekEnd = moment([
          // parseInt(endDate[2]),
          endYearState,
          moment(weekDates[0]).month(endDate[1]).format("M") - 1,
          parseInt(endDate[0]),
        ])
          .add(1, "weeks")
          .toDate();

        setEndYearState(
          moment([
            endYearState,
            moment(weekDates[0]).month(endDate[1]).format("M") - 1,
            parseInt(endDate[0]),
          ])
            .add(1, "weeks")
            .year()
        )

        setDateFilter(
          moment(currentWeekStart).format("DD MMMM YYYY") +
          "-" +
          moment(currentWeekEnd).format("DD MMMM YYYY")
        );

        if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-weekly-" + currentComponent
              : "get-weekly-sucategory" + currentComponent;

          requestParams = {
            type: 1,
            date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            url: activeTab,
          };
        } else {
          requestParams = {
            type: 1,
            filter_date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            type: 1,
            filter_date: moment(currentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: "",
            url: "get-weekly-" + currentComponent,
            dispatchAction: "dateFilterAction",
          },
        });

        dispatch(dateFilterAction(requestParams)).then((response) => {
          response.payload.data.length > 0
            ? setTransactionList(response.payload.data)
            : setTransactionList(null);
          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Bi-Weekly":
        let biWeekDates = dateFilter.split("-");
        let biStartDate = biWeekDates[0].split(" ");
        let biEndDate = biWeekDates[1].split(" ");
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        let biCurrentWeekStart = moment([
          startYearState,
          moment(biWeekDates[0]).month(biStartDate[1]).format("M") - 1,
          parseInt(biStartDate[0]),
        ])
          .add(2, "weeks")
          .toDate();

        setStartYearState(
          moment([
            startYearState,
            moment(biWeekDates[0]).month(biStartDate[1]).format("M") - 1,
            parseInt(biStartDate[0]),
          ])
            .add(2, "weeks")
            .year()
        )


        let biCurrentWeekEnd = moment([
          endYearState,
          moment(biWeekDates[0]).month(biEndDate[1]).format("M") - 1,
          parseInt(biEndDate[0]),
        ])
          .add(2, "weeks")
          .toDate();

        setEndYearState(
          moment([
            endYearState,
            moment(biWeekDates[0]).month(biEndDate[1]).format("M") - 1,
            parseInt(biEndDate[0]),
          ])
            .add(2, "weeks")
            .year()
        )


        setDateFilter(
          moment(biCurrentWeekStart).format("DD MMMM YYYY") +
          "-" +
          moment(biCurrentWeekEnd).format("DD MMMM YYYY")
        );

        if (currentComponent === "transactions") {
          requestParams = {
            type: 2,
            filter_date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
          };
        } // } else if (currentComponent === "chart") {
        //   requestParams = {
        //     type: 2,
        //     date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
        //     user_id: authUser.data.id,
        //     filter: activeTransactionFilter ?? "",
        //     url: "get-weekly-" + currentComponent,
        //   };
        else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-weekly-" + currentComponent
              : "get-weekly-sucategory" + currentComponent;

          requestParams = {
            type: 2,
            date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            type: 2,
            filter_date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            url: "get-weekly-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            type: 2,
            filter_date: moment(biCurrentWeekStart).format("DD MMMM YYYY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-weekly-" + currentComponent,
            dispatchAction: "dateFilterAction",
          },
        });

        dispatch(dateFilterAction(requestParams)).then((response) => {
          response.payload.data.length > 0
            ? setTransactionList(response.payload.data)
            : setTransactionList(null);
          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Monthly":
        let selectedMonth = moment([
          startYearState,
          moment().month(dateFilter).format("M") - 1,
          1,
        ])
          .add(1, "months")
          .toDate();

        let startYear = moment([
          startYearState,
          moment().month(dateFilter).format("M") - 1,
          1,
        ])
          .add(1, "months")
          .year()

        setStartYearState(startYear)


        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        setDateFilter(moment(selectedMonth).format("MMMM"));

        let firstDay = moment(moment(selectedMonth)).format("YYYY-MM-01");

        let lastDay =
          moment(moment(selectedMonth)).format("YYYY-MM-") +
          moment(moment(selectedMonth)).daysInMonth();

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //     filter: activeTransactionFilter ?? "",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: moment(selectedMonth).format("DD MMMM YYYY"),
            end_date: moment(selectedMonth).format("DD-MM-YY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: firstDay,
            end_date: lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Bi-Monthly":
        let BM_firstMonth = dateFilter.split('-')[0]
        let BM_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthBM = moment([
          moment().year(),
          moment().month(BM_firstMonth).format("M") - 1,
          1,
        ])
          .add(1, "months")
          .toDate();


        let selectedLastMonthBM = moment([
          moment().year(),
          moment().month(BM_lastMonth).format("M") - 1,
          1,
        ])
          .add(1, "months")
          .toDate();


        setDateFilter(moment(selectedFirstMonthBM).format("MMMM") + '-' + moment(selectedLastMonthBM).format("MMMM"));


        let BM_firstDay = moment(moment(selectedFirstMonthBM)).format("YYYY-MM-01");
        let BM_lastDay =
          moment(moment(selectedLastMonthBM)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthBM)).daysInMonth();


        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: BM_firstDay,
            end_date: BM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Quarterly":

        let QRT_firstMonth = dateFilter.split('-')[0]
        let QRT_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthQRT = moment([
          startYearState,
          moment().month(QRT_firstMonth).format("M") - 1,
          1,
        ])
          .add(1, "quarter")
          .toDate();


        let startYear_QRT = moment([
          startYearState,
          moment().month(QRT_firstMonth).format("M") - 1,
          1,
        ])
          .add(1, "quarter")
          .year();

        setStartYearState(startYear_QRT)



        let selectedLastMonthQRT = moment([
          endYearState,
          moment().month(QRT_lastMonth).format("M") - 1,
          1,
        ])
          .add(1, "quarter")
          .toDate();


        let endYear_QRT = moment([
          endYearState,
          moment().month(QRT_lastMonth).format("M") - 1,
          1,
        ])
          .add(1, "quarter")
          .year();

        setEndYearState(endYear_QRT)

        setDateFilter(moment(selectedFirstMonthQRT).format("MMMM") + '-' + moment(selectedLastMonthQRT).format("MMMM"));


        let QRT_firstDay = moment(moment(selectedFirstMonthQRT)).format("YYYY-MM-01");
        let QRT_lastDay =
          moment(moment(selectedLastMonthQRT)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthQRT)).daysInMonth();

        // alert(QRT_firstDay + '----' + QRT_lastDay);

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: QRT_firstDay,
            end_date: QRT_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      case "Quadrimester":

        let QRM_firstMonth = dateFilter.split('-')[0]
        let QRM_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthQRM = moment([
          startYearState,
          moment().month(QRM_firstMonth).format("M") - 1,
          1,
        ])
          .add(4, 'months')
          .toDate();

        let startYear_QRM = moment([
          startYearState,
          moment().month(QRM_firstMonth).format("M") - 1,
          1,
        ])
          .add(4, 'months')
          .year();

        setStartYearState(startYear_QRM)


        let selectedLastMonthQRM = moment([
          endYearState,
          moment().month(QRM_lastMonth).format("M") - 1,
          1,
        ])
          .add(4, 'months')

          .toDate();

        let endYear_QRM = moment([
          endYearState,
          moment().month(QRM_lastMonth).format("M") - 1,
          1,
        ])
          .add(4, 'months')

          .year();

        setEndYearState(endYear_QRM)


        setDateFilter(moment(selectedFirstMonthQRM).format("MMMM") + '-' + moment(selectedLastMonthQRM).format("MMMM"));


        let QRM_firstDay = moment(moment(selectedFirstMonthQRM)).format("YYYY-MM-01");
        let QRM_lastDay =
          moment(moment(selectedLastMonthQRM)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthQRM)).daysInMonth();


        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: QRM_firstDay,
            end_date: QRM_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;


      case "Semi-Annual":

        let SA_firstMonth = dateFilter.split('-')[0]
        let SA_lastMonth = dateFilter.split('-')[1];

        let selectedFirstMonthSA = moment([
          startYearState,
          moment().month(SA_firstMonth).format("M") - 1,
          1,
        ])
          .add(6, 'months')
          .toDate();


        let startYear_SA = moment([
          startYearState,
          moment().month(SA_firstMonth).format("M") - 1,
          1,
        ])
          .add(6, 'months')
          .year();

        setStartYearState(startYear_SA)



        let selectedLastMonthSA = moment([
          endYearState,
          moment().month(SA_lastMonth).format("M") - 1,
          1,
        ])
          .add(6, 'months')

          .toDate();

        let endYear_SA = moment([
          endYearState,
          moment().month(SA_lastMonth).format("M") - 1,
          1,
        ])
          .add(6, 'months')

          .year();

        setEndYearState(endYear_SA)


        setDateFilter(moment(selectedFirstMonthSA).format("MMMM") + '-' + moment(selectedLastMonthSA).format("MMMM"));


        let SA_firstDay = moment(moment(selectedFirstMonthSA)).format("YYYY-MM-01");
        let SA_lastDay =
          moment(moment(selectedLastMonthSA)).format("YYYY-MM-") +
          moment(moment(selectedLastMonthSA)).daysInMonth();

        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   console.log("Start_date Monthly.................", firstDay);
          //   requestParams = {
          //     start_date: firstDay,
          //     end_date: lastDay,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: SA_firstDay,
            end_date: SA_lastDay,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;



      case "Annual":
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        let selectedYear = moment([
          moment().year(dateFilter).format("YYYY"),
          moment().month(),
          1,
        ])
          .add(1, "years")
          .toDate();

        setDateFilter(moment(selectedYear).format("YYYY"));

        let first = moment(moment(selectedYear)).format("YYYY-01-01");
        let last = moment(moment(selectedYear)).format("YYYY-12-31");

        if (currentComponent === "transactions") {
          requestParams = {
            start_date: first,
            end_date: last,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          };
          // } else if (currentComponent === "chart") {
          //   requestParams = {
          //     start_date: first,
          //     end_date: last,
          //     user_id: authUser.data.id,
          //     filter: activeTransactionFilter ?? "",
          //     url: "get-" + currentComponent + "-data",
          //     dispatchAction: "dateFilterStartEndAction",
          //   };
        } else if (currentComponent === "chart") {
          let activeTab =
            localStorage.getItem("activeChartTab") === "category"
              ? "get-" + currentComponent + "-data"
              : "get-" + currentComponent + "-sucategorydata";

          requestParams = {
            type: 2,
            start_date: moment(selectedYear).format("DD MMMM YYYY"),
            end_date: moment(selectedYear).format("DD-MM-YY"),
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: activeTab,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            start_date: first,
            end_date: last,
            user_id: authUser.data.id,
            url: "get-" + currentComponent + "s",
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            start_date: first,
            end_date: last,
            user_id: authUser.data.id,
            filter: activeTransactionFilter ?? "",
            url: "get-" + currentComponent,
            dispatchAction: "dateFilterStartEndAction",
          },
        });

        dispatch(dateFilterStartEndAction(requestParams)).then((response) => {
          if (currentComponent === "transactions") {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            setTransactionList(response.payload.data);
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });

        break;

      default:
        let currentDate = moment([
          moment(dateFilter).year(),
          moment(dateFilter).month(),
          moment(dateFilter).date(),
        ])
          .add(1, "days")
          .toDate();

        setDateFilter(moment(currentDate).format("DD MMMM YYYY"));
        currentComponent === "expense"
          ? setHideLoader(true)
          : setShowLoader(true);

        if (currentComponent === "transactions") {
          requestParams = {
            user_id: authUser.data.id,
            filter_date: moment(currentDate).format("YYYY-MM-DD"),
            filter: activeTransactionFilter ?? "",
            url: "get-daily-" + currentComponent,
            dispatchAction: "dailyAction",
          };
        } else if (currentComponent === "chart") {
          requestParams = {
            user_id: authUser.data.id,
            date: moment(currentDate).format("YYYY-MM-DD"),
            url:
              localStorage.getItem("activeChartTab") === "category"
                ? "get-daily-" + currentComponent
                : "get-daily-sucategory" + currentComponent,
          };
        } else if (currentComponent === "expense") {
          requestParams = {
            user_id: authUser.data.id,
            filter_date: moment(currentDate).format("YYYY-MM-DD"),
            url: "get-daily-" + currentComponent,
          };
        }

        dispatch({
          type: "HIT_FILTERS_API",
          requestParams: {
            user_id: authUser.data.id,
            filter_date: moment(currentDate).format("YYYY-MM-DD"),
            filter: activeTransactionFilter ?? "",
            url: "get-daily-" + currentComponent,
            dispatchAction: "dailyAction",
          },
        });

        dispatch(getDataAction(requestParams)).then((response) => {
          if (
            currentComponent === "transactions" &&
            response.type !== "DASHBOARD_DATA_FAIL"
          ) {
            response.payload.data.length > 0
              ? setTransactionList(response.payload.data)
              : setTransactionList(null);
          } else if (
            currentComponent === "chart" ||
            currentComponent === "expense"
          ) {
            if (response.type !== "DASHBOARD_DATA_FAIL") {
              setTransactionList(response.payload.data);
            }
          }

          currentComponent === "expense"
            ? setHideLoader(false)
            : setShowLoader(false);
        });
        break;
    }
  };

  return {
    handleModal,
    handleDefaultFilter,
    handlePrevious,
    handleNext,
    isOpen,
    defaultFilter,
    dateFilter,
    setdefaultFilter
  };
};

export default useDateFilters;
