import { fetchService } from ".";

export const getSubCategoryBudgetApi = async (values) => {
const data = await fetchService({
    method: "POST",
    url: "get-subcategorybudget",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data
}