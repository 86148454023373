import React, { useState } from "react";
import { FormGroup } from 'react-bootstrap';
import select from '../../images/select.svg';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { addNoteAction } from "../../redux/actions/addNoteAction";
import { Formik } from "formik";
import { addNoteSchema } from "../../utils/validations";

const Modalcreatenote = ({ createnotetoggle, isOpen, getNotesListing }) => {
  const dispatch = useDispatch()
  const { authUser } = useSelector((state) => state.login)
  const [noteColor, setNoteColor] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false)

  const toggleColor = (colorId) => {
    setNoteColor(colorId);
  }

  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle={createnotetoggle} centered>
        <ModalHeader createnotetoggle={createnotetoggle}>
          Create Note
        </ModalHeader>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: "",
            note: "",
            password: "",
            confirm_password: ""
          }}
          validateOnChange={true}
          validationSchema={addNoteSchema}
          onSubmit={(values, { resetForm }) => {
            if (values) {
              
              setFormSubmitted(true)

              const requestParams = {
                'user_id': authUser.data.id,
                'title': values.title,
                'note': values.note,
                'color': noteColor,
                'password': values.password
              }
              dispatch(addNoteAction(requestParams)).then(response => {
                resetForm()
                createnotetoggle()

                if (response.type == "ADD_NOTE_FAIL") {
                  response.payload.message ? toast.error(response.payload.message) : toast.error(response.payload)
                }

                else {
                  toast.success(response.payload.message)
                  getNotesListing()
                }

                setFormSubmitted(false)

              })
            }
          }}
        >
          {({ errors, values, handleChange, handleSubmit }) => (
            <>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for="exampleEmail">Title</Label>
                    <Input name="title"
                      type="text"
                      value={values.title}
                      placeholder="Enter title"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <div className="error-message">
                      {errors.title ? errors.title : ''}
                    </div>
                  </FormGroup>
                  <div className="addcolor d-flex mt-3 mb-2">
                    <span>Add Color</span>
                    <div className='colours d-flex'>
                      <div className={noteColor === 0 ? "cols color1 active" : "cols color1"} onClick={() => toggleColor(0)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                      <div className={noteColor === 1 ? "cols color2 active" : "cols color2"} onClick={() => toggleColor(1)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                      <div className={noteColor === 2 ? "cols color3 active" : "cols color3"} onClick={() => toggleColor(2)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                      <div className={noteColor === 3 ? "cols color4 active" : "cols color4"} onClick={() => toggleColor(3)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                      <div className={noteColor === 4 ? "cols color5 active" : "cols color5"} onClick={() => toggleColor(4)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                      <div className={noteColor === 5 ? "cols color6 active" : "cols color6"} onClick={() => toggleColor(5)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                      <div className={noteColor === 6 ? "cols color7 active" : "cols color7"} onClick={() => toggleColor(6)}>
                        <div className="select">
                          <img src={select} alt="select" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormGroup>
                    <Label className="mt-2">Note</Label>
                    <Input
                      name="note"
                      type="textarea"
                      value={values.note}
                      placeholder="Enter note"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <div className="error-message">
                      {errors.note ? errors.note : ''}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="mt-2">Password (optional)</Label>
                    <Input
                      name="password"
                      type="password"
                      value={values.password}
                      placeholder="Enter numeric password"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <div className="error-message">
                      {errors.password ? errors.password : ''}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="mt-2">Confirm Password</Label>
                    <Input
                      name="confirm_password"
                      type="password"
                      value={values.confirm_password}
                      placeholder="Confirm password"
                      className="form-control"
                      onChange={handleChange}
                    />
                    <div className="error-message">
                      {errors.confirm_password ? errors.confirm_password : ''}
                    </div>
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button type="button" onClick={handleSubmit} color="primary" disabled={formSubmitted}>Save</Button>{' '}
                <Button color="secondary" onClick={createnotetoggle}>Cancel</Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );

}

export default Modalcreatenote;
