export const Loader = () => {
  return( 
  <div className="overlay-loader">
        <div className="loaderWrap">
        <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  </div>
    );
}