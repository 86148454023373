import { settingsApi } from "../../api/settingsApi";
import { types } from "../actionTypes";

export const settingsAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.SETTING_REQUEST,
    });
    try {
      const results = await settingsApi(values);
      if (results.data.status_code == 200) {
        return dispatch({
          type: types.SETTING_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.SETTING_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.SETTING_FAIL,
        payload: error,
      });
    }
  };
};

export const setFormData = (payload) => {
  return {
    type: types.SET_SETTING_FORMDATA,
    payload
  }
}

export const setCurrency = (payload) => {
  return {
    type: types.SET_SETTING_CURRENCY,
    payload
  }
}

export const setWeekStart = (payload) => {
  return {
    type: types.SET_SETTING_WEEK_START,
    payload
  }
}

export const setMonthStart = (payload) => {
  return {
    type: types.SET_SETTING_MONTH_START,
    payload
  }
}

