import React, { useEffect, useState } from "react";
import logo from '../../images/logo.png';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../loader";
import OTPInput from "react-otp-input";
import './otp.css'
import { verifyNoteOtpAction } from "../../redux/actions/addNoteAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";


const VerifyOtp = () => {
    const { authUser } = useSelector(state => state.login);
    const dispatch = useDispatch()
    const [hideLoader, setHideLoader] = useState(false)
    const { state } = useLocation()
    const [otp, setOtp] = useState('')
    const navigate = useNavigate()
    const handleOtpInputChange = (otp) => setOtp(otp)


    const verifyOtpHandler = () => {
        if (otp) {
            setHideLoader(true)
            let requestParams = {
                'user_id': authUser.data.id,
                "id": state.note_id,
                "otp": otp
            }

            dispatch(verifyNoteOtpAction(requestParams)).then(response => {

                if (response.payload.status_code == '200') {
                    toast.success(response.payload.message)
                    navigate('/resetNotePassword', {
                        state: state
                    })
                }
                else {
                    toast.error("Incorrect otp entered")
                    setOtp('')
                }

                setHideLoader(false)
            })
        }
        else {
            toast.error('Otp is required')
        }
    }

    return (
        <header className="App-header">
            {
                hideLoader && <Loader />
            }
            <div className="login">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <div className="outer-wrap">
                                <img src={logo} className="App-logo" alt="logo" />
                                <div className="login-form">
                                    <h4>Forgot Note Password Otp?</h4>
                                    <div className="otp">
                                        <OTPInput
                                            onChange={handleOtpInputChange}
                                            value={otp}
                                            inputStyle="inputStyle"
                                            numInputs={4}
                                            separator={<span></span>}
                                        />
                                    </div>
                                    <button className="login-btn btn btn-primary w-100 mt-3" onClick={verifyOtpHandler}>Verify Otp</button>
                                    <div style={{ float: "right", margin: "10px" }} onClick={() => navigate('/notes')}>
                                        <p>Back to notes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default VerifyOtp;