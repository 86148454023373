import React, { useState, useEffect } from "react";
import StripeCheckout from 'react-stripe-checkout';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { connect } from "react-redux";
import logosmall from "../images/logo1.png";
import { Loader } from "./loader";
import { toast } from 'react-toastify';
import { buySubscriptionAction } from "../redux/actions/loginAction";


const ExpireModal = () => {
    const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
    const dispatch = useDispatch();
    const { authUser } = useSelector(state => state.login);
    const [selectPlan, setSelectPlan] = useState('monthly');
    const [showLoader, setShowLoader] = useState(false);

    const [subscriptionExpired, setSubscriptionExpired] = useState(false);
    const checkSubscriptionStatus = () => {
        const isExpired = authUser.data.subscription_status == 1 ? true : false;
        setSubscriptionExpired(isExpired);
    };

    const handlePayment = (token) => {
        // show loader
        setShowLoader(true)

        let requestParams = {
            'user_id': authUser.data.id,
            'price': selectPlan == 'monthly' ? 500 : 5000,
            'card_token': token.id,
            'email': token.email,
            'plan_id': selectPlan == 'monthly' ? 1 : 2,
        }

        dispatch(buySubscriptionAction(requestParams)).then(response => {
            console.log(response);
            setSubscriptionExpired(false);

            if (response.payload.status_code == '200') {
                toast.success(response.payload.message)
            }
            else {
                let msg = response.payload.message ? response.payload.message : response.payload;
                toast.error(msg)
            }
            setShowLoader(false)
        })
    };

    const togglePlan = (plan) => {
        setSelectPlan(plan);
    };

    useEffect(() => {
        checkSubscriptionStatus();
        console.log(authUser);
    }, []);


    return (
        <>
            {
                showLoader && <Loader />
            }

            <Modal isOpen={subscriptionExpired} scrollable backdrop="static" className="subscription-modal">
                <ModalHeader className="border" >
                    Plan Expire
                </ModalHeader>
                <ModalBody className="expendituree" >
                    <p className="mb-2 text-center" style={{ fontWeight: "bold" }}>Your free trial has expired, please pick a yearly or monthly plan.</p>
                    <div className="plans-list d-flex bg-white m-0 p-0" style={{ boxShadow: "none" }}>
                        <div className={
                            selectPlan == 'monthly' ? "Plans-wrap bg-white mx-1 p-3 text-center active" : "Plans-wrap bg-white mx-1 p-3 text-center"
                        } onClick={() => togglePlan('monthly')}>
                            <p className="text-dark">MONTHLY</p>
                            <span>Starter plan for individuals</span>
                            {/* <div className="select p-1"><img src="/static/media/select.48a051b1278ade2805549225b79b978d.svg" alt="select" />
                        </div> */}
                            <h5 className="text-dark mt-3" style={{ fontWeight: "600" }}>$5 / month</h5>
                        </div>
                        <div className={
                            selectPlan == 'yearly' ? "Plans-wrap bg-white mx-1 p-3 text-center active" : "Plans-wrap bg-white mx-1 p-3 text-center"
                        } onClick={() => togglePlan('yearly')}>
                            <p className="text-dark">YEARLY</p>
                            <span>Big save for individuals</span>
                            {/* <div className="select p-1"><img src="/static/media/select.48a051b1278ade2805549225b79b978d.svg" alt="select" />
                        </div> */}
                            <h5 className="text-dark mt-3" style={{ fontWeight: "600" }}>$50 / year</h5>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="border">
                    <StripeCheckout
                        token={handlePayment}
                        stripeKey={STRIPE_KEY}
                        amount={selectPlan == 'monthly' ? 500 : 5000}
                        currency="CAD"
                        name={selectPlan == 'monthly' ? 'MONTHLY' : 'YEARLY'}
                        description={selectPlan == 'monthly' ? 'Starter plan for individuals' : 'Big save for individuals'}
                        image={logosmall}
                    >
                        <Button color="success" >
                            Buy a Plan
                        </Button>
                    </StripeCheckout>
                </ModalFooter>
            </Modal >
        </>
    );
};

const mapStateToProps = (state) => {
    return state.login.authUser
}
export default connect(mapStateToProps, null)(ExpireModal);