import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import { Formik } from "formik";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Shipinghandeling = ({ createnotetoggle7, isOpen }) => {
    const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
    const dispatch = useDispatch()
    const [shipamount, setAddress] = useState(invoiceDetailsStates.shipamount);
    const [updated, setUpdated] = useState(shipamount);
    const handleChange = (event) => {
        setAddress(event.target.value);
    };

    const handleClick = () => {
        dispatch(saveFormAction({ "shipamount": shipamount }))
        setUpdated(createnotetoggle7);
    }

    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle7={createnotetoggle7} centered>
                <ModalHeader createnotetoggle7={createnotetoggle7}>
                    Shipping & Handling
                </ModalHeader>
                <ModalBody className="py-4">
                    <div className="d-flex">
                        <Form className="form_text w-100">
                            <FormGroup>
                                <div className="d-flex">
                                    <Input
                                        name="shipamount"
                                        type="number"
                                        value={shipamount}
                                        placeholder="Enter your shipping and handling amount"
                                        className="me-2"
                                        onChange={handleChange}
                                    />
                                </div>
                            </FormGroup>
                        </Form>
                        <button className="btn outline_btn" type="submit" onClick={handleClick}>OK</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default Shipinghandeling;
