import React, { useEffect, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Header from "../components/partials/header";
import Navbar from "../components/partials/navbar";
import calender from "../images/cal.svg";
import mobile from "../images/mob.png";
import car from "../images/car-table.png";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryBudgetAction } from "../redux/actions/getCategoryBudgetAction";
import { Loader } from "./loader";
import ExpireModal from './ExpireModal';

const Budget = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector((state) => state.login);
  const { requestParams, filteredResponse } = useSelector(
    (state) => state.dateFilter
  );
  const [toggleState, setToggleState] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [budgetList, setBudgetList] = useState(null);
  const toggleTab = (index) => {
    setToggleState(index);
    fetchBudgetList(index);
  };

  const fetchBudgetList = (activeSection) => {
    let requestParams = {
      user_id: authUser.data.id,
      activeSection: activeSection === 1 ? "category" : "subcategory",
    };

    dispatch(getCategoryBudgetAction(requestParams)).then((response) => {
      setBudgetList(response.payload.data);
      setShowLoader(false);
    });
  };

  useEffect(() => {
    setShowLoader(true);
    fetchBudgetList(1);
  }, []);

  return (
    <>
      {showLoader && <Loader />}
      <div className="budget">
        <Header />
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 navv">
              <div className="d-flex flex-column align-items-center align-items-sm-start  pt-2 text-white min-vh-100">
                <Navbar />
              </div>
            </div>
            <div className="col background-bg">
              <div className="category-filter d-flex">
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center top-nav">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={
                            toggleState === 2 ? "active exp-nav" : "exp-nav"
                          }
                          onClick={() => toggleTab(2)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M4 8C4.55228 8 5 7.55228 5 7C5 6.44772 4.55228 6 4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8Z" />
                            <path d="M4 13C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13Z" />
                            <path d="M4 18C4.55228 18 5 17.5523 5 17C5 16.4477 4.55228 16 4 16C3.44772 16 3 16.4477 3 17C3 17.5523 3.44772 18 4 18Z" />
                            <path d="M20.06 11H7.94C7.42085 11 7 11.4209 7 11.94V12.06C7 12.5791 7.42085 13 7.94 13H20.06C20.5791 13 21 12.5791 21 12.06V11.94C21 11.4209 20.5791 11 20.06 11Z" />
                            <path d="M20.06 16H7.94C7.42085 16 7 16.4209 7 16.94V17.06C7 17.5791 7.42085 18 7.94 18H20.06C20.5791 18 21 17.5791 21 17.06V16.94C21 16.4209 20.5791 16 20.06 16Z" />
                            <path d="M20.06 6H7.94C7.42085 6 7 6.42085 7 6.94V7.06C7 7.57915 7.42085 8 7.94 8H20.06C20.5791 8 21 7.57915 21 7.06V6.94C21 6.42085 20.5791 6 20.06 6Z" />
                          </svg>{" "}
                          Sub category
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            toggleState === 1 ? "active exp-nav" : "exp-nav"
                          }
                          onClick={() => toggleTab(1)}
                        >
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M8.75 3.625H3.5C3.26794 3.625 3.04538 3.71719 2.88128 3.88128C2.71719 4.04538 2.625 4.26794 2.625 4.5V9.75C2.625 9.98206 2.71719 10.2046 2.88128 10.3687C3.04538 10.5328 3.26794 10.625 3.5 10.625H8.75C8.98206 10.625 9.20462 10.5328 9.36872 10.3687C9.53281 10.2046 9.625 9.98206 9.625 9.75V4.5C9.625 4.26794 9.53281 4.04538 9.36872 3.88128C9.20462 3.71719 8.98206 3.625 8.75 3.625V3.625ZM7.875 8.875H4.375V5.375H7.875V8.875ZM17.5 3.625H12.25C12.0179 3.625 11.7954 3.71719 11.6313 3.88128C11.4672 4.04538 11.375 4.26794 11.375 4.5V9.75C11.375 9.98206 11.4672 10.2046 11.6313 10.3687C11.7954 10.5328 12.0179 10.625 12.25 10.625H17.5C17.7321 10.625 17.9546 10.5328 18.1187 10.3687C18.2828 10.2046 18.375 9.98206 18.375 9.75V4.5C18.375 4.26794 18.2828 4.04538 18.1187 3.88128C17.9546 3.71719 17.7321 3.625 17.5 3.625V3.625ZM16.625 8.875H13.125V5.375H16.625V8.875ZM8.75 12.375H3.5C3.26794 12.375 3.04538 12.4672 2.88128 12.6313C2.71719 12.7954 2.625 13.0179 2.625 13.25V18.5C2.625 18.7321 2.71719 18.9546 2.88128 19.1187C3.04538 19.2828 3.26794 19.375 3.5 19.375H8.75C8.98206 19.375 9.20462 19.2828 9.36872 19.1187C9.53281 18.9546 9.625 18.7321 9.625 18.5V13.25C9.625 13.0179 9.53281 12.7954 9.36872 12.6313C9.20462 12.4672 8.98206 12.375 8.75 12.375ZM7.875 17.625H4.375V14.125H7.875V17.625ZM14.875 12.375C12.9447 12.375 11.375 13.9447 11.375 15.875C11.375 17.8053 12.9447 19.375 14.875 19.375C16.8053 19.375 18.375 17.8053 18.375 15.875C18.375 13.9447 16.8053 12.375 14.875 12.375ZM14.875 17.625C13.9099 17.625 13.125 16.8401 13.125 15.875C13.125 14.9099 13.9099 14.125 14.875 14.125C15.8401 14.125 16.625 14.9099 16.625 15.875C16.625 16.8401 15.8401 17.625 14.875 17.625Z" />
                          </svg>{" "}
                          Category
                        </NavLink>
                      </NavItem>

                    </Nav>
                    {/* <div className="side-data">
                        <button type="button" className="btn btn-primary date-filter"><img src={calender} alt="calender"/> 2 June 2022</button>
                     </div> */}
                  </div>
                  <TabContent>
                    <TabPane>
                      <Row
                        className={
                          toggleState === 1
                            ? " active exp-cat-table"
                            : "exp-cat-table"
                        }
                      >
                        <div className="col-lg-12 transaction-data-list table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Category</th>
                                <th scope="col">Budget</th>
                                <th scope="col">Actual Budget</th>
                                <th scope="col">Percentage</th>
                                <th scope="col">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {budgetList != null &&
                                budgetList.map((budget, key) => {
                                  return (
                                    <tr key={key}>
                                      <th scope="row">
                                        {Number(key) + Number(1)}
                                      </th>
                                      <td>{budget.category_name}</td>
                                      <td>{budget.budget}</td>
                                      <td>{budget.expence}</td>
                                      <td>{budget.percentage}%</td>
                                      <td>{budget.total}</td>
                                    </tr>
                                  );
                                })}
                              {budgetList === null && "No data found."}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </TabPane>
                    <TabPane>
                      <Row
                        className={
                          toggleState === 2
                            ? " active exp-cat-table"
                            : "exp-cat-table"
                        }
                      >
                        <div className="col-lg-12 transaction-data-list table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Sub-Category</th>
                                <th scope="col">Budget</th>
                                <th scope="col">Actual Budget</th>
                                <th scope="col">Percentage</th>
                                <th scope="col">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {budgetList != null &&
                                budgetList.map((budget, key) => {
                                  return (
                                    <tr key={key}>
                                      <th scope="row">
                                        {Number(key) + Number(1)}
                                      </th>
                                      <td>{budget.category_name}</td>
                                      <td>{budget.budget}</td>
                                      <td>{budget.expence}</td>
                                      <td>{budget.percentage}%</td>
                                      <td>{budget.total}</td>
                                    </tr>
                                  );
                                })}
                              {budgetList === null && "No data found."}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          authUser.data.subscription_status == 1 && (
            <ExpireModal />
          )
        }
      </div>
    </>
  );
};
export default Budget;
