import { checkInvoiceApi, addInvoiceApi, addSubscriptionApi } from "../../api/addInvoiceApi";
import { types } from "../actionTypes";

export const checkInvoiceAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.CHECK_INVOICE_REQUEST,
    });
    try {
      const results = await checkInvoiceApi(values);
      console.log('apiResult--->', results)

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.CHECK_INVOICE_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.CHECK_INVOICE_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.CHECK_INVOICE_FAIL,
        payload: error,
      });
    }
  };
};

export const addInvoiceAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_INVOICE_REQUEST,
    });
    try {
      const results = await addInvoiceApi(values);
      console.log('apiResult--->', results)

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.ADD_INVOICE_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.ADD_INVOICE_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.ADD_INVOICE_FAIL,
        payload: error,
      });
    }
  };
};

export const addSubscriptionAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_SUBSCRIPTION_REQUEST,
    });
    try {
       const results = await addSubscriptionApi(values);
      console.log('apiResult--->', results)

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.ADD_SUBSCRIPTION_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.ADD_SUBSCRIPTION_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.ADD_SUBSCRIPTION_FAIL,
        payload: error,
      });
    }
  };
};


export const saveFormAction = (body) =>{
  return async (dispatch) => {
    dispatch({
      type: types.SAVE_FORM,
      payload: body
    });
  };
}