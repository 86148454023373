import React, {useState } from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";


const Addinvoice = ({ createnotetoggle1, isOpen }) => {
  const dispatch = useDispatch()
  const { authUser } = useSelector((state) => state.login)
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const [estimate, setAddress] = useState('');
  const [updated, setUpdated] = useState(estimate);
 
  const handleClick = (e, val) => {
    dispatch(saveFormAction({ "estimate": val }))
    setUpdated(createnotetoggle1);
  }
  return (
    <div>
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle1={createnotetoggle1} centered>
        <ModalHeader createnotetoggle1={createnotetoggle1}>
          Create an Estimate
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="add_list_btn1 without_icon">
              Switch Document
              <span onClick={(e) => handleClick(e, 'Invoice')}>Invoice</span>
            </div>
            <div className="add_list_btn1 without_icon">
              Switch Document
              <span onClick={(e) => handleClick(e, "Estimate")} >Estimate</span>
            </div>
            <div className="add_list_btn1 without_icon">
              Switch Document
              <span onClick={(e) => handleClick(e, "Receipt")}>Receipt</span>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </div>
  );

}

export default Addinvoice;
