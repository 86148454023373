import { fetchService } from ".";

export const settingsApi = async (values) => {
  const data = await fetchService({
    method: "POST",
    url: "save-settings",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data;
};

export const getSettingsApi = async (values) => {
  const data = await fetchService({
    method: "GET",
    url: "save-settings",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });

  return data;
};