import moment from "moment";
import React, { useEffect, useState,useMemo } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Formik, Form } from "formik";
import { addTransactionAction } from "../redux/actions/addTransactionAction";
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from "./loader";
import { toast } from 'react-toastify';
import { addTransactionSchema } from "../utils/validations";

const Transactionadd = ({ incomeCategoryList, expenseCategoryList, incomeSubCategoryList, expenseSubCategoryList, fetchTransactionList, fieldValues, setFieldValues }) => {
    console.log('fieldValues---', fieldValues)
    const dispatch = useDispatch()
    const { authUser } = useSelector(state => state.login);
    const [toggleState, setToggleState] = useState(1);
    const [showLoader, setShowLoader] = useState(false)
    const toggleTab = (index) => {
        setToggleState(index);
    }
    const [repeatEvery, setRepeatEvery] = useState('none')
    const [repeatEveryValue, setRepeatEveryValue] = useState(0)
   
    useEffect(() => {
        if (fieldValues && fieldValues.repeat_data) {
            setRepeatEvery(fieldValues.repeat_data.repeat_type.toLowerCase())
            setRepeatEveryValue(fieldValues.repeat_data.repeat_every_after)
        }
        else {
            setRepeatEvery('none')
            setRepeatEveryValue(0)
        }
    }, [fieldValues])

    const handleRepeatEvery = (selection) => {
        setRepeatEvery(selection);
    }

    const handleRepeatEverySelect = (event) => {
        setRepeatEveryValue(event.target.value)
    }

    const daysArr = [{
        label: 1,
        value: 1
    }, {
        label: 2,
        value: 2
    }, {
        label: 3,
        value: 3
    }, {
        label: 4,
        value: 4
    }, {
        label: 5,
        value: 5
    }, {
        label: 6,
        value: 6
    }, {
        label: 7,
        value: 7
    }, {
        label: 8,
        value: 8
    }, {
        label: 9,
        value: 9
    }, {
        label: 10,
        value: 10
    }, {
        label: 11,
        value: 11
    }, {
        label: 12,
        value: 12
    }, {
        label: 13,
        value: 13
    }, {
        label: 14,
        value: 14
    }, {
        label: 15,
        value: 15
    }, {
        label: 16,
        value: 16
    }, {
        label: 17,
        value: 17
    }, {
        label: 18,
        value: 18
    }, {
        label: 19,
        value: 19
    }, {
        label: 20,
        value: 20
    }, {
        label: 21,
        value: 21
    }, {
        label: 22,
        value: 22
    }, {
        label: 23,
        value: 23
    }, {
        label: 24,
        value: 24
    }, {
        label: 25,
        value: 25
    }, {
        label: 26,
        value: 26
    }, {
        label: 27,
        value: 27
    }, {
        label: 28,
        value: 28
    }, {
        label: 29,
        value: 29
    }, {
        label: 30,
        value: 30
    }, {
        label: 31,
        value: 31
    }]

    const weeksArr = [{
        label: 1,
        value: 1
    }, {
        label: 2,
        value: 2
    }, {
        label: 3,
        value: 3
    }, {
        label: 4,
        value: 4
    }]

    const monthsArr = [{
        label: 1,
        value: 1
    }, {
        label: 2,
        value: 2
    }, {
        label: 3,
        value: 3
    }, {
        label: 4,
        value: 4
    }, {
        label: 5,
        value: 5
    }, {
        label: 6,
        value: 6
    }, {
        label: 7,
        value: 7
    }, {
        label: 8,
        value: 8
    }, {
        label: 9,
        value: 9
    }, {
        label: 10,
        value: 10
    }, {
        label: 11,
        value: 11
    }, {
        label: 12,
        value: 12
    }]

    return (
        <>
            {
                showLoader && <Loader />
            }
            <div className="transaction-add">
                <div className="navi">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex mb-0">
                            <li className="breadcrumb-item"><a href="#">Transaction</a></li> <span className="mx-2"> {">"} </span>
                            <li className="breadcrumb-item active" aria-current="page">{!fieldValues ? "Add" : "Edit"}</li>
                        </ol>
                    </nav>
                </div>
                <div className="Exp-category d-flex justify-content-center">

                </div>
                <div className="">
                    <div className={toggleState === 1 ? "active row expense-details" : "expense-details"}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                incomeCategory: fieldValues ? fieldValues.transactions.category_id : (incomeCategoryList && incomeCategoryList[0]) ? incomeCategoryList[0].id.toString() : "",
                                incomeSubCategory: fieldValues ? fieldValues.transactions.subcategory_id : "",
                                paymentMethod: fieldValues? fieldValues.transactions.payment_method_id:"",
                                amount: fieldValues ? fieldValues.transactions.amount : "",
                                none: "",
                                days: "",
                                weeks: "",
                                months: "",
                                repeat_end_date: fieldValues ? fieldValues.hasOwnProperty('repeat_data') && fieldValues.repeat_data.repeat_end_date : "",
                                file: "",
                                amount2: ""
                            }}
                            validateOnChange={true}
                            validationSchema={addTransactionSchema}
                            onSubmit={(values, { resetForm }) => {
                                setShowLoader(true)
                                resetForm()
                                const formData = new FormData()
                                if (fieldValues) {
                                    formData.append("id", fieldValues.transactions.id)
                                }
                                formData.append('category_id', values.incomeCategory)
                                values.incomeSubCategory != "" ? formData.append('subcategory_id', values.incomeSubCategory) : formData.append('subcategory_id', 0)
                                formData.append('payment_method', values.paymentMethod)
                                // values.paymentMethod != "" ? formData.append('payment_method', values.paymentMethod) : formData.append('payment_method', 0)
                                values.amount != "" ? formData.append('amount', values.amount) : formData.append('amount', 0)
                                if (values.file != '') {
                                    formData.append('file', values.file)
                                }
                                console.log("values.paymentMethod1", values);
                                formData.append('picture_url', 'test.jpg')
                                formData.append('user_id', authUser.data.id)
                                formData.append('transaction_type', toggleState == 1 ? 2 : 1)
                                formData.append('budget', 0)
                                formData.append('date', moment().format("YYYY-MM-DD"))
                                formData.append('repeat', repeatEvery == 'none' ? 0 : 1)

                                values.repeat_end_date != "" ? formData.append('repeat_end_date', values.repeat_end_date) : formData.append('repeat_end_date', "")

                                if (repeatEvery != 'none') {
                                    formData.append('repeat_every_after', repeatEveryValue)

                                    if (repeatEvery == 'days')
                                        formData.append('repeat_type', 1)

                                    if (repeatEvery == 'weeks')
                                        formData.append('repeat_type', 2)

                                    if (repeatEvery == 'months')
                                        formData.append('repeat_type', 3)
                                }

                                dispatch(addTransactionAction(formData)).then(response => {
                                    // setIncomeSubCategoryList(response.payload.data)

                                    // console.log(response)

                                    if (response.payload.status_code === "200") {
                                        setShowLoader(false)
                                        toast.success(response.payload.message)
                                        fetchTransactionList()
                                        window.scrollTo(0, 0)
                                    }

                                    else {
                                        setShowLoader(false)
                                        toast.error(response.payload.message)
                                    }
                                    setFieldValues(null)
                                })
                            }}
                        >
                            {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                                <Form className="row" onSubmit={handleSubmit}>
                                    <div className="col-lg-6">
                                        <div className="transaction-wrap">
                                            <div className="upper-header">
                                                <span>transaction  details (required)</span>
                                            </div>
                                            <div className="d-flex date">
                                                <strong>Date</strong>
                                                <span>{moment().format("DD-MM-YYYY")}</span>
                                            </div>
                                            <hr></hr>
                                            <div className="px-3 pb-4">
                                                <FormGroup>
                                                    <Label>Category</Label>
                                                    <Input type="select" name="incomeCategory" onChange={handleChange} value={values.incomeCategory}>
                                                        {
                                                            incomeCategoryList != null && incomeCategoryList.map((category) => {
                                                                return <option id={category.id} value={category.id}>{category.name} </option>
                                                            })

                                                        }
                                                        {
                                                            incomeCategoryList === null && 'No data found.'
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className="d-flex optional">
                                                        <Label>Sub-category</Label>
                                                        <span>Optional</span>
                                                    </div>
                                                    <Input type="select" name="incomeSubCategory" onChange={handleChange} value={values.incomeSubCategory}>
                                                        <option id={0} value="">Select Sub-category</option>
                                                        {
                                                            incomeSubCategoryList != null && incomeSubCategoryList.map((category) => {
                                                                return <option id={category.id} value={category.id}>{category.name} </option>
                                                            })

                                                        }
                                                        {
                                                            incomeSubCategoryList === null && 'No data found.'
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className="d-flex optional">
                                                        <Label>Method of Payment</Label>
                                                        <span>Optional</span>
                                                    </div>
                                                    <Input type="select" value={values.paymentMethod} onChange={handleChange} name="paymentMethod">
                                                        <option value="">Select method</option>
                                                        <option value="1">Credit Card</option>
                                                        <option value="2">Debit Card</option>
                                                        <option value="3">Wire Transfer</option>
                                                        <option value="4">Savings</option>
                                                        <option value="5">Bank</option>
                                                        <option value="6">Cheque</option>
                                                        <option value="7">Cash</option>
                                                        <option value="8">Loan</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Amount</Label>
                                                    <Input type="number" name="amount" value={values.amount} placeholder="Enter Amount" onChange={handleChange} />
                                                    <div className="error-message">
                                                        {errors.amount ? errors.amount : ''}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="transaction-wrap">
                                            <div className="upper-header">
                                                <span>Repeating details (optional)</span>
                                            </div>
                                            <FormGroup>
                                                <Label className="mt-3 ms-3">Repeat Every</Label>
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <div className={"time-selection " + (repeatEvery === 'none' ? 'bg-label' : '')} onClick={() => handleRepeatEvery('none')}>
                                                        <Label className="mt-3">None</Label>
                                                        <Input type="select" name="none" onClick={handleRepeatEverySelect}>
                                                            <option>0</option>
                                                        </Input>
                                                    </div>

                                                    <div className={"time-selection " + (repeatEvery === 'days' ? 'bg-label' : '')} onChange={() => handleRepeatEvery('days')}>
                                                        <Label className="mt-3">Days</Label>
                                                        <Input type="select" name="days" onChange={handleRepeatEverySelect}
                                                            value={
                                                                repeatEvery === 'days' ? (
                                                                    repeatEveryValue > 0 ? repeatEveryValue : (fieldValues && fieldValues.repeat_data && fieldValues.repeat_data.repeat_type == 'Days') ? fieldValues.repeat_data.repeat_every_after : repeatEveryValue) : 0
                                                            }
                                                        >
                                                            {daysArr.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Input>
                                                    </div>

                                                    <div className={"time-selection " + (repeatEvery === 'weeks' ? 'bg-label' : '')} onClick={() => handleRepeatEvery('weeks')}>
                                                        <Label className="mt-3">Weeks</Label>
                                                        <Input type="select" name="weeks" onChange={handleRepeatEverySelect}
                                                            value={
                                                                repeatEvery === 'weeks' ? (
                                                                    repeatEveryValue > 0 ? repeatEveryValue : (fieldValues && fieldValues.repeat_data && fieldValues.repeat_data.repeat_type == 'Weeks') ? fieldValues.repeat_data.repeat_every_after : repeatEveryValue) : 0
                                                            }
                                                        >
                                                            {weeksArr.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Input>
                                                    </div>

                                                    <div className={"time-selection " + (repeatEvery === 'months' ? 'bg-label' : '')} onClick={() => handleRepeatEvery('months')}>
                                                        <Label className="mt-3">Months</Label>
                                                        <Input type="select" name="months" onChange={handleRepeatEverySelect}
                                                            value={
                                                                repeatEvery === 'months' ? (
                                                                    repeatEveryValue > 0 ? repeatEveryValue : (fieldValues && fieldValues.repeat_data && fieldValues.repeat_data.repeat_type == 'Months') ? fieldValues.repeat_data.repeat_every_after : repeatEveryValue) : 0
                                                            }
                                                        >
                                                            {monthsArr.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Input>
                                                    </div>
                                                    <p className="mb-0 mt-5">{repeatEveryValue + ' ' + repeatEvery}</p>
                                                </div>

                                            </FormGroup>
                                            <div className="px-3 pb-4">
                                                <FormGroup>
                                                    <Label for="exampleDate">Repeat End Date</Label>
                                                    <Input type="date" name="repeat_end_date" value={values.repeat_end_date} placeholder="Choose End Date" onChange={handleChange} />
                                                </FormGroup>
                                                {/* <div className="mb-3">
                                                <label for="formFile" className="form-label">Upload file</label>
                                                <input className="form-control" type="file" name="file"
                                                onChange={(event) => { setFieldValue("file", event.currentTarget.files[0]); }}/>
                                            </div>
                                            <FormGroup>
                                                <Label for="exampleEmail">Amount</Label>
                                                <Input type="number" name="amount2" placeholder="Enter Amount" onChange={handleChange} />
                                            </FormGroup> */}
                                            </div>
                                        </div>
                                        <div className="bottom-butns-transaction d-flex justify-content-end mt-4">
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className={toggleState === 2 ? "active row expense-details" : "expense-details"}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                incomeCategory: fieldValues ? fieldValues.transactions.category_id : (incomeCategoryList && incomeCategoryList[0]) ? incomeCategoryList[0].id.toString() : "",
                                incomeSubCategory: fieldValues ? fieldValues.transactions.subcategory_id : "",
                                paymentMethod: fieldValues? fieldValues.transactions.payment_method_id:"",
                                amount: fieldValues ? fieldValues.transactions.amount : "",
                                none: "",
                                days: "",
                                weeks: "",
                                months: "",
                                repeat_end_date: fieldValues ? fieldValues.hasOwnProperty('repeat_data') && fieldValues.repeat_data.repeat_end_date : "",
                                file: "",
                                amount2: ""
                            }}
                            validateOnChange={true}
                            validationSchema={addTransactionSchema}
                            onSubmit={(values, { resetForm }) => {
                                setShowLoader(true)
                                resetForm()
                                const formData = new FormData()
                                formData.append('category_id', values.incomeCategory)
                                values.incomeSubCategory != "" ? formData.append('subcategory_id', values.incomeSubCategory) : formData.append('subcategory_id', 0)
                                console.log("values.paymentMethod", values.paymentMethod);
                                values.paymentMethod != "" ? formData.append('payment_method', values.paymentMethod) : formData.append('payment_method', 0)
                                values.amount != "" ? formData.append('amount', values.amount) : formData.append('amount', 0)
                                if (values.file != '') {
                                    formData.append('file', values.file)
                                }

                                formData.append('picture_url', 'test.jpg')

                                formData.append('user_id', authUser.data.id)
                                formData.append('transaction_type', toggleState == 1 ? 2 : 1)
                                formData.append('budget', 0)
                                formData.append('date', moment().format("YYYY-MM-DD"))
                                formData.append('repeat', repeatEvery == 'none' ? 0 : 1)

                                values.repeat_end_date != "" ? formData.append('repeat_end_date', values.repeat_end_date) : formData.append('repeat_end_date', "")

                                if (repeatEvery != 'none') {
                                    formData.append('repeat_every_after', repeatEveryValue)

                                    if (repeatEvery == 'days')
                                        formData.append('repeat_type', 1)

                                    if (repeatEvery == 'weeks')
                                        formData.append('repeat_type', 2)

                                    if (repeatEvery == 'months')
                                        formData.append('repeat_type', 3)
                                }

                                dispatch(addTransactionAction(formData)).then(response => {
                                    // setIncomeSubCategoryList(response.payload.data)

                                    // console.log(response)

                                    if (response.payload.status_code === "200") {
                                        setShowLoader(false)
                                        toast.success(response.payload.message)
                                        fetchTransactionList()
                                        window.scrollTo(0, 0)
                                    }

                                    else {
                                        setShowLoader(false)
                                        toast.error(response.payload.message)
                                    }
                                    setFieldValues(null)
                                })
                            }}
                        >
                            {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
                                <Form className="row" onSubmit={handleSubmit}>
                                    <div className="col-lg-6">
                                        <div className="transaction-wrap">
                                            <div className="upper-header">
                                                <span>transaction  details (required)</span>
                                            </div>
                                            <div className="d-flex date">
                                                <strong>Date</strong>
                                                <span>{moment().format("DD-MM-YYYY")}</span>
                                            </div>
                                            <hr></hr>
                                            <div className="px-3 pb-4">
                                                <FormGroup>
                                                    <Label>Category</Label>
                                                    <Input type="select" name="incomeCategory" onChange={handleChange} value={values.incomeCategory}>
                                                        {
                                                            expenseCategoryList != null && expenseCategoryList.map((category) => {
                                                                return <option id={category.id} value={category.id}>{category.name} </option>
                                                            })

                                                        }
                                                        {
                                                            expenseCategoryList === null && 'No data found.'
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className="d-flex optional">
                                                        <Label>Sub-category</Label>
                                                        <span>Optional</span>
                                                    </div>            <Input type="select" name="incomeSubCategory" onChange={handleChange} value={values.incomeSubCategory}>

                                                        <option id={0} value="">Select Sub-category</option>
                                                        {
                                                            expenseSubCategoryList != null && expenseSubCategoryList.map((category) => {
                                                                return <option id={category.id} value={category.id}>{category.name} </option>
                                                            })

                                                        }
                                                        {
                                                            expenseSubCategoryList === null && 'No data found.'
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div className="d-flex optional">
                                                        <Label>Method of Payment</Label>
                                                        <span>Optional</span>
                                                    </div>
                                                    <Input type="select" value={values.paymentMethod} onChange={handleChange} name="paymentMethod">
                                                        {
                                                            console.log(values.paymentMethod)
                                                        }
                                                        <option value="">Select method</option>
                                                        <option value="1">Credit Card</option>
                                                        <option value="2">Debit Card</option>
                                                        <option value="3">Wire Transfer</option>
                                                        <option value="4">Savings</option>
                                                        <option value="5">Bank</option>
                                                        <option value="6">Cheque</option>
                                                        <option value="7">Cash</option>
                                                        <option value="8">Loan</option>
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label>Amount</Label>
                                                    <Input type="number" name="amount" value={values.amount} placeholder="Enter Amount" onChange={handleChange} />
                                                    <div className="error-message">
                                                        {errors.amount ? errors.amount : ''}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="transaction-wrap">
                                            <div className="upper-header">
                                                <span>Repeating details (optional)</span>
                                            </div>
                                            <FormGroup>
                                                <Label className="mt-3 ms-3">Repeat Every</Label>
                                                <div className="d-flex flex-wrap align-items-center">
                                                    <div className={"time-selection " + (repeatEvery === 'none' ? 'bg-label' : '')} onClick={() => handleRepeatEvery('none')}>
                                                        <Label className="mt-3">None</Label>
                                                        <Input type="select" name="none" onClick={handleRepeatEverySelect}>
                                                            <option>0</option>
                                                        </Input>
                                                    </div>

                                                    <div className={"time-selection " + (repeatEvery === 'days' ? 'bg-label' : '')} onChange={() => handleRepeatEvery('days')}>
                                                        <Label className="mt-3">Days</Label>
                                                        <Input type="select" name="days" onChange={handleRepeatEverySelect}
                                                            value={
                                                                repeatEvery === 'days' ? (
                                                                    repeatEveryValue > 0 ? repeatEveryValue : (fieldValues && fieldValues.repeat_data && fieldValues.repeat_data.repeat_type == 'Days') ? fieldValues.repeat_data.repeat_every_after : repeatEveryValue) : 0
                                                            }
                                                        >
                                                            {daysArr.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Input>
                                                    </div>

                                                    <div className={"time-selection " + (repeatEvery === 'weeks' ? 'bg-label' : '')} onClick={() => handleRepeatEvery('weeks')}>
                                                        <Label className="mt-3">Weeks</Label>
                                                        <Input type="select" name="weeks" onChange={handleRepeatEverySelect}
                                                            value={
                                                                repeatEvery === 'weeks' ? (
                                                                    repeatEveryValue > 0 ? repeatEveryValue : (fieldValues && fieldValues.repeat_data && fieldValues.repeat_data.repeat_type == 'Weeks') ? fieldValues.repeat_data.repeat_every_after : repeatEveryValue) : 0
                                                            }
                                                        >
                                                            {weeksArr.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Input>
                                                    </div>

                                                    <div className={"time-selection " + (repeatEvery === 'months' ? 'bg-label' : '')} onClick={() => handleRepeatEvery('months')}>
                                                        <Label className="mt-3">Months</Label>
                                                        <Input type="select" name="months" onChange={handleRepeatEverySelect}
                                                            value={
                                                                repeatEvery === 'months' ? (
                                                                    repeatEveryValue > 0 ? repeatEveryValue : (fieldValues && fieldValues.repeat_data && fieldValues.repeat_data.repeat_type == 'Months') ? fieldValues.repeat_data.repeat_every_after : repeatEveryValue) : 0
                                                            }
                                                        >
                                                            {monthsArr.map((option) => (
                                                                <option value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Input>
                                                    </div>
                                                    <p className="mb-0 mt-5">{repeatEveryValue + ' ' + repeatEvery}</p>
                                                </div>

                                            </FormGroup>
                                            <div className="px-3 pb-4">
                                                <FormGroup>
                                                    <Label for="exampleDate">Repeat End Date</Label>
                                                    <Input type="date" name="repeat_end_date" value={values.repeat_end_date} placeholder="Choose End Date" onChange={handleChange} />
                                                </FormGroup>
                                                {/* <div className="mb-3">
                                                <label for="formFile" className="form-label">Upload file</label>
                                                <input className="form-control" type="file" name="file"
                                                onChange={(event) => { setFieldValue("file", event.currentTarget.files[0]); }}/>
                                            </div> */}
                                                {/* <FormGroup>
                                                <Label for="exampleEmail">Amount</Label>
                                                <Input type="number" name="amount2" placeholder="Enter Amount" onChange={handleChange} />
                                            </FormGroup> */}
                                            </div>
                                        </div>
                                        <div className="bottom-butns-transaction d-flex justify-content-end mt-4">
                                            <button type="submit" className="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div className="text-center mt-3 pb-2 Exp-category">
                    <div className="d-inline-flex expense-tabs">
                        <span className={toggleState === 1 ? "active expense-tab" : "expense-tab"} onClick={() => toggleTab(1)}>Income</span>
                        <hr className="mx-2"></hr>
                        <span className={toggleState === 2 ? "active expense-tab" : "expense-tab"} onClick={() => toggleTab(2)}>Expense</span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Transactionadd;