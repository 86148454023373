import { fetchService } from ".";

export const loginUser = async (values) => {
const data = await fetchService({
    method: "POST",
    url: "log-in",
    body: JSON.stringify(values),
    isUrl: false,
    myHeaders: {
      "Content-Type": "application/json",
    },
  });
  return data
}