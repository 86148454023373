export const types = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAIL: "LOGIN_FAIL",
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAIL: "REGISTER_FAIL",
    DATE_FILTER_REQUEST: "DATE_FILTER_REQUEST",
    DATE_FILTER_SUCCESS: "DATE_FILTER_SUCCESS",
    DATE_FILTER_FAIL: "DATE_FILTER_FAIL",
    ADD_CATEGORY_REQUEST: "ADD_CATEGORY_REQUEST",
    ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
    ADD_CATEGORY_FAIL: "ADD_CATEGORY_FAIL",
    DASHBOARD_DATA_REQUEST: "DASHBOARD_DATA_REQUEST",
    DASHBOARD_DATA_SUCCESS: "DASHBOARD_DATA_SUCCESS",
    DASHBOARD_DATA_FAIL: "DASHBOARD_DATA_FAIL",
    ADD_NOTE_REQUEST: "ADD_NOTE_REQUEST",
    ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
    ADD_NOTE_FAIL: "ADD_NOTE_FAIL",
    SET_SETTING_FORMDATA: "SET_SETTING_FORMDATA",
    SET_SETTING_CURRENCY: "SET_SETTING_CURRENCY",
    SET_SETTING_WEEK_START: "SET_SETTING_WEEK_START",
    SET_SETTING_MONTH_START: "SET_SETTING_MONTH_START",
    SETTING_REQUEST: "SETTING_REQUEST",
    SETTING_SUCCESS: "SETTING_SUCCESS",
    SETTING_FAIL: "SETTING_FAIL",
    FILTERED_RESPONSE_SUCCESS: "FILTERED_RESPONSE_SUCCESS",

    NOTE_PASSWORD_VALIDATION_REQUEST: "NOTE_PASSWORD_VALIDATION_REQUEST",
    NOTE_PASSWORD_VALIDATION_SUCCESS: "NOTE_PASSWORD_VALIDATION_SUCCESS",
    NOTE_PASSWORD_VALIDATION_FAILED: "NOTE_PASSWORD_VALIDATION_FAILED",

    // Create Invoice 
    CHECK_INVOICE_REQUEST: "CHECK_INVOICE_REQUEST",
    CHECK_INVOICE_SUCCESS: "CHECK_INVOICE_SUCCESS",
    CHECK_INVOICE_FAIL: "CHECK_INVOICE_FAIL",

    ADD_INVOICE_REQUEST: "ADD_INVOICE_REQUEST",
    ADD_INVOICE_SUCCESS: "ADD_INVOICE_SUCCESS",
    ADD_INVOICE_FAIL: "ADD_INVOICE_FAIL",

    ADD_SUBSCRIPTION_REQUEST: "ADD_SUBSCRIPTION_REQUEST",
    ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
    ADD_SUBSCRIPTION_FAIL: "ADD_SUBSCRIPTION_FAILL",

    BUY_SUBSCRIPTION_REQUEST: "BUY_SUBSCRIPTION_REQUEST",
    BUY_SUBSCRIPTION_SUCCESS: "BUY_SUBSCRIPTION_SUCCESS",
    BUY_SUBSCRIPTION_FAIL: "BUY_SUBSCRIPTION_FAILL",


    SAVE_FORM: "SAVE_FORM"
}