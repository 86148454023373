import { getDailyTransactionApi, fetchTransactionDetail, deleteTransaction } from "../../api/getDailyTransactionApi";
import { types } from "../actionTypes";

export const getDailyTransaction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.DASHBOARD_DATA_REQUEST,
    });
    try {
      const results = await getDailyTransactionApi(values);

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.DASHBOARD_DATA_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.DASHBOARD_DATA_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.ADD_CATEGORY_FAIL,
        payload: error,
      });
    }
  };
};


export const fetchTransactionDetailAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.DASHBOARD_DATA_REQUEST,
    });
    try {
      const results = await fetchTransactionDetail(values);

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.DASHBOARD_DATA_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.DASHBOARD_DATA_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.ADD_CATEGORY_FAIL,
        payload: error,
      });
    }
  };
};

export const deleteTransactionAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.DASHBOARD_DATA_REQUEST,
    });
    try {
      const results = await deleteTransaction(values);

      if (results.data.status_code == 200) {
        return dispatch({
          type: types.DASHBOARD_DATA_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.DASHBOARD_DATA_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.ADD_CATEGORY_FAIL,
        payload: error,
      });
    }
  };
};