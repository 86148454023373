import React, { useState, useEffect } from "react";
import Header from '../components/partials/header';
import Navbar from '../components/partials/navbar';
import archive from '../images/archive.svg';
import add from '../images/add.svg';
import note from '../images/note-edit.svg';
import archi from '../images/note-archi.svg';
import notedel from '../images/note-del.svg';
import Modalviewnote from "./common/view-note";
import Modaleditnote from "./common/edit-note";
import Modalcreatenote from "./common/create-note";
import archie from '../images/archie.svg';
import { getNotesAction } from "../redux/actions/getNotesAction";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
// import useEffect from "../utils/useEffect";
import { deleteNoteAction } from "../redux/actions/deleteNoteAction";
import { Loader } from "./loader";
import { archiveNoteAction } from "../redux/actions/archiveNoteAction";
import { ReactComponent as LockSvg } from '../images/lock-svgrepo-com.svg'

import { verifyNotePasswordAction, forgotPasswordAction } from "../redux/actions/addNoteAction";
import { useNavigate } from "react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ExpireModal from './ExpireModal';


const NoteTile = ({ title, date }) => {
    return (
        <div className="note-tile">
            <h5>{title}</h5>
            <span >Date: {date}</span>
        </div>
    )
}


const Notes = () => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setEditOpen] = useState(false);
    const [isCreateOpen, setCreateOpen] = useState(false);
    const [activeSection, setActiveSection] = useState("notes");
    const { authUser } = useSelector(state => state.login);
    const [notesList, setNotesList] = useState(null)
    const [archiveList, setArchiveList] = useState(null)
    const [hideLoader, setHideLoader] = useState(false)
    const [viewNoteData, setViewNoteData] = useState(null)
    const [tilePassword, setTilePassword] = useState('');
    const navigate = useNavigate()


    const handleNoteForgotPassword = (id) => {
        setHideLoader(true)
        let requestParams = {
            'user_id': authUser.data.id,
            "id": id
        }

        dispatch(forgotPasswordAction(requestParams)).then(response => {
            if (response.payload.status_code == '200') {
                toast.success(response.payload.message)

                navigate('/forgotNotePassword', {
                    state: {
                        note_id: id
                    }
                })
            }
            else {
                toast.error(response.payload)
            }

            setHideLoader(false)
        })
    }

    const unlockNoteTile = (id) => {
        if (tilePassword.hasOwnProperty(id) && tilePassword[id].trim().length > 0) {
            setHideLoader(true)
            let requestParams = {
                'user_id': authUser.data.id,
                "password": tilePassword[id].trim(),
                "id": id
            }

            dispatch(verifyNotePasswordAction(requestParams)).then(response => {
                if (response.payload.status_code == '200') {
                    if (activeSection == 'notes') {
                        let notesListModified = notesList.map((note) => {
                            if (note.id == id) {
                                note.protected = false
                            }
                            return note
                        })

                        setNotesList(notesListModified)
                    }
                    else {
                        let notesListModified = archiveList.map((note) => {
                            if (note.id == id) {
                                note.protected = false
                            }
                            return note
                        })

                        setArchiveList(notesListModified)
                    }

                    toast.success('Note Unlocked')
                }

                else {
                    toast.error(response.payload)
                }

                setHideLoader(false)
            })
        }
    };

    const toggle = (noteData) => {
        setIsOpen(!isOpen)
        setViewNoteData(noteData)
    }
    const editnotetoggle = (noteData) => {
        setEditOpen(!isEditOpen)
        setViewNoteData(noteData)
    }
    const createnotetoggle = () => {
        setCreateOpen(!isCreateOpen)
    }

    const getNotesListing = (section) => {
        setHideLoader(true)
        let requestParams = {
            'user_id': authUser.data.id,
            'archive': section === "archive" ? true : false
        }

        dispatch(getNotesAction(requestParams)).then(response => {
            // console.log(response.payload.data)
            if (response.payload.status_code == '200') {
                section === "archive" ? setArchiveList(response.payload.data) : setNotesList(response.payload.data)
            }

            else {
                toast.error(response.payload.message)
            }

            setHideLoader(false)
        })
    }

    const getNotes = () => {

        setActiveSection("notes")
        getNotesListing("notes")
    }

    const getArchiveNotes = () => {

        setActiveSection("archive")
        getNotesListing("archive")
    }

    useEffect(() => {

        getNotesListing("notes")
    }, []);

    const getColorCode = (notecolor) => {

        var colorCode = '#FF9300'
        switch (notecolor) {
            case 0:
                colorCode = '#F32225'
                return colorCode;
            case 1:
                colorCode = '#2A79AB'
                return colorCode;
            case 2:
                colorCode = '#3E9C53'
                return colorCode;
            case 3:
                colorCode = '#8293A1'
                return colorCode;
            case 4:
                colorCode = '#725A48'
                return colorCode;
            case 5:
                colorCode = '#B7BAEE'
                return colorCode;
            case 6:
                colorCode = '#FF9300'
                return colorCode;
            default:
                return colorCode;
        }
    }

    const deleteNote = (noteId) => {

        let requestParams = {
            'user_id': authUser.data.id,
            'id': noteId
        }

        dispatch(deleteNoteAction(requestParams)).then(response => {

            if (response.payload.status_code == '200') {
                toast.success(response.payload.message)
                activeSection === "notes" ? getNotesListing() : getArchiveNotes()
            }

            else {
                toast.error(response.payload.message)
            }
        })
    }

    const archiveNote = (noteId) => {
        let requestParams = {
            'user_id': authUser.data.id,
            'id': noteId
        }

        dispatch(archiveNoteAction(requestParams)).then(response => {

            if (response.payload.status_code == '200') {
                toast.success(response.payload.message)
                getNotesListing()
            }

            else {
                toast.error(response.payload.message)
            }
        })
    }

    return (
        <>
            {
                hideLoader && <Loader />
            }
            <div className="notes">
                <Header />
                <div className="container-fluid">
                    <div className="row flex-nowrap">
                        <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 navv">
                            <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                                <Navbar />
                            </div>
                        </div>
                        <div className="col background-bg">
                            {activeSection === "notes" && <div className={"category-filter active notess  notess"}>
                                <div className="notes">
                                    <h4 className="mb-0">Notes</h4>
                                    <div className="right-actions">
                                        <img src={archive} alt="archive" className={"active-chart chatt-tab chatt-tab"} onClick={() => {
                                            getArchiveNotes()
                                        }} />
                                        <button type="button" className="btn btn-primary" onClick={createnotetoggle}> <img src={add} alt="add" /> Add</button>
                                    </div>
                                </div>
                                <div className="notes-list d-flex">
                                    {notesList && notesList != null && notesList.map((noteData) => {

                                        return <div className="not" key={noteData.id} style={{ backgroundColor: getColorCode(noteData.color) }}>
                                            {
                                                noteData.protected
                                                    ? (
                                                        <div className="" key={noteData.id}>
                                                            <div className="note-tile">
                                                                <h5 className="locked_p">Locked
                                                                    <span>
                                                                        <LockSvg height={16} width={16} fill={"black"} />
                                                                    </span>
                                                                </h5>
                                                                <div className="input_flex">
                                                                    <input
                                                                        type="password"
                                                                        placeholder="Enter note password to unlock"
                                                                        value={tilePassword[noteData.id]}

                                                                        onChange={(e) => {
                                                                            setTilePassword({ [noteData.id]: e.target.value })
                                                                        }}
                                                                    />
                                                                    <button type="submit" onClick={() => unlockNoteTile(noteData.id)}>Unlock</button>
                                                                </div>

                                                                <div className="forgot_p">
                                                                    <span onClick={() => handleNoteForgotPassword(noteData.id)}>Forgot Password?</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <>
                                                            <div className="d-flex justify-content-between" key={noteData.id}>
                                                                <NoteTile {...noteData} />
                                                                <div className="note-actions">
                                                                    {/* <img onClick={() => editnotetoggle(noteData)} src={note} alt="note-edit" /> */}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip >Archive</Tooltip>}
                                                                    >
                                                                        <img src={archi} onClick={() => archiveNote(noteData.id)} alt="note-archive" />
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip >Delete</Tooltip>}
                                                                    >
                                                                        <img src={notedel} onClick={() => deleteNote(noteData.id)} alt="note-del" />
                                                                    </OverlayTrigger>

                                                                </div>
                                                            </div>
                                                            <p>{noteData.body}<span onClick={() => { toggle(noteData) }}>Read</span></p>
                                                        </>
                                                    )
                                            }

                                        </div>
                                    })
                                    }
                                </div>
                            </div>}
                            {activeSection === "archive" && <div className={"category-filter active archivee archivee"}>
                                <div className="notes">
                                    <h4 className="mb-0">Archive</h4>
                                    <div className="right-actions">
                                        <div className=""> <button type="button" className={"btn btn-primary active-chart chatt-tab chatt-tab"} onClick={() => {
                                            getNotes()
                                        }}>  Notes</button></div>

                                    </div>
                                </div>
                                <div className="notes-list d-flex">
                                    {archiveList && archiveList != null && archiveList.map((noteData) => {



                                        return <div className="not" key={noteData.id} style={{ backgroundColor: getColorCode(noteData.color) }}>
                                            {
                                                noteData.protected
                                                    ? (
                                                        <div className="" key={noteData.id}>
                                                            <div className="note-tile">
                                                                <h5 className="locked_p">Locked
                                                                    <span>
                                                                        <LockSvg height={16} width={16} fill={"black"} />
                                                                    </span>
                                                                </h5>
                                                                <div className="input_flex">
                                                                    <input
                                                                        type="password"
                                                                        placeholder="Enter note password to unlock"
                                                                        value={tilePassword[noteData.id]}

                                                                        onChange={(e) => {
                                                                            setTilePassword({ [noteData.id]: e.target.value })
                                                                        }}
                                                                    />
                                                                    <button type="submit" onClick={() => unlockNoteTile(noteData.id)}>Unlock</button>
                                                                </div>

                                                                <div className="forgot_p">
                                                                    <span onClick={() => handleNoteForgotPassword(noteData.id)}>Forgot Password?</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    (
                                                        <>
                                                            <div className="d-flex justify-content-between" key={noteData.id}>
                                                                <NoteTile {...noteData} />
                                                                <div className="note-actions">
                                                                    {/* <img onClick={() => editnotetoggle(noteData)} src={note} alt="note-edit" /> */}

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip >Delete</Tooltip>}
                                                                    >
                                                                        <img src={notedel} onClick={() => deleteNote(noteData.id)} alt="note-del" />
                                                                    </OverlayTrigger>

                                                                </div>
                                                            </div>
                                                            <p>{noteData.body}<span onClick={() => { toggle(noteData) }}>Read</span></p>
                                                        </>
                                                    )
                                            }

                                        </div>

                                        // return <div className="not" key={noteData.id} style={{ backgroundColor: getColorCode(noteData.color) }}>
                                        //     <div className="d-flex justify-content-between">
                                        //         <NoteTile title={noteData.title} date={noteData.date} />

                                        //         <div className="note-actions">
                                        //             {/* <img onClick={editnotetoggle} src={note} alt="note-edit"/>
                                        //                 <img src={archi} onClick={()=>archiveNote(noteData.id)} alt="note-archive" /> */}

                                        //             <OverlayTrigger
                                        //                 placement="top"
                                        //                 overlay={<Tooltip >Delete</Tooltip>}
                                        //             >
                                        //             <img src={notedel} onClick={() => deleteNote(noteData.id)} alt="note-del" />
                                        //             </OverlayTrigger>

                                        //         </div>
                                        //     </div>
                                        //     <p><span onClick={() => toggle(noteData)}>Read ..</span></p>
                                        // </div>
                                    })
                                    }
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <Modalviewnote isOpen={isOpen} toggle={toggle} viewNotedata={viewNoteData} />
                {/* <Modaleditnote isOpen = {isEditOpen} editnotetoggle = {editnotetoggle} viewNotedata={viewNoteData}/> */}
                <Modalcreatenote isOpen={isCreateOpen} createnotetoggle={createnotetoggle} getNotesListing={getNotesListing} />

                {
                    authUser.data.subscription_status == 1 && (
                        <ExpireModal />
                    )
                }
            </div>
        </>
    );
}

export default Notes;
