import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Shipto = ({ createnotetoggle12, isOpen, getNotesListing }) => {
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const dispatch = useDispatch()
  const [shipaddress, setAddress] = useState(invoiceDetailsStates.shipaddress);
  const [updated, setUpdated] = useState(shipaddress);
  const handleChange = (event) => {
      setAddress(event.target.value);
  };
  const handleClick = ()  =>{
      dispatch(saveFormAction({"shipaddress": shipaddress}))    
      setUpdated(createnotetoggle12);
    }



  return (
    <div>
      <Modal className="exp-cat"  isOpen={isOpen} createnotetoggle12={createnotetoggle12} centered>
        <ModalHeader createnotetoggle12={createnotetoggle12}>
       Ship To Address
        </ModalHeader>
            <ModalBody>
            <Form className="form_text">
                  <FormGroup>                
                    <Input
                      name="shipaddress"
                      type="textarea"
                      value={shipaddress}
                      rows="4"
                      placeholder="Shipping address of your customer"
                      onChange={handleChange}
                    />                  
                  </FormGroup>
                  </Form>
              <div className="modal_footer modal_footer1 border-0 mt-0">
                <button className="btn outline_btn" type="submit"  onClick={handleClick}>SAVE</button>
              </div>
            </ModalBody>
      </Modal>
    </div>
  );
}

export default Shipto;
