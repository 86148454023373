import React, { useCallback, useEffect, useState } from "react";
import { Form, Label, Input, FormGroup, InputGroup } from "reactstrap";
import Header from "../components/partials/header";
import Navbar from "../components/partials/navbar";
import account from "../images/account.svg";
import lock from "../images/lock.svg";
import billing from "../images/billing.svg";
import privacy from "../images/privacy.svg";
import user from "../images/user1.png";
import upload from "../images/upload.svg";
import { toast } from "react-toastify";
import select from "../images/select.svg";
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setCurrency, setFormData, setMonthStart, settingsAction, setWeekStart } from "../redux/actions/settingsAction";
import { buySubscriptionAction } from "../redux/actions/loginAction";
import { type } from "@testing-library/user-event/dist/type";
import { setGoogleDriveSyncing, setDropboxSyncing } from "../redux/actions/syncingActions";
import useGoogle from "./googleDrive/useGoogle";
import ExpireModal from './ExpireModal';
import logosmall from "../images/logo1.png";
import { Loader } from "./loader";
import StripeCheckout from 'react-stripe-checkout';
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

const Settings = () => {
  const { token, authorize, revoke } = useGoogle();
  const dispatch = useDispatch();

  const [rememberUser, setRememberUser] = useState(true);
  const [archiveList, setArchiveList] = useState(null);
  const [notesList, setNotesList] = useState(null);

  const [isShown, setIsShown] = useState(false);

  const { authUser } = useSelector((state) => state.login);
  const { formData, currency, weekStart, monthStart } = useSelector((state) => state.setting);
  const { googleDriveSync, dropboxSync } = useSelector((state) => state.syncing);
  const [toggleState, setToggleState] = useState(2);
  const [stateChange, setStateChange] = useState(false);
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);

  const [modal, setModal] = React.useState(false);
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const [selectPlan, setSelectPlan] = useState('monthly');
  const [showLoader, setShowLoader] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  const toggle4 = () => setModal4(!modal4);

  const handleGoogleDriveSync = () => {
    if (!googleDriveSync) {
      authorize()
      // localStorage.setItem('google_drive_sync', true)
    }
    else {
      revoke()
      // localStorage.removeItem('google_drive_sync')
    }
    // dispatch(setGoogleDriveSyncing(!googleDriveSync))
  }

  const handlePayment = (token) => {
    // show loader
    setShowLoader(true)

    let requestParams = {
      'user_id': authUser.data.id,
      'price': selectPlan == 'monthly' ? 500 : 5000,
      'card_token': token.id,
      'email': token.email,
      'plan_id': selectPlan == 'monthly' ? 1 : 2,
    }

    dispatch(buySubscriptionAction(requestParams)).then(response => {
      console.log(response);
      setSubscriptionExpired(false);

      if (response.payload.status_code == '200') {
        toast.success(response.payload.message)
      }
      else {
        let msg = response.payload.message ? response.payload.message : response.payload;
        toast.error(msg)
      }
      setShowLoader(false)
    })
  };

  const handleDropBoxSync = () => {
    if (!dropboxSync) {
      window.location = `https://dropbox.com/oauth2/authorize?response_type=code&client_id=${"j4kutxzk32nl7yr"}&redirect_uri=${"https://spenleaf-frontend.iapplabz.co.in/settings"}`;

    }
    else {
      let dropbox_access_token = localStorage.getItem('dropbox_access_token')
      if (dropbox_access_token) {
        fetch(`https://api.dropboxapi.com/2/auth/token/revoke`, {
          headers: {
            Authorization: `Bearer ${dropbox_access_token}`
          },
          method: "POST"
        }).then(async (resp) => {
          if (resp.status == 200) {
            localStorage.removeItem('dropbox_sync')
            localStorage.removeItem('dropbox_access_token')
            dispatch(setDropboxSyncing(false))
          }
        })
      }
    }
  }



  useEffect(() => {
    let googleDriveSyncState = localStorage.getItem('google_drive_sync')
    dispatch(setGoogleDriveSyncing(googleDriveSyncState ? true : false))

    let dropboxSyncState = localStorage.getItem('dropbox_sync')
    dispatch(setDropboxSyncing(dropboxSyncState ? true : false))

    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');

    if (code) {
      fetch(`https://api.dropbox.com/oauth2/token?code=${code}&grant_type=authorization_code&redirect_uri=https://spenleaf-frontend.iapplabz.co.in/settings`, {
        headers: {
          Authorization: "Basic ajRrdXR4emszMm5sN3lyOml3dG83b3VhNHB5ZThsZA==",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }).then(async (resp) => {
        if (resp.status == 200) {
          resp = await resp.json()
          localStorage.setItem('dropbox_sync', true)
          localStorage.setItem('dropbox_access_token', resp.access_token)
          dispatch(setDropboxSyncing(true))
        }
      })

      // localStorage.setItem("accesstoken", resp.access_token);
    }

  }, [])

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    if (settings) {
      if (settings.formData) {
        dispatch(setFormData(settings.formData));
      }
      if (settings.currency) {
        dispatch(setCurrency(settings.currency));
      }
      if (settings.weekStart) {
        dispatch(setWeekStart(settings.weekStart));
      }
      if (settings.monthStart) {
        dispatch(setMonthStart(settings.monthStart));
      }
    }
  }, []);

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem(
      "settings",
      JSON.stringify({
        ...settings,
        formData,
      })
    );
  }, [formData]);

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem(
      "settings",
      JSON.stringify({
        ...settings,
        currency,
      })
    );
  }, [currency]);

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem(
      "settings",
      JSON.stringify({
        ...settings,
        weekStart,
      })
    );
  }, [weekStart]);

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    localStorage.setItem(
      "settings",
      JSON.stringify({
        ...settings,
        monthStart,
      })
    );
  }, [monthStart]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const togglePlan = (billing) => {
    setSelectPlan(billing);
  };

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);
  };

  //sync api
  const getNotesListing = useCallback(() => {
    if (authUser && formData) {
      let obj = formData;
      if (weekStart && weekStart >= 0) {
        obj = {
          ...obj,
          week_start_day: weekStart,
        };
      } else {
        delete obj.week_start_day;
      }

      if (monthStart && monthStart >= 0) {
        obj = {
          ...obj,
          month_start_date: monthStart,
        };
      } else {
        delete obj.month_start_date;
      }

      console.log("Obj ------------>", obj);
      dispatch(settingsAction({ ...obj, user_id: authUser.data.id })).then(
        (response) => {
          console.log("settingggggg--Sync", response);
          if (response.payload.status_code == "200") {
            console.log("Sync+++++++++", response.payload);
          } else {
            toast.error(response.payload.message);
          }
        }
      );
    }
  }, [formData, weekStart, monthStart, authUser, dispatch]);

  useEffect(() => {
    getNotesListing();
  }, [formData, getNotesListing]);

  const data = [
    "Default",
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CFA",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "ECO",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FCFA",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GGP",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "IMP",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JEP",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRU",
    "MVR",
    "MWK",
    "MXN",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLL",
    "SOS",
    "SPL",
    "SRD",
    "STN",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TVD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "UYU",
    "UZS",
    "VEF",
    "VES",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XBT",
    "XCD",
    "XOF",
    "ZAR",
    "ZMW",
    "ZWD",
  ];

  const SymbolsData = [
    "Default",
    "Tk",
    "$",
    "Bs",
    "Br",
    "Nu.",
    "₡",
    "Kč",
    "Fdj",
    "€",
    "£",
    "D",
    "GH₵",
    "Rp",
    "₪",
    "Rs",
    "¥",
    "Ksh",
    "₮",
    "RM",
    "MTn",
    "B/.",
    "S/.",
    "K",
    "₱",
    "฿",
    "TSh",
    "₴",
    "USh",
    "₫",
    "VT",
    "RF",
    "T$",
    "NT$",
    "CHF",
    "Kr",
    "E",
    "₩",
    "R",
    "S",
    "NAf",
    "Le",
    "SR",
    "Db",
    "WS$",
    "Lei",
    "₦",
    "C$",
    "L",
    "Ar",
    "₭",
    "G",
    "Q",
    "RD$",
    "CF",
    "P",
    "CFA",
    "FCFA",
  ];

  return (
    <>
      <div className="chart">
        <Header />
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="col-auto col-md-2 col-xl-2 px-sm-2 px-0 navv">
              <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
                <Navbar />
              </div>
            </div>
            <div className="col background-bg row m-0">
              <div className="col-lg-4 col-md-12">
                <div className="settings">
                  <h3>Settings</h3>

                  <div className="side-outer-wrap settings-tab">
                    {/* <div className={toggleState === 1 ? "account active-tab padding" : "padding"} onClick={() => toggleTab(1)}>
                                        <div className="tab-tile d-flex align-items-center">
                                            <img src={account} alt="account"/>
                                            <span className="ms-2">Account</span>
                                        </div>
                                        <p>Manage you  public profile and private information</p>
                                    </div> */}
                    <div
                      className={
                        toggleState === 2
                          ? "security active-tab padding"
                          : "padding"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      <div className="tab-tile d-flex align-items-center">
                        <img src={lock} alt="lock" />
                        <span className="ms-2">Security</span>
                      </div>
                      <p>Manage your information. keep private and secure</p>
                    </div>
                    <div
                      className={
                        toggleState === 3
                          ? "billing active-tab padding"
                          : "padding"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      <div className="tab-tile d-flex align-items-center">
                        <img src={billing} alt="account" />
                        <span className="ms-2">Plan & Billing</span>
                      </div>
                      <p>
                        Manage your subscription plan, payment method and
                        billing information
                      </p>
                    </div>
                    <div
                      className={
                        toggleState === 4
                          ? "privacy active-tab padding"
                          : "padding"
                      }
                      onClick={() => toggleTab(4)}
                    >
                      <div className="tab-tile d-flex align-items-center">
                        <img src={privacy} alt="account" />
                        <span className="ms-2">Privacy Policy</span>
                      </div>
                      <p>
                        Manage your subscription plan, payment method and
                        billing information
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                {/* <div className={toggleState === 1 ? "active account-profile" : "account-profile"}>
                                <h3>Account</h3>
                                <div className="row">
                                    <div className="col-xl-4 col-lg-12 col-md-12">
                                        <div className="user-img">
                                            <img src={user} alt="user"/>
                                            <div className="upload">
                                                <img src={upload} alt="upload" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-12 col-md-12">
                                        <div className="user-profile-form">
                                            <Form>
                                            <FormGroup>
                                            <Label>Name</Label>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="" />
                                            </FormGroup>
                                            <FormGroup>
                                            <Label >Email Address</Label>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="" />
                                            </FormGroup>
                                            <FormGroup>
                                            <Label >Phone number</Label>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="" />
                                            </FormGroup>
                                            </Form>
                                            <div className="text-end">
                                            <button type="button" className="btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                <div
                  className={
                    toggleState === 2
                      ? "active security-tab mt-0 secure"
                      : "security-tab"
                  }
                >
                  <h3>Security</h3>
                  <div className="security-wrap">
                    <div className="outer_wrap">
                      <span>Time Period</span>
                      <div className="selectt">
                        {/* <Input type="select" name="select" id="exampleSelect">
                          <option>Bydefault</option>
                          <option>Daily</option>
                          <option>Weekly</option>
                          <option>Bi-Weekly</option>
                          <option>Monthly</option>
                          <option>Yearly</option>
                        </Input> */}
                        <button
                          onClick={handleClick}
                          className="btn btn-primary"
                        >
                          Click Here
                        </button>
                      </div>
                    </div>
                    {isShown && (
                      <>
                        <div className="outer_wrap">
                          <span>Show Expenditure</span>
                          <div className="selectt">
                            <select
                              onChange={(e) => {
                                dispatch(setFormData({
                                  type: 1,
                                  show_expenditure: 8,
                                }));
                                // dispatch(setWeekStart(null);
                                dispatch(setMonthStart(+e.target.value));
                              }}
                              className="selectt"
                            >
                              <option value={1}>Daily</option>
                              <option value={2}>Weekly</option>
                              <option value={3}>Bi-Weekly</option>

                              <option value={4}>Monthly</option>
                              <option value={5}>Bi-Monthly</option>
                              <option value={6}>Quarterly</option>
                              <option value={7}>Quadrimester</option>
                              <option value={8}>Semi-Annual</option>
                              <option value={9}>Annual</option>
                            </select>
                          </div>
                        </div>
                        <div className="outer_wrap">
                          <span>Week Start Day</span>
                          <div className="selectt">
                            <select
                              onChange={(e) => {
                                dispatch(setFormData({
                                  type: 1,
                                  show_expenditure: 8,
                                }));

                                // dispatch(setMonthStart(null);
                                dispatch(setWeekStart(+e.target.value));
                              }}
                              className="selectt"
                            >
                              <option value={1}>Monday</option>
                              <option value={2}>Tuesday</option>
                              <option value={3}>Wednesday</option>
                              <option value={4}>Thursday</option>
                              <option value={5}>Friday</option>
                              <option value={6}>Saturday</option>
                              <option value={7}>Sunday</option>
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="outer_wrap">
                      <span>Currency</span>
                      <div className="selectt">
                        <p className="mb-0" onClick={toggle3}>
                          {currency}{" "}
                        </p>
                      </div>
                      <Modal isOpen={modal3} toggle={toggle3} scrollable>
                        <ModalHeader toggle={toggle3}>
                          Currency Code
                        </ModalHeader>
                        <ModalBody className="expendituree">
                          {data.map((d, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                onChange={toggle3}
                                className="m-1"
                                onClick={() => {
                                  dispatch(setFormData({
                                    type: 4,
                                    currency_type: "code",
                                    currency: d,
                                  }));
                                  dispatch(setCurrency(d));
                                }}
                                // checked={defaultFilter=== "Everyone"}
                                checked={
                                  formData.currency === d || currency === d
                                }
                              // onClick={postCurrencySymbols}
                              />
                              <span>{d}</span>
                            </div>
                          ))}
                        </ModalBody>
                        <ModalFooter>
                          <Button color="success" onClick={toggle4}>
                            Symbols
                          </Button>
                          <Button color="danger" onClick={toggle3}>
                            Cancel
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                    <div>
                      <Modal isOpen={modal4} toggle={toggle4} scrollable>
                        <ModalHeader toggle={toggle4}>
                          Currency Symbols
                        </ModalHeader>

                        <ModalBody className="expendituree">
                          {SymbolsData.map((d, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                onChange={() => {
                                  toggle4();
                                  toggle3();
                                }}
                                className="m-1"
                                value={"Everyone"}
                                onClick={() => {
                                  dispatch(setFormData({
                                    type: 3,
                                    currency_type: "symbol",
                                    currency: d,
                                  }));
                                  dispatch(setWeekStart(null));
                                  dispatch(setMonthStart(null));
                                  dispatch(setCurrency(d));
                                }}
                                // checked={defaultFilter=== "Everyone"}
                                checked={
                                  formData.currency === d || currency === d
                                }
                              />
                              <span>{d}</span>
                            </div>
                          ))}
                        </ModalBody>
                      </Modal>
                    </div>
                    <div className="outer_wrap">
                      <span>Carry Over</span>
                      <div className="selectt">
                        <div className="form-check form-switch">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            // onClick={carryApi}
                            onChange={(e) => {
                              dispatch(setFormData({
                                type: 2,
                                carry_over: e.target.checked ? 1 : 0,
                                positive_only: e.target.checked ? 1 : 0,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="outer_wrap">
                      <span>Automatic Sync</span>
                      <div className="selectt">
                        {/* <Input type="select" name="select" id="exampleSelect">
                          <option>Daily</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input> */}
                        {/* <h4>Automatic Sync</h4> */}
                        <Button
                          color="danger"
                          onClick={() => {
                            toggle();
                            // getNotesListing();
                          }}
                        >
                          Sync
                        </Button>
                        <Modal isOpen={modal} toggle={toggle}>
                          <ModalHeader toggle={toggle}>
                            Automatic Syncing
                          </ModalHeader>
                          <ModalBody className="expendituree">
                            <div>
                              <input
                                type="checkbox"
                                onChange={toggle}
                                className="m-1"
                                value={"Everyone"}
                                onClick={() => {
                                  dispatch(setFormData({
                                    type: 4,
                                    automatic_sync: 1,
                                  }));
                                  dispatch(setWeekStart(null));
                                  dispatch(setMonthStart(null));
                                }}
                                // checked={defaultFilter=== "Everyone"}
                                checked={formData.automatic_sync === 1}
                              />
                              {/* <span>EveryOne</span> */}
                              <span>Every Day</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={toggle}
                                className="m-1"
                                // onClick={() => {
                                //   getNotesListing();
                                //   dispatch(setFormData({});
                                // }}
                                onClick={() =>
                                  dispatch(setFormData({
                                    type: 4,
                                    automatic_sync: 2,
                                  }))
                                }
                                checked={formData.automatic_sync === 2}
                              />
                              {/* <span>EveryWeek</span> */}
                              <span>Every Week</span>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                onChange={toggle}
                                className="m-1"
                                // value=carry_over
                                // onClick={getNotesListing}
                                onClick={() =>
                                  dispatch(setFormData({
                                    type: 4,
                                    automatic_sync: 3,
                                  }))
                                }
                                checked={formData.automatic_sync === 3}
                              />
                              {/* <span>EveryMonth</span> */}
                              <span>Every Month</span>
                            </div>
                          </ModalBody>
                        </Modal>
                      </div>
                    </div>
                    {/* <div className="outer_wrap">
                      <div>
                        <span>Share with others</span>
                        <p>Change User Permission</p>
                      </div>
                      <div className="selectt">
                        <Input type="select" name="select" id="exampleSelect">
                          <option>View</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Input>
                      </div>
                    </div> */}
                    <div className="outer_wrap">
                      <span>Dropbox Sync</span>
                      <div className="selectt">
                        <div className="form-check form-switch">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            onChange={handleDropBoxSync}
                            checked={dropboxSync}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="outer_wrap">
                      <span>Google Drive Sync</span>
                      <div className="selectt">
                        <div className="form-check form-switch">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            onChange={handleGoogleDriveSync}
                            checked={googleDriveSync}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    toggleState === 3
                      ? "active security-tab plan-billing mt-0"
                      : "security-tab"
                  }
                >
                  <h3>Plan & Billing</h3>
                  <div className="plans">
                    <p>Change your plan</p>
                    <span>Upgrade or downgrade your current plan.</span>
                  </div>
                  <div className="plans-list d-flex flex-wrap ">
                    <div
                      className={
                        selectPlan == 'monthly' ? "Plans-wrap active" : "Plans-wrap"
                      }
                      onClick={() => togglePlan('monthly')}
                    >
                      <p>MONTHLY</p>
                      <span>Starter plan for individuals.</span>
                      <div className="select">
                        <img src={select} alt="select" />
                      </div>
                      <h5>$5 / month</h5>
                    </div>
                    <div
                      className={
                        selectPlan === 'yearly' ? "Plans-wrap active" : "Plans-wrap"
                      }
                      onClick={() => togglePlan('yearly')}
                    >
                      <p>YEARLY</p>
                      <span>Big save for individuals</span>
                      <div className="select">
                        <img src={select} alt="select" />
                      </div>
                      <h5>$50 / year</h5>
                    </div>
                  </div>
                  <div className=" mt-5">
                    <StripeCheckout
                      token={handlePayment}
                      stripeKey={STRIPE_KEY}
                      amount={selectPlan == 'monthly' ? 500 : 5000}
                      currency="CAD"
                      name={selectPlan == 'monthly' ? 'MONTHLY' : 'YEARLY'}
                      description={selectPlan == 'monthly' ? 'Starter plan for individuals' : 'Big save for individuals'}
                      image={logosmall}
                    >
                      <Button color="primary" className="plan-btn">
                        Save
                      </Button>
                    </StripeCheckout>
                    {/* <button type="button" className="btn btn-primary plan-btn">
                      Save
                    </button> */}
                  </div>
                </div>

                <div
                  className={
                    toggleState === 4
                      ? "active security-tab privacy-p mt-0"
                      : "security-tab"
                  }
                >
                  <h3>Privacy policy</h3>

                  <div className="plans-list mt-4">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi
                    </p>
                    <p>
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum."
                    </p>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo. Nemo
                      enim ipsam voluptatem quia voluptas sit aspernatur aut
                      odit aut fugit, sed quia consequuntur magni dolores eos
                      qui ratione voluptatem sequi nesciunt.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          authUser.data.subscription_status == 1 && (
            <ExpireModal />
          )
        }
      </div>
    </>
  );
};
export default Settings;
