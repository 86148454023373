import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { FormGroup } from 'react-bootstrap';
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Selectlogo = ({ createnotetoggle, isOpen, setShow }) => {
  const dispatch = useDispatch()
  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const [addNewItemModalOpen, setAddNewItemModal] = useState(false)
  const openAddNewItemsModal = () => {
    setAddNewItemModal(!addNewItemModalOpen)
  }

  const [inputs, setInputs] = useState({
    tax: "",
    rate: "",
  })
  
  const handleInputChange = (e) => {
    setInputs({
      ...inputs, ...{ [e.target.name]: e.target.value }
    })
  }
  const [updated, setUpdated] = useState();
  const handleClick = () => {
    dispatch(saveFormAction({ "taxRate": [inputs.rate] }))
    dispatch(saveFormAction({ "taxAmount": [inputs.tax] }))
    dispatch(saveFormAction({ "taxItems": [...invoiceDetailsStates.taxItems, inputs] }))
    createnotetoggle()
    setInputs({
      tax: "",
      rate: "",
    })

  }
  return (
    <div> 
      <Modal className="exp-cat" isOpen={isOpen} createnotetoggle={createnotetoggle} centered>
        <ModalHeader createnotetoggle={createnotetoggle}>
          +New Tax
        </ModalHeader>
        <ModalBody>
          <Form className="form_text">
            <FormGroup >
              <Label className="mt-2">Tax</Label>
              <Input
                name="tax"
                type="text"
                value={inputs.tax}
                placeholder="Tax Name"
                className="form-control"
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup >
              <Label className="mt-2">Tax Rate%</Label>
              <Input
                name="rate"
                type="number"
                value={inputs.rate}
                placeholder="Tax Rate%"
                className="form-control"
                onChange={handleInputChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" onClick={handleClick} color="primary" >Ok</Button>{' '}
          <Button color="secondary" onClick={createnotetoggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );

}

export default Selectlogo;
