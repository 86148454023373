import React, { useState } from "react";
import { FormGroup } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Label } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";

const Fromaddress = ({ createnotetoggle4, isOpen }) => {
    const dispatch = useDispatch()
    const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)

    const [address, setAddress] = useState(invoiceDetailsStates.address);

    const handleChange = (event) => {   
        setAddress(event.target.value);
    };

    const handleClick = ()  =>{
        dispatch(saveFormAction({"address": address}))    
        createnotetoggle4()
      }

    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle4={createnotetoggle4} centered>
                <ModalHeader createnotetoggle4={createnotetoggle4}>
                    From Address
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input
                                name="address"
                                type="textarea"
                                value={address}
                                rows="4"
                                placeholder="Name and address of your customer"
                                className="form-control form_text"
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Form>

                    <div className="modal_footer modal_footer1 border-0 mt-0">
                        {/* <Button className="btn outline_btn" onClick={createnotetoggle4}>SAVE</Button> */}

                        <button className="btn outline_btn" type="submit" onClick={handleClick} >SAVE</button>

                    </div>
                </ModalBody>
                {/* <h2>Message: {address}</h2>
                <h2>Updated: {updated}</h2> */}
            </Modal>
        </div>
    );

}

export default Fromaddress;
