import React, { useEffect, useState } from "react";
import calender from "../images/cal.svg";
import moment from "moment";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { dateFilterAction } from "../redux/actions/dateFilterAction";
import prev from '../images/prev.svg';
import next from '../images/next.svg';
import { dateFilterStartEndAction } from "../redux/actions/dateFilterStartEndAction";
import { getDataAction } from "../redux/actions/dashboard/getDataAction";
import useDateFilters from "../hooks/useDateFilters";

const DateFilter = ({ setHideLoader, setShowLoader, activeTransactionFilter, setTransactionList }) => {
  const { handleModal, handleDefaultFilter, handlePrevious, handleNext, isOpen, defaultFilter, dateFilter, setdefaultFilter } = useDateFilters({ setHideLoader, setShowLoader, activeTransactionFilter, setTransactionList })

  return (
    <>
      <div className="date-filter d-flex align-items-center">
        <button onClick={handlePrevious}> <img src={prev} alt="prev" /> </button>
        <div className="side-data" onClick={handleModal}>

          <button type="button" className="btn btn-primary d-flex align-items-center" id="dateButton">
            <img src={calender} alt="calender" />
            {dateFilter}

          </button>

          <Modal
            className="exp-cat"
            isOpen={isOpen}
            toggle={handleModal}
            centered
          >
            <ModalHeader toggle={handleModal}>View Expenditures</ModalHeader>
            <ModalBody className="clndr_modal">
              <tbody>
                <tr>
                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Daily"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Daily"}
                    />
                    <p>Daily</p>
                  </td>
                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Weekly"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Weekly"}

                    />
                    <p>Weekly</p>
                  </td>
                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Bi-Weekly"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Bi-Weekly"}

                    />
                    <p>Bi-Weekly</p>
                  </td>
                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Monthly"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Monthly"}

                    />
                    <p>Monthly</p>
                  </td>
                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Bi-Monthly"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Bi-Monthly"}

                    />
                    <p>Bi-Monthly</p>
                  </td>

                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Quarterly"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Quarterly"}

                    />
                    <p>Quarterly</p>
                  </td>

                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Quadrimester"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Quadrimester"}

                    />
                    <p>Quadrimester</p>
                  </td>

                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Semi-Annual"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Semi-Annual"}

                    />
                    <p>Semi-Annual</p>
                  </td>

                  <td className="pe-3">
                    <input className="me-2 dateRadioButton"
                      type="radio"
                      name="date_filter"
                      value={"Annual"}
                      onChange={handleDefaultFilter}
                      checked={defaultFilter === "Annual"}

                    />
                    <p>Annual</p>
                  </td>
                </tr>
              </tbody>
            </ModalBody>
          </Modal>
        </div>
        <button onClick={handleNext}> <img src={next} alt="next" /> </button>
      </div>

    </>
  );
};

export default DateFilter;
