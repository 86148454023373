import { types as Types } from '../actionTypes';

export const settingReducer = (state = {
  formData: {},
  currency: "Default",
  weekStart: null,
  monthStart: null,
  loading: false,
  data: null,
  error: '',
}, action) => {
  switch (action.type) {

    case Types.SET_SETTING_FORMDATA:
      return {
        ...state,
        formData: {
          ...action.payload
        }
      }
    case Types.SET_SETTING_CURRENCY:
      return {
        ...state,
        currency: action.payload
      }
    case Types.SET_SETTING_WEEK_START:
      return {
        ...state,
        weekStart: action.payload
      }
    case Types.SET_SETTING_MONTH_START:
      return {
        ...state,
        monthStart: action.payload
      }
    case Types.SETTING_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        error: ''
      }
    case Types.SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ''
      }
    case Types.SETTING_FAIL:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload
      }
    default:
      return state
  }
}

