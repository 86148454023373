import React, { useEffect, useState, useRef } from "react";
import Header from "./partials/header";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import html2canvas from "html2canvas";
const Invoicepreview = () => {

  // const [img,setImg] = useState("data")
  const handleImageDownload = async () => {
    // TODO: add logic here
    const element = document.getElementById('print'),
      canvas = await html2canvas(element),
      data = canvas.toDataURL('image/png'),
      link = document.createElement('a');
    link.href = data;
    link.download = 'Invoice.png';
    // setImg(link.href)
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // console.log("dataUrl", link.href);

  };
  const handleExportData = () => {

    let exportedDataArray = []

    exportedDataArray.map((data, key) => {
      exportedDataArray.push([Number(key) + Number(1),
      data.invoiceDetailsStates.address,
      data.invoiceDetailsStates.billaddress,
      data.invoiceDetailsStates.shipaddress,
      data.invoiceDetailsStates.estimate_no,
      data.invoiceDetailsStates.date,
      data.invoiceDetailsStates.terms,
      data.invoiceDetailsStates.addNewItems,
      data.invoiceDetailsStates.taxItems,
      data.invoiceDetailsStates.shipamount,
      data.total
      ])
    })
    const doc = new jsPDF()
    // autoTable(doc, { html: '#my-table' })
    autoTable(doc, {
      startY: doc.autoTable() + 70,
      margin: { horizontal: 10 },
      styles: { overflow: "linebreak" },
      bodyStyles: { valign: "top" },
      html: '#my-table',
      // columnStyles: { email: { cellWidth: "wrap" } },
      // head: [['#', 'DATE', 'CATEGORY', 'SUB-CATEGORY', 'TRANSACTION TYPE', 'AMOUNT']],
      body: exportedDataArray
      // [
      // ['David', 'david@example.com', 'Sweden', 'abcd','xyz', '1234'],
      // ['Castille', 'castille@example.com', 'Spain','abcd','xyz', '1234'],
      // // ...
      // ],

    })

    // pdf.fromHTML(string);
    // pdf.save("pdf");

    let pdfName = 'Invoice.pdf'
    doc.save(pdfName)

    var blobPDF = new Blob([doc.output()], { type: 'application/pdf' });
    // var file = new File([blobPDF], new Date().toString() + '.pdf');
    let url = doc.output("datauri")
  }

  const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)
  const [total, setTotal] = useState("")

  useEffect(() => {
    let sum = 0
    if (invoiceDetailsStates.addNewItems.length > 0) {
      invoiceDetailsStates.addNewItems.forEach((ele) => {
        sum = sum + parseFloat(ele.amount)
      })

      let totalTaxPercent = 0
      invoiceDetailsStates.taxItems.forEach((ele) => {
        totalTaxPercent = totalTaxPercent + parseFloat(ele.rate)
      })

      let sumWithTaxes = sum + ((sum * totalTaxPercent) / 100)

      let sumWithTaxesWithShipping = sumWithTaxes + parseFloat(invoiceDetailsStates.shipamount || 0.00)
      setTotal(sumWithTaxesWithShipping)
    }
    else {
      setTotal(0)
    }

  }, [invoiceDetailsStates])


  const [addItemsTotal, setAddItemsTotal] = useState(0)

  useEffect(() => {
    if (invoiceDetailsStates.addNewItems.length > 0) {
      let sum = 0
      invoiceDetailsStates.addNewItems.forEach((ele) => {
        sum = sum + parseFloat(ele.amount)
      })
      setAddItemsTotal(sum)
    }
  }, [invoiceDetailsStates.addNewItems])

  const colorsObj = {
    "red": "red",
    "blue": "#4081e1",
    "green": "#18b218"
  }

  return (
    <>
      <div className="chart">
        <Header />
        <div className="container-fluid">
          <div className="row flex-nowrap">
            <div className="header_invoice">
              <Link to='/createinvoice'>
                <h1>
                  <img src={require('../images/prev.svg').default} alt="" />
                  Invoice preview & share options</h1>
              </Link>
            </div>
            <div className="col invoice_background row m-0">
              <div className="col-12">
                <div className="create_invoice_section">
                  <ul className="m-0 p-0">
                    <li>
                      <a href={`mailto:support@spenleaf.com?subject=Speanleaf Invoice&body=${""}`}>

                        <div className="switch_doc">
                          <img src={require('../images/email.png')} alt="" style={{ width: "auto" }} />
                          Email</div>
                      </a>
                    </li>
                    {/* <li>
                      <a href="javascript:;">
                        <div className="switch_doc">
                          <img src={require('../images/share1.png')} alt="" style={{ width: "auto" }} />
                          Share</div>
                      </a>
                    </li> */}
                    <li>
                      <a href="javascript:;">
                        <div className="switch_doc" onClick={handleImageDownload}>
                          <img src={require('../images/download1.png')} alt="" style={{ width: "auto" }} />
                          Download</div>
                      </a>
                    </li>
                    <Link to='/createinvoice'>
                      <li>
                        <a href="javascript:;">
                          <div className="switch_doc">
                            <img src={require('../images/editing.png')} alt="" style={{ width: "auto" }} />
                            Edit Invoice</div>
                        </a>
                      </li>
                    </Link>
                  </ul>
                  <div id="print">
                    {
                      invoiceDetailsStates.estimates && <div className="flex_row1">
                        {invoiceDetailsStates.estimates.toLowerCase() == 'default' &&
                          (
                            <span>
                              <img src={require('../images/check-mark.png')} alt="" style={{ width: "28px" }} />
                            </span>
                          )}

                        {invoiceDetailsStates.estimates.toLowerCase() !== 'default' &&
                          (
                            <span>
                              <img src={require('../images/lined-circle.svg').default} alt="" style={{ width: "28px" }} />
                            </span>
                          )}

                        {invoiceDetailsStates.estimates.toLowerCase() == 'red' &&
                          (
                            <span className="red_tick">
                              <img src={require('../images/check.png')} alt="" style={{ width: "18px" }} />
                            </span>
                          )}

                        {invoiceDetailsStates.estimates.toLowerCase() !== 'red' &&
                          (
                            <span className="red_circle"></span>
                          )}

                        {invoiceDetailsStates.estimates.toLowerCase() == 'blue' &&
                          (
                            <span className="blue_tick">
                              <img src={require('../images/check.png')} alt="" style={{ width: "18px" }} />
                            </span>
                          )}

                        {invoiceDetailsStates.estimates.toLowerCase() !== 'blue' &&
                          (
                            <span className="blue_circle"></span>
                          )}


                        {invoiceDetailsStates.estimates.toLowerCase() == 'green' &&
                          (
                            <span className="green_tick">
                              <img src={require('../images/check.png')} alt="" style={{ width: "18px" }} />
                            </span>
                          )}
                        {invoiceDetailsStates.estimates.toLowerCase() !== 'green' &&
                          (
                            <span className="green_circle"></span>
                          )}
                      </div>
                    }

                    <div className="invoice_div mt-4" >
                      {
                        invoiceDetailsStates.estimated && <div className="paid_text">

                          {invoiceDetailsStates.estimated.toLowerCase() == 'set as partial paid' &&
                            (
                              <p>PARTIALLY<span> PAID</span></p>
                            )}

                          {invoiceDetailsStates.estimated.toLowerCase() == 'set as paid' &&
                            (
                              <span>PAID</span>
                            )}

                        </div>
                      }

                      <table id="my-table">
                        <tr>
                          <td colSpan={2} style={{ verticalAlign: "top" }}>
                            <div className="table_td_black p_wrap">{invoiceDetailsStates.address}</div>
                          </td>
                          <td colSpan={2} align="right" id="domel">
                            {/* <img src={require('../images/invoice_img.jpeg')} alt="" className="invoice_img1" /> */}
                            {invoiceDetailsStates.logo_url && <img src={invoiceDetailsStates.logo_url} alt="" className="invoice_img1" />}
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1" style={{ width: "25%" }}> <div className="table_td_red" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>BILL TO</div></td>
                          <td className="py-1" style={{ width: "25%" }}> <div className="table_td_red" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>SHIP TO</div></td>
                          <td className="py-1" style={{ width: "25%" }}> <div className="table_td_red" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>{invoiceDetailsStates.estimate.toUpperCase()} #</div></td>
                          <td align="right" className="py-1" style={{ width: "25%" }}>
                            <div className="table_td_black">{invoiceDetailsStates.estimate_no}</div>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0" style={{ verticalAlign: "top" }}>
                            <div className="table_td_black p_wrap">{invoiceDetailsStates.billaddress}</div>
                          </td>
                          <td className="py-0" style={{ verticalAlign: "top" }}>
                            <div className="table_td_black p_wrap">{invoiceDetailsStates.shipaddress}</div>
                          </td>
                          <td className="py-0" style={{ verticalAlign: "top" }}>
                            <div className="table_td_red" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>{invoiceDetailsStates.estimate.toUpperCase()} Date</div>
                          </td>
                          <td align="right" className="py-0" style={{ verticalAlign: "top" }}>
                            <div className="table_td_black">{invoiceDetailsStates.date} </div>
                          </td>
                        </tr>

                        <tr className="table_td_red border_td" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>
                          <td>
                            <div>QTY</div>
                          </td>
                          <td>
                            <div>DESCRIPTION</div>
                          </td>
                          <td align="center">
                            <div>PRICE</div>
                          </td>
                          <td align="right">
                            <div>AMOUNT</div>
                          </td>
                        </tr>
                        {
                          invoiceDetailsStates.addNewItems.length > 0 &&
                          invoiceDetailsStates.addNewItems.map((ele) => {
                            return (
                              <tr className="table_td_black  border-top-0">
                                <td>
                                  <div> {(parseFloat(ele.qty)).toFixed(2)}</div>
                                </td>
                                <td>
                                  <div>{ele.description}</div>
                                </td>
                                <td align="center">
                                  <div>
                                    {/* {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency}
                                     {(parseFloat(ele.price)).toFixed(2)} */}
                                    {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                                      invoiceDetailsStates.addNewItems.length > 0 ?
                                        ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                                    }
                                    {
                                      ele.price > 0 && <span> {(parseFloat(ele.price)).toFixed(2)} </span>

                                    }
                                    {
                                      invoiceDetailsStates.currencyformat == "0.00 $" && (
                                        invoiceDetailsStates.addNewItems.length > 0 ?
                                          ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                                    }
                                  </div>
                                </td>
                                <td align="right">
                                  <div>
                                    {/* {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} 
                                     {(parseFloat(ele.amount)).toFixed(2)}
 */}



                                    {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                                      invoiceDetailsStates.addNewItems.length > 0 ?
                                        ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                                    }
                                    {
                                      ele.amount > 0 && <span> {(parseFloat(ele.amount)).toFixed(2)} </span>

                                    }
                                    {
                                      invoiceDetailsStates.currencyformat == "0.00 $" && (
                                        invoiceDetailsStates.addNewItems.length > 0 ?
                                          ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                                    }




                                  </div>
                                </td>

                              </tr>
                            )
                          })
                        }
                        <tr className="border_td">
                          <td colSpan={2}>
                            <div className="table_td_black">Sub Total</div>
                            {
                              invoiceDetailsStates.taxItems.length > 0 &&
                              invoiceDetailsStates.taxItems.map((ele) => {
                                return (
                                  <div className="table_td_black"> {ele.tax} </div>
                                )
                              })
                            }
                            <div className="table_td_black">Shipping & Handling</div>
                            <div className="table_td_red" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>TOTAL</div>
                          </td>

                          <td colSpan={2} align="right">
                            <div className="table_td_black">
                              {/* {
                            <span> {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {(parseFloat(addItemsTotal)).toFixed(2)}</span>
                            
                          } */}

                              {/* {invoiceDetailsStates.addNewItemsAmounts.map((ele) => (<span> {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {(parseFloat(ele)).toFixed(2)}</span>))} */}

                              {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                                invoiceDetailsStates.addNewItems.length > 0 ?
                                  ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                              }
                              {
                                addItemsTotal > 0 && <span> {(parseFloat(addItemsTotal)).toFixed(2)} </span>

                              }
                              {
                                invoiceDetailsStates.currencyformat == "0.00 $" && (
                                  invoiceDetailsStates.addNewItems.length > 0 ?
                                    ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                              }

                            </div>

                            {
                              invoiceDetailsStates.taxItems.length > 0 &&
                              invoiceDetailsStates.taxItems.map((ele) => {
                                return (
                                  <div className="table_td_black"> {(parseFloat(ele.rate)).toFixed(2)} %</div>
                                )
                              })
                            }
                            <div className="table_td_black">
                              {
                                // parseFloat(invoiceDetailsStates.shipamount) > 0 &&
                                (
                                  <>
                                    {/* {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {(parseFloat(invoiceDetailsStates.shipamount || 0.00)).toFixed(2)} */}
                                    {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                                      invoiceDetailsStates.shipamount.length > 0 ?
                                        ((invoiceDetailsStates.currencyformat.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                                    }

                                    {
                                      invoiceDetailsStates.shipamount > 0 && <span> {(parseFloat(invoiceDetailsStates.shipamount || 0.00)).toFixed(2)} </span>

                                    }

                                    {
                                      invoiceDetailsStates.currencyformat == "0.00 $" && (
                                        invoiceDetailsStates.shipamount.length > 0 ?
                                          ((invoiceDetailsStates.currencyformat.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                                    }
                                  </>)}
                            </div>
                            <div className="table_td_red" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>
                              {/* {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {parseFloat(total).toFixed(2)} */}
                              {invoiceDetailsStates.currencyformat == "$ 0.00" && (
                                invoiceDetailsStates.addNewItems.length > 0 ?
                                  ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                              }
                              {
                                total > 0 && <span> {(parseFloat(total)).toFixed(2)} </span>

                              }
                              {
                                invoiceDetailsStates.currencyformat == "0.00 $" && (
                                  invoiceDetailsStates.addNewItems.length > 0 ?
                                    ((invoiceDetailsStates.addNewItems.length > 0 && !invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency) : invoiceDetailsStates.currencyformat)
                              }
                            </div>
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan={2}>
                            <div className="table_td_black">Shipping & Handling</div>
                            <div className="table_td_red">TOTAL</div>
                          </td>
                          <td colSpan={2} align="right">
                            <div className="table_td_black"> {
                              parseFloat(invoiceDetailsStates.shipamount) > 0 && (
                                <>
                                  {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {(parseFloat(invoiceDetailsStates.shipamount)).toFixed(2)}

                                </>)}</div>
                            <div className="table_td_red"> {(!invoiceDetailsStates.currency) ? "$ " : invoiceDetailsStates.currency} {parseFloat(total).toFixed(2)}</div>
                          </td>
                        </tr> */}
                        <tr>
                          <td colSpan={4}>
                            <div className="table_td_red tablefooter" style={{ color: colorsObj[invoiceDetailsStates.estimates.toLowerCase().trim()] }}>TERMS & CONDITIONS</div>
                            <div className="table_td_black tablefooter mt-3">{invoiceDetailsStates.terms}</div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Invoicepreview;

