import React, {useState, useEffect} from 'react';
import './App.css';
import {  Route, Redirect, Routes, BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const App = () => { 
  const[isLoggedIn, setIsLoggedIn] = useState(false);
  
  return (
    <div className="App">
      <BrowserRouter>
          <AppRoutes />
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
