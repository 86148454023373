import { loginUser } from "../../api/loginApi";
import { subscriptionApi } from "../../api/subscriptionApi";
import { types } from "../actionTypes";

export const loginUserAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
    });
    try {
      const results = await loginUser(values);

      if (results.data.data) {
        return dispatch({
          type: types.LOGIN_SUCCESS,
          payload: results.data,
        });
      }
      throw results
    } catch (error) {

      return dispatch({
        type: types.LOGIN_FAIL,
        payload: error,
      });
    }
  };
};

export const buySubscriptionAction = (values) => {
  return async (dispatch) => {
    dispatch({
      type: types.BUY_SUBSCRIPTION_REQUEST,
    });
    try {
      const results = await subscriptionApi(values);
      if (results.data.status_code == 200) {
        return dispatch({
          type: types.BUY_SUBSCRIPTION_SUCCESS,
          payload: results.data,
        });
      } else {
        return dispatch({
          type: types.BUY_SUBSCRIPTION_FAIL,
          payload: results.data.message,
        });
      }
    } catch (error) {

      return dispatch({
        type: types.BUY_SUBSCRIPTION_FAIL,
        payload: error,
      });
    }
  };
};