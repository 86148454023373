import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveFormAction } from "../../redux/actions/addInvoiceAction";


const Currenyformating = ({ createnotetoggle10, isOpen }) => {
    const dispatch = useDispatch()
    const { authUser } = useSelector((state) => state.login)
    const invoiceDetailsStates = useSelector((state) => state.invoiceDetails)

    const handleClick = (e, val) => {
        dispatch(saveFormAction({ "currencyformat": val }))
    }
    return (
        <div>
            <Modal className="exp-cat" isOpen={isOpen} createnotetoggle10={createnotetoggle10} centered>
                <ModalHeader createnotetoggle10={createnotetoggle10}>

                    <span onClick={createnotetoggle10} className="d-flex justify-content-between align-items-center">
                        <span>Currency Formating</span>
                        <img src={require('../../images/cross-svgrepo-com.svg').default} alt="" width="15px" />
                    </span>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="add_list_btn1 without_icon hover_none" onClick={(e) => handleClick(e, '$ 0.00')}>
                            $ 0.00
                            {
                                invoiceDetailsStates.currencyformat == '$ 0.00' &&
                                <span >
                                    <img src={require('../../images/check.png')} alt="" />
                                </span>
                            }
                        </div>
                        <div className="add_list_btn1 without_icon hover_none" onClick={(e) => handleClick(e, '0.00 $')}>
                            0.00 $
                            {
                                invoiceDetailsStates.currencyformat == '0.00 $' &&
                                <span >
                                    <img src={require('../../images/check.png')} alt="" />
                                </span>
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );

}

export default Currenyformating;
