import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../images/logo.png';
import { Formik, Form} from "formik";
import {loginFormSchema} from "../../utils/validations"
import { connect } from "react-redux";
import { loginUserAction } from "../../redux/actions/loginAction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "../loader";

const Login = ({login}) => {
  const [save, setSave] = useState(false);
  const [savedData, setSavedData] = useState({
    email: '',
    password: ''
  });
  const[hideLoader, setHideLoader] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    let email = JSON.parse(localStorage.getItem('cred'))?.email;
    setSavedData({ 
      ...savedData, 
      email: email ? email : ''
    });           
    email && setSave(true)
  }, [])


  return( 
    <> 
      <header className="App-header">
        {
          hideLoader && <Loader />
        }
        <div className="login">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div className="outer-wrap">
                  <img src={logo} className="App-logo" alt="logo" />  
                  <div className="login-form">
                  <h4>Sign in to your account</h4>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: "",
                        password: ""
                      }}                                  
                      validateOnChange={true}
                      validationSchema={loginFormSchema}
                      onSubmit={(values, { resetForm }) => {
                        if(values){
                            const loginValues = {
                              email: values.email,
                              user_name: values.email,
                              password: values.password,
                              registration_type: 1
                            }
                            setHideLoader(true)
                            login(loginValues).then(response => {
                              if(response.type == "LOGIN_FAIL")
                              {
                                setHideLoader(false)

                                toast.error(response.payload.data.message);
                              }
    
                              else
                              {
                                
                                toast.success(response.payload.message);
                                setHideLoader(false)
                                navigate("/expense");
                              }
                            })
                        }         
                      }}                         
                      >
                        {({ errors, touched, values, handleChange, handleSubmit }) => (
                          <Form className="form text-fields" onSubmit={handleSubmit}>
                          <label for="email" className="form-label">Email address</label>
                          <input
                            name="email"                 
                            type="email"                  
                            value={values.email}
                            placeholder="Enter email"
                            className="form-control email-field"
                            label="Email"
                            onChange={handleChange}
                          />       
                          <div className="error-message">
                            {errors.email ? errors.email : ''}
                          </div>
                          <label for="password" className="form-label">Password</label>
                          <input                           
                            name="password"                      
                            type="password"
                            value={values.password}
                            placeholder="Enter password"
                            className="form-control email-field"
                            label="Password"           
                            onChange={handleChange}
                          />   
                          <div className="error-message">
                            {errors.password ? errors.password : ''}
                          </div>
                          <div className="d-flex justify-content-between">
                            <div className="form-check">
                              {/* <input className="form-check-input" type="checkbox" value="" />
                              <label className=" remember form-check-label" for="flexCheckDefault">
                                Remember me
                              </label> */}
                            </div>
                            <Link to="/forgot"> Forgot password?</Link>
                          </div>
                          <button type="submit" className="login-btn btn btn-primary w-100 mt-3">Sign In</button>
                          <div className="ask-signup">
                            <Link to="/register">Don’t have a spenweek account? <span>Sign Up</span> </Link>
                          </div>
                          </Form>
                        )}
                        
                      </Formik>
                      <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

    </>
  );
}

const mapStateToProps = (state)=>{
 return {
    
 }
}

const mapDispatchToProps = (dispatch)=>{
  return {
  login : (values)=>dispatch(loginUserAction(values))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);