import { LoginReducer } from "./loginReducer";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from "redux";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { DateFilterReducer } from "./dateFilterReducer";
import { settingReducer } from "./settingReducer";
import { syncReducer } from "./syncReducer";
import { invoiceReducer } from "./invoiceReducer";

const persistConfig = {
    key: 'counter',
    storage,
};

export const rootReducer = combineReducers({
    login : LoginReducer,
    dateFilter: DateFilterReducer,
    setting: settingReducer,
    syncing: syncReducer,
    invoiceDetails: invoiceReducer
  });