import React, { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { Formik, Form } from "formik";
import { addCategorySchema } from '../../utils/validations';
import { useDispatch, useSelector } from "react-redux";
import { addCategoryAction } from '../../redux/actions/addCategoryAction';
import { toast } from 'react-toastify';
import { Loader } from '../loader';

const Modalcat = ({ toggle, isOpen, currentSelection, currentTab, renderCategoryListing, renderIncomeCategories, rowValues }) => {
  const dispatch = useDispatch()
  const { authUser } = useSelector((state) => state.login);
  const [showLoader, setShowLoader] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  return (
    <>
      {
        showLoader && <Loader />
      }
      <div>
        <Modal className="exp-cat" isOpen={isOpen} toggle={toggle} centered>
          <ModalHeader toggle={toggle}>
            {rowValues ? "Edit" : "Add"} {currentTab}
          </ModalHeader>
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: rowValues ? rowValues.name : '',
              price: rowValues ? rowValues.budget : "",
              id: rowValues ? rowValues.id : "",
            }}
            validateOnChange={true}
            validationSchema={addCategorySchema}
            onSubmit={(values, { resetForm }) => {
              if (values) {
                setFormSubmitted(true)

                setShowLoader(true)

                let requestParams = {
                  'user_id': authUser.data.id,
                  'name': values.name,
                  'category_type': currentTab == 'Expense' ? 1 : 2,
                  'parent_id': 0,
                  'icon': 'ic_cat1',
                  'budget': values.price,
                  'endpoint': currentSelection
                }
                requestParams = { ...requestParams, ...(rowValues && { id: rowValues.id }) }

                dispatch(addCategoryAction(requestParams)).then(response => {
                  resetForm()
                  if (response.type == "ADD_CATEGORY_FAIL") {
                    response.payload.message ? toast.error(response.payload.message) : toast.error(response.payload)
                    setShowLoader(false)
                  }

                  else {
                    toast.success(response.payload.message)
                    toggle()
                    currentTab == 'Expense' ? renderCategoryListing() : renderIncomeCategories()
                    setShowLoader(false)
                  }
                  setFormSubmitted(false)
                })

              }
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <ModalBody>
                <Form className="form text-fields">
                  <input
                    name="id"
                    type="hidden"
                    className="form-control"
                  />

                  <label className="form-label">Category name</label>
                  <input
                    name="name"
                    type="text"
                    value={values.name}
                    placeholder={'Enter ' + currentSelection}
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="error-message">
                    {errors.name ? errors.name : ''}
                  </div>
                  <label for="password" className="form-label">Budget</label>
                  <input
                    name="price"
                    type="text"
                    value={values.price}
                    placeholder="Enter budget"
                    className="form-control"
                    onChange={handleChange}
                  />
                  <div className="error-message">
                    {errors.price ? errors.price : ''}
                  </div>
                  <button type="submit" className="btn btn-primary cat-save" disabled={formSubmitted}>Save</button>
                  {/* <button  className="btn btn-warning">Cancel</button> */}
                </Form>
              </ModalBody>
            )}
          </Formik>

        </Modal>


      </div>
    </>
  );

}

export default Modalcat;